import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { InputError } from 'components';
import { Controller } from 'react-hook-form';

export const TestSubTypesField = (props) => {
  const { form, field: fieldData, formData, t } = props;
  const [subTypes, setSubTypes] = useState([] as any);
  const [disabled, setDisabled] = useState(true);

  const companyWatcher = form.watch('company', formData.company?._id);
  const deviceTypeWatcher = form.watch('deviceType', formData.deviceType);
  const testTypeWatcher = form.watch('testType', formData.testType);

  useEffect(() => {
    if (companyWatcher && testTypeWatcher && deviceTypeWatcher) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [companyWatcher, deviceTypeWatcher, testTypeWatcher]);

  useEffect(() => {
    form.setValue('testSubTypes', []);
    const testType = form.watch('testType', formData && formData['testType']);
    const newOptions = fieldData.options.filter(
      (option) => option.testType === testType,
    );
    const newOptionsValues = newOptions.map((item) => item.value);

    form.setValue(
      'testSubTypes',
      formData?.testSubTypes?.filter((item) => newOptionsValues.includes(item)),
    );
    setSubTypes(newOptions);
  }, [companyWatcher, testTypeWatcher]);

  useEffect(() => {
    if (disabled) {
      form.setValue('testSubTypes', []);
    }
  }, [disabled]);

  return !disabled ? (
    <Grid item xs={fieldData.span} key={`subTypes-form-field`}>
      <Controller
        render={({
          field: {
            onChange,
            value = (formData && formData[fieldData.fieldName]) || Array<any>(),
          },
        }) => (
          <TextField
            label={
              fieldData.required
                ? `${t(fieldData.label)} *`
                : t(fieldData.label)
            }
            select
            disabled={disabled}
            margin={'dense'}
            fullWidth
            variant="standard"
            error={form.formState.errors[fieldData.fieldName]}
            defaultValue={formData && formData[fieldData.fieldName]}
            helperText={
              form.formState.errors[fieldData.fieldName] && (
                <InputError message={t(fieldData.error)} />
              )
            }
            SelectProps={{
              multiple: true,
              value: value || [],
              onChange: onChange,
              renderValue: (selected) => {
                let selectedLabels: any = [];
                fieldData.options.forEach((option) => {
                  if ((selected as string[]).includes(option.value)) {
                    selectedLabels.push(option.label);
                  }
                });
                return (selectedLabels as string[]).join(', ') || [];
              },
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
          >
            {subTypes &&
              subTypes.map((subType, index) => (
                <MenuItem key={subType.value} value={subType.value}>
                  <Checkbox
                    checked={
                      value !== null ? value?.includes(subType.value) : false
                    }
                  />
                  <ListItemText primary={subType.label} />
                </MenuItem>
              ))}
          </TextField>
        )}
        name={fieldData.fieldName}
        control={form.control}
        rules={{
          required: fieldData.required,
          validate: (value) => (disabled ? value : value.length > 0),
        }}
        defaultValue={formData && formData[fieldData.fieldName]}
      />
    </Grid>
  ) : null;
};
