import {
  FASTPREVIEW_GET_RESULT,
  FASTPREVIEW_GET_RESULT_TABLE,
  FASTPREVIEW_START,
  FASTPREVIEW_CLEAR,
} from './FastPreviewTypes';

const INITIAL_STATE = {
  loading: true,
  error: false,
  result: {},
  resultTable: {},
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FASTPREVIEW_START:
      return { ...state, loading: true, error: null };
    case FASTPREVIEW_GET_RESULT:
      return {
        ...state,
        result: {
          ...action.payload,
          files: (action.payload?.files || []).filter((file) => file.confirmed),
        },
      };
    case FASTPREVIEW_GET_RESULT_TABLE:
      return {
        ...state,
        resultTable: action.payload,
      };
    case FASTPREVIEW_CLEAR:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
