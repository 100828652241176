import { Language } from 'utils/locale';
import {
  alphabeticCustom,
  alphabeticOnly,
  dontAllowOnlySpacesandSpecialSymbols,
  emailRegexWithoutSymbols,
} from '../../../services/config';

export const profileDetailsFields = {
  title: {
    fieldName: 'title',
    label: 'title',
    type: 'select',
    options: [
      {
        label: 'titleDr',
        value: 'Dr',
      },
      {
        label: 'titleMr',
        value: 'Mr',
      },
      {
        label: 'titleMs',
        value: 'Ms',
      },
    ],
    required: false,
    className: 'login-input',
    span: 6,
    error: 'titleRequired',
  },
  language: {
    fieldName: 'language',
    label: 'language',
    type: 'select',
    options: [
      {
        label: 'English',
        value: Language.ENGLISH,
      },
      {
        label: 'German',
        value: Language.GERMAN,
      },
    ],
    required: false,
    className: 'login-input',
    span: 6,
    error: 'languageRequired',
  },
  firstName: {
    fieldName: 'firstName',
    label: 'firstName',
    type: 'text',
    required: true,
    className: 'login-input',
    span: 6,
    error: 'firstNameRequired',
    maxLength: 200,
    minLength: 1,
    pattern: dontAllowOnlySpacesandSpecialSymbols,
  },
  lastName: {
    fieldName: 'lastName',
    label: 'lastName',
    type: 'text',
    required: true,
    className: 'login-input',
    span: 6,
    error: 'lastNameRequired',
    maxLength: 200,
    minLength: 1,
    pattern: dontAllowOnlySpacesandSpecialSymbols,
  },
  email: {
    fieldName: 'email',
    label: 'email',
    type: 'email',
    multiline: true,
    className: 'login-input',
    required: true,
    span: 12,
    error: 'emailValidRequired',
    pattern: emailRegexWithoutSymbols,
  },
  notificationPreferences: {
    fieldName: 'notificationPreferences',
    label: 'notificationPreferences',
    type: 'select',
    options: [
      {
        label: 'notificationPreferencesDaily',
        value: 'daily',
      },
      {
        label: 'notificationPreferencesWeekly',
        value: 'weekly',
      },
      {
        label: 'notificationPreferencesUnsubscribed',
        value: 'unsubscribed',
      },
    ],
    className: 'login-input',
    span: 12,
    error: 'notificationPreferencesRequired',
    required: false,
  },
};

export const profileFieldsName = [
  'title',
  'email',
  'firstName',
  'lastName',
  'notificationPreferences',
  'language',
];
