import React, { useMemo, useState } from 'react';
import { QueryBuilder } from './components/QueryBuilder';
import { Button, Grid, SvgIcon } from '@material-ui/core';
import { DataTable, Loading } from '../../components';
import {
  transformColumnsForDataSearch,
  transformManualInputsForDataSearch,
} from '../../utils/transform-helpers';

import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import './DataSearch.scss';

export const DataSearchView = ({
  fields,
  resultSearchMethod,
  data,
  query,
  loading,
  setHiddenColumns,
  hiddenColumns,
  setSelectedResults,
  selectedResults,
  exportSearchResults,
  totalNumberOfResults,
  t,
}) => {
  const tableColumns = useMemo(
    () => transformColumnsForDataSearch(data, query, fields, [], t),
    [query, data],
  );
  const formattedData = useMemo(
    () => transformManualInputsForDataSearch(data || []),
    [data],
  );
  const [tablePage, setTablePage] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(10);

  return (
    <Grid container>
      <Grid item xs={12} className={'query-builder-holder'}>
        <QueryBuilder fields={fields} submitMethod={resultSearchMethod} t={t} />
      </Grid>
      <Grid container xs={12} justify={'flex-end'}>
        <Button
          color={'primary'}
          variant={selectedResults?.length ? 'contained' : 'text'}
          disabled={!selectedResults?.length}
          onClick={() => {
            const filteredColumns = tableColumns.filter((tColumn: any) => {
              return !hiddenColumns.includes(tColumn.id);
            });
            exportSearchResults(filteredColumns);
          }}
        >
          <SvgIcon component={SaveAltOutlinedIcon} />
          {t('exportExcel')}
        </Button>
      </Grid>
      {loading ? (
        <Loading />
      ) : (
        <Grid item xs={12}>
          <DataTable
            columns={tableColumns}
            canHideColumns={true}
            canSelectRows={true}
            canUsePagination={true}
            data={formattedData || []}
            controlledPagination={true}
            passedState={{
              pageIndex: tablePage,
              pageSize: tablePageSize,
              totalNumberOfResults,
            }}
            onPageChange={(pageIndex, pageSize) => {
              resultSearchMethod(query, {
                page: pageIndex,
                limit: pageSize,
              });
              setTablePage(pageIndex);
            }}
            onPageSizeChange={(pageSize, pageIndex) => {
              resultSearchMethod(query, {
                page: pageIndex,
                limit: pageSize,
              });
              setTablePageSize(pageSize);
            }}
            onSelectRow={(data) => setSelectedResults(data)}
            onColumnHide={(column) => {
              setHiddenColumns(column);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
