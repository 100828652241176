export const GET_DATA_SEARCH = 'data-search/data-search-start';
export const GET_DATA_FIELDS = 'data-search/data-search-get-fields';
export const DATA_SEARCH_SUCCESS = 'data-search/data-search-success';
export const DATA_SEARCH_ERROR = 'data-search/data-search-error';
export const GET_DATA_SEARCH_RESULTS = 'data-search/data-search-clear';
export const DATA_SEARCH_RESET = 'data-search/data-search-reset';
export const DATA_SEARCH_SET_QUERY = 'data-search/data-search-set-query';
export const DATA_SEARCH_SELECT_RESULTS =
  'data-search/data-search-select-result';
export const DATA_SEARCH_SELECT_ALL_RESULTS =
  'data-search/data-search-select-all-results';
export const DATA_SEARCH_HIDE_COLUMNS = 'data-search/data-search-hide-columns';
export const DATA_SEARCH_EXPORT_RESULTS =
  'data-search/data-search-export-results';
export const DATA_SEARCH_APPEND_STATISTICAL_OPERATION =
  'data-search/data-search-append-statistical-operation';
export const DATA_SEARCH_ADD_COLUMN =
  'data-search/data-search-add-compute-column';
export const DATA_SEARCH_REMOVE_COLUMN =
  'data-search/data-search-remove-compute-column';
export const DATA_SEARCH_SET_COMPUTE_COLUMNS =
  'data-search-data/search-set-compute-columns';
export const DATA_SEARCH_SET_STATISTICAL_OPERATIONS =
  'data-search-data/search-set-statistical-operations';
export const DATA_SEARCH_CLEAR_OPERATIONS =
  'data-search-data/search-clear-operations';
