
export const GET_LOCATION = 'location/location-form-start';
export const GET_LOCATION_SUCCESS = 'location/location-form-success';
export const GET_LOCATION_ERROR = 'location/location-form-error';
export const LOCATION_CLEAR = 'location/location-form-clear';
export const LOCATION_SINGLE_SUCCESS = 'location/location-single-success';
export const LOCATION_UPDATE_SUCCESS = 'location/location-update-success';
export const LOCATION_CREATE_SUCCESS = 'location/location-create-success';
export const LOCATION_AUDIT = 'location/audit';
export const GET_LOCALES = 'location/locales-success';
export const GET_BOARDS = 'location/get-boards';