import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AdminRoutes } from 'routes/AdminRoutes';
import { Header } from 'components';
import { Account, Notification } from 'containers';
import { isAuth } from 'services/auth';
import Configuration from 'services/configRegistry';

import { CustomModal } from '../../components/CustomModal/CustomModal';
import { isSuperAdmin } from 'services/account';
import { NotFound } from 'containers';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dashboard from 'containers/Dashboard/Dashboard';
import useScrollToId from '../../utils/hooks/useScrollToId';
import {
  getUrlCurrentPath,
  permissionErrorContent,
} from '../../utils/get-url-current-path';

import './AdminLayout.scss';
import { useTranslation } from 'react-i18next';

export const AdminLayout = ({ path }) => {
  const [isOpen, setIsOpen] = useState(!(window.outerWidth < 1200));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pathname, hash, key] = useScrollToId();
  const history = useHistory();

  const { t } = useTranslation('permissions');

  useEffect(() => {
    setIsModalOpen(window.location.hash === '#permission-error');
  }, [window.location.hash]);

  const closeSidebar = () => {
    let savedSidebar: any = localStorage.getItem('sidebar');

    if (
      window.outerWidth < 1200 ||
      (savedSidebar && !parseInt(savedSidebar, 10))
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const toggleSidebar = () => {
    localStorage.setItem('sidebar', isOpen ? '0' : '1');
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    closeSidebar();
    window.addEventListener('resize', closeSidebar);
    const configuration = new Configuration();
    return () => {
      window.removeEventListener('resize', closeSidebar);
    };
  }, []);
  if (!isAuth()) {
    return <Redirect to="/login" />;
  }

  const getUrlPath = getUrlCurrentPath()?.pop();
  const notificationRedirect = getUrlPath?.includes('#');

  return (
    <div className="admin">
      <Header toggleSidebar={toggleSidebar} />

      <React.Suspense fallback="loading..">
        <div className={`content ${isOpen ? 'content--open' : ''}`}>
          <Switch>
            <Route exact path="/dashboard">
              <Dashboard />
            </Route>
            {AdminRoutes.map((route: any, index) => {
              if (route.allowed) {
                const routeProps = { ...route, path: path + route.path };
                return <Route exact {...routeProps} key={route.path + index} />;
              }

              return null;
            })}
            <Route component={NotFound} />
          </Switch>

          <CustomModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            confirm={() => {
              setIsModalOpen(false);
              localStorage.removeItem('responseError');

              if (notificationRedirect) {
                history.push(
                  isSuperAdmin() ? '/dashboard/company' : '/dashboard',
                );
                return;
              }
              history.push(
                isSuperAdmin() ? '/dashboard/company' : '/dashboard/project',
              );
            }}
            data={{
              dialogTitle: t('permissionError'),
              dialogContentText: (
                <div>
                  <div>
                    {notificationRedirect
                      ? t(permissionErrorContent.result)
                      : t(permissionErrorContent.default)}
                  </div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{t('showMore')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {t('actionNotAllowed') + ' '}
                        <b>{localStorage.getItem('responseError')}</b>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ),
              confirmText: t('confirmText'),
            }}
          />
        </div>
      </React.Suspense>
      <Account />
      <Notification />
    </div>
  );
};
