import React, { useState, useEffect } from 'react';
import './TableFilter.scss';
import { Grid, TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

export const TableFilter = (props) => {
  const { filters, submitFilters } = props;
  const [searchKeyword, setSearchKeyword] = useState(filters.filter || '');
  const { t } = useTranslation('common');

  useEffect(() => {
    if (searchKeyword !== filters.filter) {
      const debouncer = setTimeout(() => {
        submitFilters({
          ...filters,
          page: 0,
          filter: searchKeyword.trim(),
        });
      }, 500);
      return () => clearTimeout(debouncer);
    }
  }, [searchKeyword]);

  const changeInputHandler = (event) => {
    setSearchKeyword(event.target.value);
  };

  return (
    <Grid container justify="flex-end">
      <TextField
        className="search-input"
        placeholder={t('search')}
        onChange={(event) => changeInputHandler(event)}
        value={searchKeyword}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Grid>
  );
};
