import { ForgotPassword, InitialSetup, Login, ResetPassword } from 'containers';
import { Terms } from '../containers/Terms/Terms';
import { ActiveDirectorySuccess } from '../containers/_Default/Login/ActiveDirectory/ActiveDirectorySuccess/ActiveDirectorySuccess';
import { ActiveDirectoryFail } from '../containers/_Default/Login/ActiveDirectory/ActiveDirectoryFail/ActiveDirectoryFail';

export const PublicRoutes = [
  {
    component: Login,
    path: 'login',
  },
  {
    component: ForgotPassword,
    path: 'forgot-password',
  },
  {
    component: InitialSetup,
    path: 'initial-setup/:id',
  },
  {
    component: ResetPassword,
    path: 'activate-user/:token*',
  },
  {
    component: ResetPassword,
    path: 'reset-password/:token*',
  },
  {
    component: ActiveDirectorySuccess,
    path: 'ad/success/:token*',
  },
  {
    component: ActiveDirectoryFail,
    path: 'ad/fail',
  },
  {
    component: Terms,
    path: 'terms',
  },
];
