import API from 'services/api';

export const createSetting = async (data: any) => {
  return await API.post('/setting', data);
};

export const editSetting = async (id: number, data: any) => {
  return await API.put(`/setting/${id}`, data);
};

export const getSetting = async (id) => {
  return await API.get(`/setting/${id}`);
};

export const getCustomSetting = async (query) => {
  return await API.get(`/setting/custom`, { params: query });
};

export const getQuerySetting = async (query) => {
  return await API.get(`/setting/query`, { params: query });
};

export const getAllSetting = async () => {
  return await API.get(`/setting/all`);
};

export const removeSetting = async (id) => {
  return await API.delete(`/setting/${id}`);
};

export const getSettings = async (props) => {
  const { limit = 10, page = 0, sort, name } = props;
  return await API.get('/setting', {
    params: { limit, page, sort, name },
  });
};
