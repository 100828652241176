import {
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TableFilter } from 'containers/_Default/TableFilter/TableFilter';
import React, { useEffect, useState } from 'react';

export const FilterTable = (props) => {
  const {
    filtersPassed = {
      page: 0,
      limit: 10,
      filter: '',
    },
    setChecked,
    fetchMethod = () => {},
    processRows = (data) => data,
    setFilters,
    existing = [],
    canEdit = false,
    usedInTest = (entity) => false,
    t = () => {},
  } = props;

  const [checkedItems, setCheckedItems] = useState(existing as any);

  const [data, setData] = useState({ docs: [] });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getData();
  }, [filtersPassed]);

  useEffect(() => {
    setChecked(checkedItems);
  }, [checkedItems]);

  useEffect(() => setRows(processRows([...data.docs])), [data]);

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: any,
  ) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, row]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item._id !== row._id));
    }
  };

  async function getData() {
    try {
      // Check if data is provided flag
      const response = await fetchMethod(filtersPassed);
      setData(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Grid container xs={12} justify="space-between">
      <Grid item xs={12}>
        <TableFilter filters={filtersPassed} submitFilters={setFilters} />
      </Grid>
      <Grid item xs={12} style={{ maxHeight: 350, overflowY: 'auto' }}>
        {rows.length ? (
          rows.map((row: any, index) => {
            const checked =
              checkedItems.findIndex((item) => item._id === row._id) > -1;

            const disabled =
              !canEdit &&
              existing.findIndex((item) => item._id === row._id) > -1 &&
              usedInTest(row._id);
            return (
              <Grid container direction="row">
                <Grid item xs={10}>
                  {row.name}
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    size="medium"
                    color="primary"
                    style={{ transform: 'scale(1.5)' }}
                    onChange={(event) => handleCheck(event, row)}
                    value={row._id}
                    checked={checked}
                    disabled={disabled}
                    disableRipple
                  />
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid container>
            <Grid item direction="column" xs={12}>
              No results
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
