enum STATISTICAL_OPERATION {
  AVERAGE = 'Average',
  MEDIAN = 'Median',
  STANDARD_DEVIATION = 'Standard deviation',
  VARIANCE = 'Variance',
  SUM = 'Sum',
}

const statisticalOperationKeys = {
  [STATISTICAL_OPERATION.AVERAGE]: 'average',
  [STATISTICAL_OPERATION.MEDIAN]: 'median',
  [STATISTICAL_OPERATION.STANDARD_DEVIATION]: 'standardDeviation',
  [STATISTICAL_OPERATION.VARIANCE]: 'variance',
  [STATISTICAL_OPERATION.SUM]: 'sum',
};

const operations = {
  Sum: (arr) => arr.reduce((acc, val) => acc + parseFloat(val), 0),
  Average: (arr) =>
    arr.reduce((acc, val) => acc + parseFloat(val), 0) / arr.length,
  Median: (arr) => {
    arr.sort((a, b) => a - b);
    let medianResult;
    if (arr.length % 2 === 0) {
      medianResult = (arr[arr.length / 2 - 1] + arr[arr.length / 2]) / 2;
    } else {
      medianResult = arr[Math.floor(arr.length / 2)];
    }
    return medianResult;
  },
  Variance: (arr) => {
    let mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    let variance =
      arr.reduce((acc, val) => acc + (val - mean) ** 2, 0) / arr.length;
    return variance < 0 ? 0 : variance;
  },
  'Standard deviation': (arr) => {
    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
    const variance =
      arr.reduce((acc, val) => acc + (val - mean) ** 2, 0) / arr.length;

    return variance < 0 ? 0 : Math.sqrt(variance);
  },
  Default: () => '/',
};

const calculateStatisticalResult = (columns, rows) => {
  const config = JSON.parse(localStorage.getItem('config') as any);
  const precision = config?.decimalPlaces || 3;

  const columnValues = columns?.map((column) => ({
    ...column,
    values: getColumnValues(column, rows).filter(
      (value) => !isNaN(parseFloat(value)),
    ),
  }));

  return columnValues.map((column) => {
    const operation = operations[column.operation] || operations.Default;
    const columnResult = operation(column.values);
    return {
      ...column,
      result:
        columnResult === operations.Default()
          ? columnResult
          : columnResult.toFixed(precision),
    };
  });
};

const getColumnValues = (column, rows) => {
  const columnValues: any = [];
  rows.forEach((row) => {
    Object.entries(row.values).map(([key, value]: any) => {
      if (key === column.id) {
        columnValues.push(value);
      }
    });
  });

  return columnValues;
};

export {
  STATISTICAL_OPERATION,
  calculateStatisticalResult,
  statisticalOperationKeys,
};
