import React, { useEffect } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getFieldFromType } from '../../../components/Forms/FieldTypes';
import { InputError } from '../../../components';

export const RecipeIngredientsSection = ({
  ingredients,
  addRow,
  saveIngredient,
  isRecipe,
  t,
  material,
  materials,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [ingredientsState, setIngredientsState] = useState(ingredients);
  const form = useForm({});
  const watcher = form.watch();

  const updateRowPositionsAndPercentages = (rows) => {
    return rows.map((row, index) => ({
      ...row,
      percentage: row.percentage ? row.percentage : 0,
      position: index + 1,
    }));
  };

  const addNewRow = () => {
    const ingredient = {
      range: {},
      generatedNow: true,
      percentage: 0,
      position: (ingredients?.length || 0) + 1,
    };

    addRow(ingredient);
    setIngredientsState([...ingredientsState, ingredient]);
  };

  return (
    <Grid className={'box-holder'} xs={isRecipe ? 12 : 5}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const isFormValid = await form.trigger();
          if (isFormValid) {
            const ingredients = Object.values(data)
              .filter((item) => item.materialNumber)
              .map((item) => item);
            const ingredientsWithCorrectPositions = updateRowPositionsAndPercentages(
              ingredients,
            );
            const success: boolean = await saveIngredient(
              ingredientsWithCorrectPositions,
            );
            const mappedIngredients = ingredientsWithCorrectPositions.map(
              (ingredient) => {
                return {
                  ...ingredient,
                  generatedNow: false,
                };
              },
            );
            setIngredientsState(mappedIngredients);
            if (success) {
              setEditMode(false);
              form.reset(ingredients);
            }
          }
        })}
      >
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item xs={2}>
            <Typography variant={'h3'}>{t('ingredientsTitle')}</Typography>
          </Grid>
          <Grid item container xs={3}>
            {editMode && (
              <>
                <Grid item xs={6}>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={() => {
                      form.reset(ingredients);
                      setEditMode(false);
                      const filteredIngredients = ingredientsState.filter(
                        (ingredient) => {
                          return !ingredient.generatedNow;
                        },
                      );
                      setIngredientsState(filteredIngredients);
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button color="default" variant="outlined" type={'submit'}>
                    {t('save')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {!editMode && !material?.usedInProgressProject && (
            <Button
              color="default"
              variant="outlined"
              onClick={() => {
                setEditMode(true);
              }}
            >
              {t('edit')}
            </Button>
          )}
        </Grid>

        <Grid container>
          <TableContainer component={Paper} style={{ overflowX: 'visible' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('position')}</TableCell>
                  <TableCell>{t('materialNo')}</TableCell>
                  <TableCell>{t('materialName')}</TableCell>
                  <TableCell>{t('percentage')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredientsState.map((row: any, index) => (
                  <Row
                    row={row}
                    index={index}
                    form={form}
                    editMode={editMode}
                    t={t}
                    ingredients={ingredientsState}
                    materials={materials}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify={'flex-end'} key={'edit-' + editMode}>
            {editMode && (
              <IconButton
                // key={index}
                aria-label="edit"
                color="secondary"
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={addNewRow}
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
      <Grid item container xs={12} justify={'flex-end'}>
        <Typography variant={'body2'}>
          {t('totalPercentage')}
          {(form.formState.isDirty
            ? (Object.values(watcher) || []).reduce(
                (acc, item) => acc + parseFloat(item?.percentage || 0),
                0,
              )
            : (ingredients || []).reduce(
                (acc, item) => acc + parseFloat(item.percentage || 0),
                0,
              )
          ).toFixed(2)}
          %
        </Typography>
      </Grid>
    </Grid>
  );
};

const Row = ({
  row,
  ingredients,
  index,
  form,
  editMode,
  t,
  materials = [],
}) => {
  const materialNumberOptions = materials.map((option: any) => ({
    label: option.number,
    value: option.number,
    id: option._id,
  }));
  const materialNameOptions = materials.map((option: any) => ({
    label: option.name,
    value: option.name,
    id: option._id,
  }));

  const findSelectedAndUpdateNumber = (field: string, value) => {
    const material: any = materials.find(
      (material: any) => material.name === value,
    );

    const [index, fieldName] = field.split('.');

    form.setValue(`${index}.materialNumber`, material?.number);
  };

  const findSelectedAndUpdateName = (field: string, value) => {
    const material: any = materials.find(
      (material: any) => material.number === value,
    );

    const [index, fieldName] = field.split('.');

    form.setValue(`${index}.materialName`, material?.name);
  };

  const [
    filteredMaterialNameOptions,
    setFilteredMaterialNameOptions,
  ] = useState([] as any);
  const [
    filteredMaterialNumberOptions,
    setFilteredMaterialNumberOptions,
  ] = useState([] as any);

  useEffect(() => {
    if (ingredients.length) {
      const filteredNameOptions = materialNameOptions.filter(
        ({ label: label }) => {
          return !ingredients.some(({ materialName: materialName }) => {
            return label === materialName;
          });
        },
      );

      const filteredNumberOptions = materialNumberOptions.filter(
        ({ label: label }) =>
          !ingredients.some(
            ({ materialNumber: materialNumber }) => label === materialNumber,
          ),
      );

      setFilteredMaterialNameOptions([...filteredNameOptions]);
      setFilteredMaterialNumberOptions([...filteredNumberOptions]);
    }
  }, [form, editMode]);

  return !row.deleted ? (
    <TableRow key={'table-' + row.position + index}>
      <TableCell>
        {getFieldFromType(
          form,
          {
            fieldName: `${index}.position`,
            label: '',
            type: 'table-text',
            required: true,
            span: 6,
            error: ' ',
          } as any,
          row.position + '-position',
          ingredients,
          t,
          false,
        )}
      </TableCell>
      <TableCell
        className={
          editMode && row.generatedNow
            ? 'table-cell-edit-mode'
            : 'table-cell-view-mode'
        }
      >
        {getFieldFromType(
          form,
          {
            fieldName: `${index}.materialNumber`,
            type: 'materials-number-search-select',
            auxiliaryLabel: 'Material',
            options: filteredMaterialNumberOptions,
            required: false,
            className: 'login-input',
            span: 11,
            index: index,
          } as any,
          row.position + '-materialNumber',
          ingredients,
          t,
          editMode && row.generatedNow,
          false,
          (fieldName, value) => findSelectedAndUpdateName(fieldName, value),
        )}
      </TableCell>
      <TableCell
        className={
          editMode && row.generatedNow
            ? 'table-cell-edit-mode'
            : 'table-cell-view-mode'
        }
      >
        {getFieldFromType(
          form,
          {
            fieldName: `${index}.materialName`,
            type: 'materials-name-search-select',
            auxiliaryLabel: 'Material',
            options: filteredMaterialNameOptions,
            required: false,
            className: 'login-input',
            span: 11,
            index: index,
          } as any,
          row.position + '-materialName',
          ingredients,
          t,
          editMode && row.generatedNow,
          false,
          (fieldName, value) => findSelectedAndUpdateNumber(fieldName, value),
        )}
      </TableCell>
      <TableCell>
        <Grid container alignItems={'center'} justify="flex-start" xs={6}>
          <Grid item xs={10}>
            {getFieldFromType(
              form,
              {
                fieldName: `${index}.percentage`,
                label: '',
                type: 'table-text',
                required: false,
                subType: 'Float',
                span: 12,
                error: ' ',
              } as any,
              row.position + '-percentage',
              ingredients,
              t,
              editMode,
            )}
          </Grid>
          <Grid item xs={2}>
            %
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        {getFieldFromType(
          form,
          {
            fieldName: `${index}.deleted`,
            label: '',
            type: 'hidden',
            required: true,
            span: 6,
            error: ' ',
          } as any,
          row.position + '-deleted',
          ingredients,
          t,
          editMode && row.generatedNow,
        )}
      </TableCell>
      <TableCell>
        <Controller
          name={`${row.position}.position`}
          defaultValue={row.position}
          render={() => (
            <TextField
              name={`${row.position}.position`}
              value={row.position}
              type={'hidden'}
            />
          )}
          control={form.control}
        />
        <Controller
          name={`${row.position}._id`}
          defaultValue={row._id}
          render={() => (
            <TextField
              name={`${row.position}._id`}
              type={'hidden'}
              value={row._id}
            />
          )}
          control={form.control}
        />
      </TableCell>
      {form.formState.errors[row.position] ? (
        <TableCell>
          <InputError />
        </TableCell>
      ) : null}
    </TableRow>
  ) : null;
};
