import React from 'react';
export { Account } from './_Default/Account/Account';
export { Notification } from './_Default/Notification/Notification';
export const Login = React.lazy(() => import('./_Default/Login/Login'));
export const NotFound = React.lazy(() =>
  import('./_Default/NotFound/NotFound'),
);
export const ForgotPassword = React.lazy(() =>
  import('./_Default/ForgotPassword/ForgotPassword'),
);
export const ResetPassword = React.lazy(() =>
  import('./_Default/ResetPassword/ResetPassword'),
);
export const Role = React.lazy(() => import('./_Default/Role/Role'));
export const InitialSetup = React.lazy(() =>
  import('./_Default/InitialSetup/InitialSetup'),
);

// injection line - do not remove or edit
export const ProjectOverview = React.lazy(() =>
  import('./ProjectOverview/ProjectOverview'),
);
export const Device = React.lazy(() => import('./Device/Device'));
export const DeviceForm = React.lazy(() => import('./DeviceForm/DeviceForm'));
export const Project = React.lazy(() => import('./Project/Project'));
export const TestResults = React.lazy(() =>
  import('./TestResults/TestResults'),
);
export const Group = React.lazy(() => import('./Group/Group'));
export const GroupForm = React.lazy(() => import('./GroupForm/GroupForm'));
export const Location = React.lazy(() => import('./Location/Location'));
export const LocationForm = React.lazy(() =>
  import('./LocationForm/LocationForm'),
);
export const Company = React.lazy(() => import('./CompanyOverview/Company'));
export const CompanyForm = React.lazy(() =>
  import('./CompanyForm/CompanyForm'),
);

export const User = React.lazy(() => import('./User/User'));
export const UserForm = React.lazy(() => import('./UserForm/UserForm'));
export const FastPreview = React.lazy(() =>
  import('./FastPreview/FastPreview'),
);
export const TemplateOverview = React.lazy(() =>
  import('./TemplateOverview/TemplateOverview'),
);

export const TemplateForm = React.lazy(() =>
  import('./TemplateForm/TemplateForm'),
);

export const ProjectPlanOverview = React.lazy(() =>
  import('./ProjectPlanOverview/ProjectPlanOverview'),
);
export const Material = React.lazy(() => import('./Material/Material'));
export const MaterialForm = React.lazy(() =>
  import('./MaterialForm/MaterialForm'),
);
export const TestStandardOverview = React.lazy(() =>
  import('./TestStandardOverview/TestStandardOverview'),
);
export const TestStandard = React.lazy(() =>
  import('./TestStandard/TestStandard'),
);
export const ChatDialog = React.lazy(() => import('./ChatDialog/ChatDialog'));
export const ProjectRecipe = React.lazy(() =>
  import('./ProjectRecipe/ProjectRecipe'),
);

export const ProjectResultWrapper = React.lazy(() =>
  import('./ProjectResultWrapper/ProjectResultWrapper'),
);
