import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  MenuItem,
  Paper,
  ClickAwayListener,
  Grow,
  Popper,
  MenuList,
} from '@material-ui/core';
import { Arrow } from 'components';

import './LeftMenu.scss';

export const LeftMenu = ({ name, items, icon, activeRoute }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef?.current !== undefined &&
      anchorRef?.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  if (!items.some((item) => !!item.hasAccess)) {
    return null;
  }

  const isRouteActive = () => {
    let isSubrouteActive = false;

    items.forEach((item) => {
      if (!isSubrouteActive && activeRoute.includes(item.url)) {
        isSubrouteActive = true;
      }
    });

    return isSubrouteActive;
  };

  return (
    <>
      <Button
        startIcon={icon}
        className={`btn-menu-left${isRouteActive() ? ' active' : ''}`}
        ref={anchorRef}
        onClick={handleToggle}
      >
        <span className="menu-left-wrap">
          <div className="menu-left-name">{name}</div>
        </span>
        <span className="arrow">
          <Arrow color="#212121" />
        </span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className="menu-dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom-end',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {(items || []).map((item, index) => {
                    if (!item.hasAccess) return null;
                    return (
                      <MenuItem key={index}>
                        <Link
                          to={`/dashboard/${item.url}`}
                          onClick={handleClose}
                        >
                          {item.name}
                        </Link>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
