import { Grid, IconButton } from '@material-ui/core';
import React, { useState } from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';

import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import './ChartWidget.scss';

export const ChartWidget = ({ config, data, t }) => {
  const chartData = data[config.field] || [];

  const [disableZoomOut, setDisableZoomOut] = useState(true);
  const [left, setLeft] = useState('dataMin - 1');
  const [right, setRight] = useState('dataMax + 1');
  const [refAreaLeft, setRefAreaLeft] = useState(0 as any);
  const [refAreaRight, setRefAreaRight] = useState(0 as any);

  const zoom = (event) => {
    if (!event) {
      return;
    }

    if (refAreaLeft === refAreaRight || refAreaRight === 0) {
      setRefAreaRight(0);
      setRefAreaLeft(0);
    }

    // xAxis domain
    let refLeft: any = refAreaLeft;
    let refRight: any = refAreaRight;
    if (refLeft > refRight) {
      [refLeft, refRight] = [refRight, refLeft];
    }

    // reset dragging area
    setRefAreaRight(0);
    setRefAreaLeft(0);

    // set domain
    setLeft(refLeft);
    setRight(refRight);
    setDisableZoomOut(false);
  };

  const zoomReset = () => {
    setRefAreaRight(0);
    setRefAreaLeft(0);
    setLeft('dataMin - 1');
    setRight('dataMax + 1');
    setDisableZoomOut(true);
  };

  return (
    <Grid container justify="center" className="chart-widget">
      <Grid item sm={11}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 20,
              bottom: 10,
            }}
            onMouseDown={(e) => setRefAreaLeft(e?.activeLabel)}
            onMouseMove={(e) => refAreaLeft && setRefAreaRight(e?.activeLabel)}
            onMouseUp={zoom}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis
              dataKey="name"
              label={{ value: t('week'), position: 'insideBottom', offset: -7 }}
              type="number"
              fontSize="12px"
              interval={1}
              domain={[left, right]}
              allowDataOverflow={true}
            />
            <YAxis interval={1} />
            <Tooltip />
            <Bar dataKey="value" fill="#2c5364" />
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.4}
              />
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item sm={1}>
        <IconButton
          onClick={zoomReset}
          aria-label="delete"
          className="delete-btn"
          size="small"
          disabled={disableZoomOut}
        >
          <ZoomOutIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
