export const PROJECTEDIT_START = 'projectedit/projectedit-start';
export const PROJECTEDIT_SUCCESS = 'projectedit/projectedit-success';
export const PROJECTEDIT_SAVE_SUCCESS = 'projectedit/projectedit-save-success';
export const PROJECTEDIT_PLAN_SUCCESS = 'projectedit/projectedit-plan-success';
export const PROJECTEDIT_ERROR = 'projectedit/projectedit-error';
export const PROJECTEDIT_SETDEVICES = 'projectedit/projectedit-setdevices';
export const PROJECTEDIT_SETMEMBERS = 'projectedit/projectedit-setmembers';
export const PROJECTEDIT_SETTESTS = 'projectedit/projectedit-settests';
export const PROJECTEDIT_SET_RECIPE_TESTS =
  'projectedit/projectedit-set-recipe-tests';
export const PROJECTEDIT_SETPROJECTDOCUMENTS =
  'projectedit/projectedit-setprojectdocuments';
export const PROJECTEDIT_RESET = 'projectedit/projectedit-reset';
export const PROJECTEDIT_DUPLICATE_ID = 'projectedit/duplicate-projectId';
export const TESTMATRIX_SELECT_MATERIALS = 'testmatrix/select_materials';
export const TESTMATRIX_SELECT_STANDARDS = 'testmatrix/select_standards';
export const PROJECT_SPECIFICATION_ADD =
  'projectedit/projectedit-specification-add';
export const PROJECT_SPECIFICATION_SELECT_SPECIFICATIONS =
  'projectedit/projectedit-select-specifications';
export const PROJECT_SPECIFICATIONS_ADD_FROM_TEST =
  'projectedit/projectedit-specifications-add-from-test';
export const PROJECT_SPECIFICATIONS_REMOVE_FROM_TEST =
  'projectedit/projectedit-specifications-remove-from-test';
