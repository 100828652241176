import {
  TEST_STANDARD_SUCCESS,
  TEST_STANDARD_START,
  TEST_STANDARD_ERROR,
  TEST_STANDARD_CLEAR,
} from './TestStandardTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    testType: '',
    testSubType: '',
    device: '',
    template: '',
    name: '',
    canDisable: false,
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TEST_STANDARD_START:
      return { ...state, loading: true, error: null };

    case TEST_STANDARD_SUCCESS:
      const data = action.payload;
      data.testSubType.fields = data.testSubType?.fields.filter(
        (field) =>
          !['startDate', 'startTime', 'comments'].includes(field.fieldName),
      );
      return { ...state, data: action.payload, loading: false };

    case TEST_STANDARD_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
