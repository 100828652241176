import React from 'react';

import { termsText } from './TermsText';
import { Grid, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export const Terms = () => {
  const history = useHistory();
  const goToLoginPage = () => {
    history.push('/login');
  };
  return (
    <Grid container justify={'center'}>
      <Grid container item xs={12} md={6}>
        <Grid item container xs={12} alignItems={'center'}>
          <IconButton>
            <ArrowBack onClick={goToLoginPage}></ArrowBack>
          </IconButton>
          <span>Go back to login</span>
        </Grid>
        <Grid item container className="box-holder">
          <Grid item container xs={12}>
            <h1>Terms and conditions</h1>
          </Grid>
          <Grid item container xs={12}>
            <p>{termsText}</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
