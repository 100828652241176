import API from 'services/api';

export const getAllNotifications = async (props) => {
  const {page = 0, limit = 10, sort } = props;
  return await API.get('/notification/all', {
    params: { page, limit, sort }
  })
}
    
  export const getRecentNotifications = async (props) => {
    const {_limit = 5} = props;
    return await API.get('/notification/recent', {
      params: {_limit}
    })
  }

  export const readRecentNotification = async (id) => {
    return API.put(`/notification/read/${id}`)
  }

  export const getUnreadNotifications = async () => {
    return API.get('/notification/unread');
  }

  export const readAllRecentNotifications = () => {
    return API.put('notification/all/read');
  }