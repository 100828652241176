export const GET_DEVICE = 'device/device-form-start';
export const GET_DEVICE_CATALOGUE = 'device/device-catalogue-start';
export const GET_DEVICE_CATALOGUE_SUCCESS = 'device/device-catalogue-success';
export const GET_DEVICE_CATALOGUE_ERROR = 'device/device-catalogue-error';
export const GET_DEVICE_PROFILE = 'device/device-profile-start';
export const GET_DEVICE_PROFILE_SUCCESS = 'device/device-profile-success';
export const GET_DEVICE_PROFILE_ERROR = 'device/device-profile-error';
export const GET_DEVICE_SUCCESS = 'device/device-form-success';
export const GET_DEVICE_ERROR = 'device/device-form-error';
export const DEVICE_CLEAR = 'device/device-form-clear';
export const DEVICE_SINGLE_SUCCESS = 'device/device-single-success';
export const DEVICE_UPDATE_SUCCESS = 'device/device-update-success';
export const DEVICE_CREATE_SUCCESS = 'device/device-create-success';
export const DEVICE_AUDIT = 'device/audit';
export const GET_LOCALES = 'device/locales-success';
export const GET_BOARDS = 'device/get-boards';
