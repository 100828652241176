export const PROJECT_RECIPE_START = 'project-recipe/start';
export const PROJECT_RECIPE_SUCCESS = 'project-recipe/success';
export const PROJECT_RECIPE_ERROR = 'project-recipe/error';
export const PROJECT_RECIPE_RESET = 'project-recipe/reset';
export const RECIPE_ADD_ROW = 'recipe/add-row';
export const RECIPE_ADD = 'recipe/add';
export const RECIPE_EDIT_PERCENTAGE = 'recipe/edit-percentage';
export const RECIPE_EDIT_MATERIAL = 'recipe/edit-material';
export const RECIPE_SAVE_SUCCESS = 'recipe/save-success';
export const RECIPE_MARK_FINAL = 'recipe/mark-final';
export const RECIPE_COMPLETE = 'recipe/complete';
export const PROJECT_RECIPE_FILTERED_RECIPES_SUCCESS =
  'project-recipe/save-filtered-recipes-success';
export const PROJECT_RECIPE_FILTER_RECIPE = 'project-recipe/filter-recipe';
export const PROJECT_RECIPE_FILTERED_RECIPES_RESULTS_SUCCESS = 'project-recipe/save-filtered-recipes-results-success';
