import { combineReducers } from 'redux';
import LoginReducer from 'containers/_Default/Login/LoginReducer';
import AccountReducer from 'containers/_Default/Account/AccountReducer';
import ForgotPasswordReducer from 'containers/_Default/ForgotPassword/ForgotPasswordReducer';
import UserReducer from 'containers/User/UserReducer';
import UserFormReducer from 'containers/UserForm/UserFormReducer';
import RoleReducer from 'containers/_Default/Role/RoleReducer';
import ResetPasswordReducer from 'containers/_Default/ResetPassword/ResetPasswordReducer';
import NotificationReducer from 'containers/_Default/Notification/NotificationReducer';
// import injection line - do not remove or edit
import DeviceReducer from 'containers/Device/DeviceReducer';
import DeviceFormReducer from 'containers/DeviceForm/DeviceFormReducer';
import ProjectReducer from 'containers/Project/ProjectReducer';
import GroupReducer from 'containers/Group/GroupReducer';
import GroupFormReducer from 'containers/GroupForm/GroupFormReducer';
import LocationReducer from 'containers/Location/LocationReducer';
import LocationFormReducer from 'containers/LocationForm/LocationFormReducer';
import CompanyOverviewReducer from 'containers/CompanyOverview/CompanyOverviewReducer';
import CompanyFormReducer from 'containers/CompanyForm/CompanyFormReducer';
import TestResultsReducer from 'containers/TestResults/TestResultsReducer';
import ProjectDataReducer from './containers/ProjectData/ProjectDataReducer';
import FastPreviewReducer from './containers/FastPreview/FastPreviewReducer';
import DashboardReducer from 'containers/Dashboard/DashboardReducer';
import TemplateOverviewReducer from './containers/TemplateOverview/TemplateOverviewReducer';
import TemplateFormReducer from './containers/TemplateForm/TemplateFormReducer';
import DataSearchReducer from './containers/DataSearch/DataSearchReducer';
import RecentNotificationsReducer from './components/RecentNotification/RecentNotificationsReducer';
import ProfileReducer from './containers/Profile/components/ProfileReducer';
import ProjectPlanOverviewReducer from 'containers/ProjectPlanOverview/ProjectPlanOverviewReducer';
import MaterialReducer from 'containers/Material/MaterialReducer';
import MaterialFormReducer from 'containers/MaterialForm/MaterialFormReducer';
import MaterialDetailsReducer from './containers/MaterialDetails/MaterialDetailsReducer';
import TestStandardOverviewReducer from './containers/TestStandardOverview/TestStandardOverviewReducer';
import TestStandardReducer from 'containers/TestStandard/TestStandardReducer';
import TestStandardFormReducer from './containers/TestStandardOverviewForm/TestStandardFormReducer';
import ChatDialogReducers from 'containers/ChatDialog/ChatDialogReducers';
import ProjectRecipeReducer from './containers/ProjectRecipe/ProjectRecipeReducer';

export default combineReducers({
  login: LoginReducer,
  account: AccountReducer,
  forgotPassword: ForgotPasswordReducer,
  user: UserReducer,
  userForm: UserFormReducer,
  role: RoleReducer,
  resetPassword: ResetPasswordReducer,
  notification: NotificationReducer,
  // register injection line - do not remove or edit
  device: DeviceReducer,
  deviceForm: DeviceFormReducer,
  project: ProjectReducer,
  group: GroupReducer,
  groupForm: GroupFormReducer,
  location: LocationReducer,
  locationForm: LocationFormReducer,
  companyOverview: CompanyOverviewReducer,
  companyForm: CompanyFormReducer,
  testResults: TestResultsReducer,
  projectData: ProjectDataReducer,
  fastPreview: FastPreviewReducer,
  dashboard: DashboardReducer,
  templateOverview: TemplateOverviewReducer,
  templateForm: TemplateFormReducer,
  dataSearch: DataSearchReducer,
  recentNotification: RecentNotificationsReducer,
  profile: ProfileReducer,
  projectPlans: ProjectPlanOverviewReducer,
  material: MaterialReducer,
  materialForm: MaterialFormReducer,
  materialDetails: MaterialDetailsReducer,
  testStandards: TestStandardOverviewReducer,
  testStandard: TestStandardReducer,
  testStandardForm: TestStandardFormReducer,
  chat: ChatDialogReducers,
  projectRecipe: ProjectRecipeReducer,
});
