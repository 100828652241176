import { ERROR, SUCCESS, CLEAR, LOADING } from './NotificationTypes';

const INITIAL_STATE = {
  isOpen: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        message: action.payload.message,
        isError: true,
        isSuccess: false,
        isLoading: false,
      };

    case SUCCESS:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        message: action.payload.message,
        isError: false,
        isSuccess: true,
        isLoading: false,
      };

    case LOADING:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        message: action.payload.message,
        isError: false,
        isSuccess: false,
        isLoading: true,
      };

    case CLEAR:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
