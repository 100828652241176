import React from 'react';

import { Table } from 'components/Table/Table';

import './TableWidget.scss';

export const TableWidget = ({ config, data }) => {
  return (
    <div className="table-widget">
      <Table
        columnNames={config.tableColumns}
        processData={config.processData}
        title={''}
        dataPassed={{ docs: data[config.field] }}
        tablePagination={false}
        searchEnabled={false}
        fetchDataMethod={() => {}}
        filtersPassed={{}}
        className="test-info-table"
        useLocalData={true}
      />
    </div>
  );
};
