import { ITableHeaderColumn } from '../../components/Table/components/TableHeader/TableHeader';

export const notificationsTableColumn: ITableHeaderColumn[] = [
  {
    type: 'label',
    label: 'date',
    field: 'createdAt',
    sortable: true,
  },
  {
    type: 'label',
    label: 'type',
    field: 'type',
  },
  {
    type: 'label',
    label: 'content',
    field: 'content',
  },
];
