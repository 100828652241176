import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Switch, matchPath } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';

import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { configureStore } from './store';
import * as serviceWorker from './serviceWorker';
import { PublicLayout, AdminLayout } from './layouts';
import { AppTheme } from './theme/theme';

import { AdminRoutes } from 'routes/AdminRoutes';
import { PublicRoutes } from 'routes/PublicRoutes';

import './i18n';

const browserHistory = createBrowserHistory({ basename: '/' });
const store = configureStore();

if (!!process.env.REACT_APP_SENTRY_URL) {
  const paths = [
    ...AdminRoutes.map((route) => route.path),
    ...PublicRoutes.map((route) => route.path),
  ].filter((path) => path !== '*');

  const routes = Array.from(new Set(paths)).map((path) => ({
    path: `/dashboard/${path}`,
    exact: true,
    strict: false,
  }));

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    normalizeDepth: 5,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [window.location.hostname],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          browserHistory,
          routes,
          matchPath,
        ),
      }),
    ],
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={AppTheme}>
        <Router history={browserHistory}>
          <Switch>
            <AdminLayout path="/dashboard/" />
            <PublicLayout path="/" />
          </Switch>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
