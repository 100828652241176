import React from 'react';
import { TextField, Popover, IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

export const TableGlobalFilter = ({ filter, setFilter, debounceSearch, t }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TextField
      className="search-input"
      placeholder={t('datatable_search')}
      onChange={(e) => {
        setFilter(e.target.value);
        debounceSearch(e.target.value);
      }}
      value={filter}
      InputProps={{
        endAdornment: (
          <IconButton>
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );
};
