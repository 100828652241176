import React from 'react';
import { Paper } from '@material-ui/core';

import './BoxWrap.scss';

export const BoxWrap = ({ children, className = '' }) => {
  return <Paper className={`box-wrap ${className}`}>{children}</Paper>;
};

BoxWrap.Toolbar = ({ children, variant = 'primary' }) => {
  return (
    <div className={`box-toolbar box-toolbar--${variant}`}>{children}</div>
  );
};

BoxWrap.InfoBox = ({ children, variant = 'primary' }) => {
  return <div className={`box-info box-info--${variant}`}>{children}</div>;
};
