import {
  RECENT_NOTIFICATIONS_START,
  RECENT_NOTIFICATIONS_ERROR,
  RECENT_NOTIFICATIONS_SUCCESS,
  UNREAD_NOTIFICATIONS_START,
  UNREAD_NOTIFICATIONS_SUCCESS,
  UNREAD_NOTIFICATIONS_ERROR,
  MARK_ALL_READ_NOTIFICATIONS_SUCCESS,
  MARK_ALL_READ_NOTIFICATIONS_ERROR,
  MARK_ALL_READ_NOTIFICATIONS_START,
} from './RecentNotificationsTypes';

const INITIAL_STATE = {
  loading: true,
  error: false,
  message: '',
  unreadNotifications: [],
  data: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RECENT_NOTIFICATIONS_START:
      return { ...state, loading: true, error: null };

    case RECENT_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case RECENT_NOTIFICATIONS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case UNREAD_NOTIFICATIONS_START:
      return { ...state, loading: true, error: null };

    case UNREAD_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, unreadNotifications: action.payload };

    case UNREAD_NOTIFICATIONS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case MARK_ALL_READ_NOTIFICATIONS_START:
      return { ...state, loading: true, error: null };
    case MARK_ALL_READ_NOTIFICATIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case MARK_ALL_READ_NOTIFICATIONS_SUCCESS:
      return { ...state, loading: false, unreadNotifications: [] };

    default:
      return state;
  }
};
