import React from 'react';
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';

const TimePickerField = (props) => {
  const { field, data, index, form } = props;
  return (
    <Grid
      item
      container
      key={`${field.label}-form-field`}
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <TimeField
        value={
          data && data[field?.fieldName]
            ? moment.utc(data[field.fieldName]).format('HH:mm')
            : '00:00'
        }
        onChange={(e: any) => {
          if (e) {
            const startTime = moment.utc('1970-01-01 ' + e.target.value);
            e = startTime.toDate();
          }
          form.setValue(field.fieldName, e);
        }}
        input={
          <TextField
            disabled={field.disabled}
            fullWidth
            value={
              data && data[field?.fieldName]
                ? moment.utc(data[field.fieldName]).format('HH:mm')
                : '00:00'
            }
            className="timeFieldInput"
          />
        }
      />
    </Grid>
  );
};

export default TimePickerField;
