import { getLocalAccount } from './account';

export const getPermissions = () => {
  const account = getLocalAccount();
  if (!account || !(account.role?.permissions || account.permissions)) {
    return false;
  }

  return account.role ? account.role?.permissions : account.permissions;
};
