import {
  DATA_SEARCH_ERROR,
  DATA_SEARCH_SUCCESS,
  GET_DATA_FIELDS,
  GET_DATA_SEARCH_RESULTS,
  GET_DATA_SEARCH,
  DATA_SEARCH_RESET,
  DATA_SEARCH_SET_QUERY,
  DATA_SEARCH_HIDE_COLUMNS,
  DATA_SEARCH_SELECT_RESULTS,
  DATA_SEARCH_EXPORT_RESULTS,
  DATA_SEARCH_ADD_COLUMN,
  DATA_SEARCH_REMOVE_COLUMN,
  DATA_SEARCH_APPEND_STATISTICAL_OPERATION,
  DATA_SEARCH_SET_COMPUTE_COLUMNS,
  DATA_SEARCH_SET_STATISTICAL_OPERATIONS,
  DATA_SEARCH_CLEAR_OPERATIONS,
  DATA_SEARCH_SELECT_ALL_RESULTS,
} from './DataSearchTypes';
import { SEARCH_TYPES } from '../../utils/transform-helpers';

const INITIAL_STATE = {
  error: null,
  loading: false,
  fields: [],
  results: [],
  query: {},
  hiddenColumns: [],
  selectedResults: [],
  selectedTableRows: [],
  computedColumns: [],
  totalNumberOfResults: 0,
  statisticalOperations: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATA_SEARCH_RESET:
      return { ...INITIAL_STATE };
    case DATA_SEARCH_ERROR: {
      return { ...state, loading: false, error: true };
    }
    case DATA_SEARCH_ADD_COLUMN: {
      return {
        ...state,
        computedColumns: [
          ...state.computedColumns,
          { ...action.payload, subType: SEARCH_TYPES.FLOAT },
        ],
        hiddenColumns: state.hiddenColumns.filter(
          (column) => column !== action.payload?.id,
        ),
      };
    }

    case DATA_SEARCH_REMOVE_COLUMN: {
      return {
        ...state,
        computedColumns: [
          ...state.computedColumns.map((column: any) => ({
            ...column,
            visible: column.id !== action.payload.id,
          })),
        ],
        hiddenColumns: [...state.hiddenColumns, action.payload.id],
      };
    }

    case DATA_SEARCH_SET_STATISTICAL_OPERATIONS: {
      return {
        ...state,
        statisticalOperations: action.payload,
      };
    }

    case DATA_SEARCH_SET_COMPUTE_COLUMNS: {
      return {
        ...state,
        computedColumns: action.payload,
      };
    }
    case DATA_SEARCH_SET_QUERY:
      return { ...state, query: action.payload };
    case GET_DATA_SEARCH:
      return { ...state, loading: true };

    case GET_DATA_FIELDS:
      return { ...state, loading: false, fields: action.payload };

    case GET_DATA_SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        results: action.payload.docs,
        totalNumberOfResults: action.payload.totalDocs,
      };

    case DATA_SEARCH_HIDE_COLUMNS:
      return {
        ...state,
        hiddenColumns: action.payload,
      };
    case DATA_SEARCH_APPEND_STATISTICAL_OPERATION:
      let statisticalOperationsCopy: any = state.statisticalOperations;
      const currentOperation = action.payload;

      const foundIndex: number = statisticalOperationsCopy.findIndex(
        (operation: {
          selectColumn: string;
          selectStatisticalOperation: string;
        }) => operation.selectColumn === currentOperation.selectColumn,
      );

      if (foundIndex === -1) {
        return {
          ...state,
          statisticalOperations: [
            ...state.statisticalOperations,
            currentOperation,
          ],
        };
      } else {
        statisticalOperationsCopy[foundIndex] = { ...currentOperation };
        return {
          ...state,
          statisticalOperations: [...statisticalOperationsCopy],
        };
      }
    case DATA_SEARCH_SELECT_RESULTS:
      const selectedResults: any = [];
      for (let [indexKey, value] of Object.entries(action.payload)) {
        if (value && state.results[indexKey]) {
          if (state.results[indexKey].resultTables?.resultTableRows?._id) {
            selectedResults.push(
              state.results[indexKey]?.resultTables?.resultTableRows?._id,
            );
          } else {
            selectedResults.push(state.results[indexKey]?._id);
          }
        }
      }
      return {
        ...state,
        selectedResults,
      };

    case DATA_SEARCH_SELECT_ALL_RESULTS:
      const allResults: any = [];
      const results = action.payload;
      results?.forEach((result) => {
        result?.resultTables.forEach((resultTable) => {
          resultTable.resultTableRows.forEach((resultTableRow) => {
            allResults.push(resultTableRow._id);
          });
        });
      });
      return {
        ...state,
        selectedResults: allResults,
      };

    case DATA_SEARCH_EXPORT_RESULTS:
      return { ...state, loading: false };

    case DATA_SEARCH_CLEAR_OPERATIONS:
      return {
        ...state,
        statisticalOperations: [],
        computedColumns: [],
        hiddenColumns: [],
      };
    default:
      return state;
  }
};
