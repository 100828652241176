import React, { useState, useEffect } from 'react';
import { NotificationCentreView } from './NotificationCentreView';
import { useSelector, useDispatch } from 'react-redux';
import { markAllNotificationsAsRead } from '../../components/RecentNotification/RecentNotificationsActions';

import './NotificationCentre.scss';
import { useTranslation } from 'react-i18next';

export default () => {
  const [filters, setFilters] = useState({
    limit: 10,
    page: 0,
    sort: '-createdAt',
  });
  const notifications = useSelector((state) => state.recentNotification);
  const { t, i18n } = useTranslation(['notifications', 'common']);
  const dispatch = useDispatch();
  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsRead());
    setFilters({ ...filters });
  };
  return (
    <div>
      <NotificationCentreView
        filters={filters}
        unreadNotifications={notifications.unreadNotifications}
        markAllAsRead={markAllAsRead}
        t={t}
        i18n={i18n}
      />
    </div>
  );
};
