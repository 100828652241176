import React from 'react';
import './MaterialDetails.scss';
import { Button, Grid, Tooltip, Typography } from '@material-ui/core';
import { SpecificationSection } from './components/SpecificationSection';
import { InformationSection } from './components/InformationSection';
import { PageTitle } from '../../components';
import { RecipeIngredientsSection } from './components/RecipeIngredientsSection';
import { useHistory } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';

export const MaterialDetailsView = (props) => {
  const {
    data,
    addSpecification,
    removeSpecification,
    saveSpecification,
    cancelSpecificationAction,
    saveInformation,
    goBackAction,
    addIngredient,
    saveIngredient,
    materials,
    t,
  } = props;

  const history = useHistory();
  const viewProject = (id: string) => {
    history.push(`/dashboard/project/${id}`);
  };

  return (
    <div className="page-material-details">
      <Grid item container justify={'space-between'}>
        <PageTitle title={data.name}>
          <Button color="primary" onClick={() => goBackAction()}>
            <Typography variant="body2">{t('goBack')}</Typography>
          </Button>
        </PageTitle>
      </Grid>

      <Grid container item spacing={3}>
        {data.type === 'Recipe' && (
          <Grid item container xs={6}>
            <RecipeIngredientsSection
              addRow={addIngredient}
              saveIngredient={saveIngredient}
              ingredients={data.ingredients}
              isRecipe={data.type === 'Recipe'}
              t={t}
              material={data}
              materials={materials}
            />
          </Grid>
        )}
        <Grid item container xs={data.type == 'Recipe' ? 6 : 12} spacing={1}>
          <SpecificationSection
            addRow={addSpecification}
            deleteRow={removeSpecification}
            saveSpecification={saveSpecification}
            rows={data.specificationRowMap}
            cancel={cancelSpecificationAction}
            isRecipe={data.type === 'Recipe'}
            t={t}
            material={data}
          />
          <InformationSection
            material={data}
            saveInformation={saveInformation}
            isRecipe={data.type === 'Recipe'}
            t={t}
          />
        </Grid>
        {
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {data.fromProject ? (
              <Button
                color="default"
                variant="outlined"
                onClick={() => viewProject(data.fromProject)}
              >
                {t('viewProject', { ns: 'project' })}
              </Button>
            ) : null}
            {data.final ? (
              <Tooltip title={t('finalRecipe')}>
                <CheckIcon fontSize={'large'} style={{ paddingTop: '' }} />
              </Tooltip>
            ) : null}
          </Grid>
        }
      </Grid>
    </div>
  );
};
