import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUrlCurrentPath } from '../get-url-current-path';

// Scroll to section id
const useScrollToId = () => {
  const { pathname, hash, key } = useLocation();

  const isNumeric = (value) => /^-?\d+$/.test(value);

  useEffect(() => {
    let defaultWaitTime = 500;
    const getUrlPath = getUrlCurrentPath().pop();

    if (isNumeric(getUrlPath?.slice(1)[0])) {
      defaultWaitTime = 2000;
    }
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const timer = setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id.split('/')[0]);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, defaultWaitTime);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [pathname, hash, key]); // do this on route change
  return [pathname, hash, key];
};

export default useScrollToId;
