import {
  GET_LOCALES,
  GET_BOARDS,
  GET_DEVICE,
  GET_DEVICE_ERROR,
  DEVICE_CLEAR,
  DEVICE_SINGLE_SUCCESS,
  DEVICE_UPDATE_SUCCESS,
  DEVICE_CREATE_SUCCESS,
  DEVICE_AUDIT,
  GET_DEVICE_CATALOGUE,
  GET_DEVICE_CATALOGUE_SUCCESS,
  GET_DEVICE_CATALOGUE_ERROR,
  GET_DEVICE_PROFILE,
  GET_DEVICE_PROFILE_SUCCESS,
  GET_DEVICE_PROFILE_ERROR,
} from './DeviceFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  data: {
    name: '',
    model: '',
    testType: '',
    testSubType: '',
    type: '',
    profile: '',
  },
  deviceCatalogue: [],
  deviceProfiles: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEVICE_AUDIT:
      return { ...state, audit: action.payload };

    case GET_BOARDS:
      return { ...state, boards: action.payload };

    case GET_LOCALES:
      return { ...state, locales: action.payload };

    case GET_DEVICE:
      return { ...state, loading: true };

    case DEVICE_SINGLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case DEVICE_UPDATE_SUCCESS:
      return { ...state, loading: false };

    case DEVICE_CREATE_SUCCESS:
      return { ...state, loading: false };

    case GET_DEVICE_ERROR:
      return { ...state, loading: false, data: null, error: action.payload };

    case DEVICE_CLEAR:
      return { ...state, loading: false, data: INITIAL_STATE, error: null };

    case GET_DEVICE_CATALOGUE:
      return { ...state, loading: true };

    case GET_DEVICE_CATALOGUE_SUCCESS:
      return { ...state, loading: false, deviceCatalogue: action.payload };

    case GET_DEVICE_CATALOGUE_ERROR:
      return {
        ...state,
        loading: false,
        deviceCatalogue: null,
        error: action.payload,
      };

    case GET_DEVICE_PROFILE:
      return { ...state, loading: true };

    case GET_DEVICE_PROFILE_SUCCESS:
      return { ...state, loading: false, deviceProfiles: action.payload };

    case GET_DEVICE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        deviceProfiles: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
