import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  usePagination,
} from 'react-table';

import {
  TableGlobalFilter,
  TableRowCheckbox,
  TableColumnHide,
  TablePaginationActions,
} from './components';

import {
  calculateStatisticalResult,
  statisticalOperationKeys,
} from '../../utils/statistical-operation';

import './DataTable.scss';
import { getUniqueElementsByField } from '../../utils/transform-helpers';
import { useTranslation } from 'react-i18next';

interface IDataTableProps {
  passedState?: any;
  columns: Array<any>;
  data: Array<any>;
  hiddenColumns?: Array<any>;
  removeColumn?: Function;
  title?: String;
  canSearchTable?: Boolean;
  statisticalOperations?: Array<Object>;
  canSortColumns?: Boolean;
  canHideColumns?: Boolean;
  canUsePagination?: Boolean;
  canSelectRows?: Boolean;
  canUseLink?: Boolean;
  generateTable?: Boolean;
  onPageChange?: Function;
  onPageSizeChange?: Function;
  debounceSearch?: Function;
  controlledPagination?: Boolean;
  onColumnHide?: Function;
  onSearch?: Function;
  onSelectRow?: Function;
  onDelete?: Function;
  canDelete?: Boolean;
  canDisablePagination?: boolean;
}

/**
 * Multipurpose table for displaying data with extended functionality
 * and dynamically loaded plugins
 *
 * https://react-table.tanstack.com/docs/overview
 *
 * @param passedState - (optional) passed table state (set as initial and reflected on change)
 * @param columns - table columns
 *  @param removeColumn - (optional) callback when removing columns
 * @param data - table rows
 * @param title - (optional) table title
 * @param canUsePagination - enable table pagination
 * @param canSortColumns - enable column sorting
 * @param canHideColumns - enable column hiding
 * @param canSearchTable - enable table search
 * @param statisticalOperations - enable statistical operations
 * @param canSelectRows - enable row selection
 * @param canUseLink
 * @param onPageChange - (optional) callback when changing a page
 * @param onPageSizeChange - (optional) callback when changing items per page
 * @param onColumnHide - (optional) callback when hiding a column
 * @param onSearch - (optional) callback when searching
 * @param onSelectRow - (optional) callback when selecting a row
 * @param controlledPagination - (optional) User controlled pagination
 * @param onDelete - (optional) callback when deleting rows
 * @param canDelete - (optionak) enable row deletion
 *
 * TODO:
 * - Add external search functionality (by replacing with external component)
 */
export const DataTable = ({
  passedState,
  columns,
  data,
  hiddenColumns,
  removeColumn,
  canSearchTable = false,
  canSortColumns = false,
  canHideColumns = false,
  canUsePagination = false,
  canSelectRows = false,
  canUseLink = false,
  generateTable = false,
  statisticalOperations = [],
  controlledPagination = false,
  title = '',
  debounceSearch = (_) => {},
  onPageChange = (_) => {},
  onPageSizeChange = (_) => {},
  onColumnHide = (_) => {},
  onSearch = (_) => {},
  onSelectRow = (_) => {},
  onDelete = (_) => {},
  canDelete = false,
  canDisablePagination = false,
}: IDataTableProps) => {
  const { t } = useTranslation('common');
  const loadPlugins = () => {
    const plugins: Array<any> = [];

    if (canSearchTable) {
      plugins.push(useGlobalFilter);
    }
    if (canSortColumns) {
      plugins.push(useSortBy);
    }
    if (canUsePagination) {
      plugins.push(usePagination);
    }
    if (canSelectRows) {
      plugins.push(useRowSelect);
      plugins.push((hooks) => {
        canSelectRows &&
          hooks.visibleColumns.push((columns) => [
            {
              id: 'Selection',
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  <TableRowCheckbox {...getToggleAllPageRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <TableRowCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
      });
    }
    return plugins;
  };

  const getHeaderProps = (column) => {
    const sortByProps = canSortColumns
      ? {
          ...column.getSortByToggleProps(),
        }
      : null;
    return sortByProps
      ? column.getHeaderProps({ ...sortByProps })
      : column.getHeaderProps();
  };

  const tableProps = useTable(
    {
      columns,
      data,
      initialState: passedState || {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    ...loadPlugins(),
  );

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    allColumns,
    rows,
    page,
    prepareRow,
    state,
    setHiddenColumns,
    getToggleHideAllColumnsProps,
    toggleHideAllColumns,
  } = tableProps;

  const handleChangePage = (event, newPage) => {
    tableProps.gotoPage(newPage);
    if (canUsePagination && typeof onPageChange === 'function') {
      onPageChange(newPage, state.pageSize);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    tableProps.setPageSize(Number(event.target.value));
    if (canUsePagination && typeof onPageSizeChange === 'function') {
      onPageSizeChange(Number(event.target.value), state.pageIndex);
    }
  };

  useEffect(() => {
    if (passedState?.hiddenColumns) {
      setHiddenColumns(passedState.hiddenColumns);
    }
  }, [passedState]);

  useEffect(() => {
    if (canSearchTable && typeof onSearch === 'function') {
      onSearch(state.globalFilter);
    }
  }, [state.globalFilter]);

  useEffect(() => {
    if (canSelectRows && typeof onSelectRow === 'function') {
      onSelectRow(state.selectedRowIds);
    }
  }, [state.selectedRowIds]);

  useEffect(() => {
    if (canHideColumns && typeof onColumnHide === 'function') {
      onColumnHide(state.hiddenColumns);
    }
  }, [state.hiddenColumns]);

  const renderRow = (row) => {
    prepareRow(row);
    return (
      <TableRow {...row.getRowProps()}>
        {row.cells.map((cell) => {
          if (canUseLink && cell?.column?.Header === 'Link') {
            const cellValue = cell.row.original;
            return (
              <TableCell {...cell.getCellProps()}>
                <Link to={`/${cellValue?.link}`}>
                  <LinkIcon color={cellValue?.read ? 'disabled' : 'inherit'} />
                </Link>
              </TableCell>
            );
          }

          if (cell?.column?.Header.toString().endsWith('.')) {
            return (
              <TableCell {...cell.getCellProps()}>
                {cell?.row?.original[cell?.column?.Header]}
              </TableCell>
            );
          } else {
            return (
              <TableCell {...cell.getCellProps()}>
                {cell.render('Cell')}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };
  let combinedData;

  if (statisticalOperations?.length && columns.length) {
    combinedData = calculateStatisticalResult(
      columns.filter((column) => !hiddenColumns?.includes(column.id)),
      rows,
    ).map((column) => ({
      name: column.Header,
      value: column.result,
      operation: column.operation,
    }));
  }

  const tableResultRow = (columns, operation) => {
    if (columns?.length && generateTable) {
      return (
        <TableRow>
          <TableCell colSpan={1}>
            {t(`results:statistical_${statisticalOperationKeys[operation]}`)}
          </TableCell>
          {combinedData?.map((column) => {
            return (
              <TableCell colSpan={1}>
                {operation === column.operation ? column.value : '/'}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  };

  return (
    <>
      <Grid container alignContent="center" className="table-title">
        {title ? (
          <Grid
            item
            container
            xs={10}
            justify="flex-start"
            alignContent="center"
          >
            <Grid item>
              <Typography variant="h4">{title}</Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid item container xs={title ? 2 : 12} justify="flex-end">
          {canHideColumns ? (
            <TableColumnHide
              columns={allColumns}
              toggleHideAllColumns={toggleHideAllColumns}
              canSelectRows={canHideColumns}
              getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
              t={t}
            />
          ) : null}
          {Object.keys(state.selectedRowIds || {}).length && canDelete ? (
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justify="flex-end"
              className="selecting"
            >
              <Grid item xs={12}>
                <Typography variant="body2">
                  {`${Object.keys(state.selectedRowIds || {}).length} `}
                  {t('itemsSelected')}
                </Typography>
              </Grid>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => onDelete(state.selectedRowIds)}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Grid>
          ) : null}

          {canSearchTable && !Object.keys(state.selectedRowIds || {}).length ? (
            <Grid container xs={9} alignItems="center" justify="flex-end">
              <Grid item>
                <TableGlobalFilter
                  filter={state.globalFilter}
                  debounceSearch={debounceSearch as any}
                  setFilter={tableProps.setGlobalFilter}
                  t={t}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <div className="datatable">
        <Table {...getTableProps()}>
          <TableHead>
            <TableRow>
              {headers.map(
                (column, index) =>
                  column.isVisible && (
                    <TableCell
                      {...getHeaderProps(column)}
                      key={`${column.id}-${index}`}
                    >
                      <Grid container>
                        <Grid item xs={10}>
                          {column.render('Header')}
                        </Grid>
                        <Grid item xs={2}>
                          {column.computational && removeColumn ? (
                            <div
                              onClick={() => removeColumn(column.id)}
                              style={{ cursor: 'pointer' }}
                            >
                              <HighlightOffIcon fontSize="inherit" />
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon fontSize="inherit" />
                        ) : (
                          <ArrowUpwardIcon fontSize="inherit" />
                        )
                      ) : (
                        ''
                      )}
                    </TableCell>
                  ),
              )}
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {canUsePagination && !controlledPagination
              ? page.map(renderRow)
              : rows.map(renderRow)}
            {getUniqueElementsByField(
              statisticalOperations as any[],
              'selectStatisticalOperation',
            )?.map((operation: any) =>
              tableResultRow(columns, operation.selectStatisticalOperation),
            )}
          </TableBody>
        </Table>
      </div>
      {canUsePagination && (
        <TableFooter style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                {
                  label: t('datatable_showAll'),
                  value: passedState?.totalNumberOfResults || data.length,
                },
              ]}
              colSpan={12}
              count={passedState?.totalNumberOfResults || data.length}
              rowsPerPage={state.pageSize}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t('datatable_ofPages')} ${count}`
              }
              page={state.pageIndex}
              SelectProps={{
                disabled: canDisablePagination,
                inputProps: { 'aria-label': t('datatable_rowsPerPage') },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={t('datatable_rowsPerPage')}
            />
          </TableRow>
        </TableFooter>
      )}
    </>
  );
};
