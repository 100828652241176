import API from 'services/api';

export const loginUser = async ({
  username,
  password,
  rememberMe,
  language,
}) => {
  return await API.post('/auth', { username, password, rememberMe, language });
};

export const loginADUserByToken = async (token) => {
  return await API.get(`/auth/ad/token/${token}`);
};
