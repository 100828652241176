import {
  TEST_STANDARDS_START,
  TEST_STANDARDS_SUCCESS,
  TEST_STANDARDS_UPDATE_SUCCESS,
} from './TestStandardOverviewTypes';

const INITIAL_STATE = {
  loading: true,
  error: false,
  data: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TEST_STANDARDS_START:
      return { ...state, loading: true, error: null };

    case TEST_STANDARDS_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case TEST_STANDARDS_UPDATE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
};
