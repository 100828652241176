import React from 'react';

import {
  Checkbox,
  Popover,
  Grid,
  IconButton,
  Typography,
  FormControlLabel,
} from '@material-ui/core';

import ViewColumnIcon from '@material-ui/icons/ViewColumn';

export const TableColumnHide = ({
  columns,
  getToggleHideAllColumnsProps,
  canSelectRows,
  toggleHideAllColumns,
  t,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [toggleAllAnchor, setToggleAllAnchor] = React.useState(null as any);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="secondary" onClick={handleClick} size="medium">
        <ViewColumnIcon fontSize="inherit" />
      </IconButton>
      <Popover
        className="table-column-hide"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container className="table-column-hide-items">
          <Grid item sm={12}>
            <Typography className="table-column-hide-title" variant="h4">
              {t('datatable_showHideColumns')}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  {...getToggleHideAllColumnsProps()}
                  indeterminate={
                    columns.filter((c) => !c.isVisible).length &&
                    columns[0].isVisible
                  }
                />
              }
              label={t('datatable_toggleAll')}
              ref={toggleAllAnchor}
            />
          </Grid>
          {columns.map((column) => {
            const selectionCheckbox = column?.id === 'Selection';

            if (
              (column.computational && !column.visible) ||
              selectionCheckbox
            ) {
              return;
            }
            return (
              <Grid
                item
                sm={4}
                key={column.id}
                style={{ wordBreak: 'break-all' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      {...column.getToggleHiddenProps()}
                      onClick={() => {
                        if (canSelectRows) {
                          if (!column.isVisible) {
                            columns[0].toggleHidden(false);
                          } else if (
                            column.isVisible &&
                            columns.filter((c) => !c.isVisible).length ===
                              columns.length - 2 &&
                            columns[0].isVisible
                          ) {
                            toggleHideAllColumns(true);
                          }
                        }
                        toggleAllAnchor?.click();
                      }}
                    />
                  }
                  label={column.Header}
                  disabled={selectionCheckbox}
                  checked={selectionCheckbox}
                />
              </Grid>
            );
          })}
        </Grid>
      </Popover>
    </>
  );
};
