import {
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  LOGIN_AFTER_CHANGE,
  ERROR
} from './ResetPasswordTypes';

const INITIAL_STATE = {
  loading: false,
  proceedToDashboard: false,
  error: null
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_PASSWORD_START:
      return { ...state, loading: true, error: null };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false };

    case LOGIN_AFTER_CHANGE:
      return { ...state, proceedToDashboard: true};
      
    case ERROR:
      return {...INITIAL_STATE, loading: false};
      
    default:
      return state;
  }
};
