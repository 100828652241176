import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import { InputError } from '../InputError/InputError';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import AddIcon from '@material-ui/icons/Add';

import './SearchDropdown.scss';

export const SearchDropdown = (props) => {
  const { form, field, styles, formData, t, hideButtons = false } = props;
  const [values, setValues] = useState([] as any);
  const [displayValues, setDisplayValues] = useState([] as any);
  const [selectedValue, setSelectedValue] = useState({} as any);

  const resetValuesFromForm = () => {
    const output = [] as any;
    const formValues = form.getValues(field.fieldName) || [];
    formValues.forEach((formValue) => {
      const foundField = field.options.find((option) => {
        return option.value === formValue._id;
      });
      if (foundField) {
        output.push(foundField);
      }
    });
    if (output.length) {
      setValues(output);
    }
    if (output.length) {
      setDisplayValues(form.getValues(field.fieldName + '-temp'));
    }
  };

  const resetValuesFromData = () => {
    let output = [] as any;
    formData[field.fieldName].forEach((field) => {
      output.push({
        label: field.email,
        value: field._id,
      });
    });
    setDisplayValues(output);
    setValues(output);
  };

  useEffect(() => {
    const formValue = form.getValues(field.fieldName);
    if (formValue && formValue.length) {
      resetValuesFromForm();
    } else if (formData && formData[field.fieldName]) {
      resetValuesFromData();
    }
  }, []);

  useEffect(() => {
    form.setValue(
      field.fieldName,
      values.map((value) => {
        return { _id: value.value };
      }),
    );
  }, [values]);

  useEffect(() => {
    form.setValue(
      field.fieldName + '-temp',
      displayValues.map((value) => {
        return value;
      }),
    );
  }, [displayValues]);

  const isValueinArray = (value, array) => {
    return array.find((arrayValue) => {
      return arrayValue.value === value.value;
    });
  };
  const addToArray = (data) => {
    if (!data || !Object.keys(data).length) {
      return;
    }
    const valueInArray = isValueinArray(data, values);
    const valueInDisplayArray = isValueinArray(data, displayValues);

    if (!valueInArray) {
      setValues([
        ...values,
        {
          value: data.value,
          label: data.label,
        },
      ]);
    }
    if (!valueInDisplayArray) {
      setDisplayValues([
        ...displayValues,
        {
          value: data.value,
          label: data.label,
        },
      ]);
    }
  };

  const removeFromArray = (item) => {
    const newValues = [...values];
    let indexInArray = -1;
    values.forEach((value, index) => {
      if (value.value === item.value) {
        indexInArray = index;
      }
    });
    if (indexInArray > -1) {
      newValues.splice(indexInArray, 1);
      setValues(newValues);
    }
  };

  return (
    <Grid item xs={12} key="user-form-field">
      <Grid container alignItems="flex-end" justify="space-between" spacing={3}>
        <Grid item xs={9}>
          {selectedValue !== null ? (
            <FormLabel component="legend" filled={true} focused={true}>
              {t(field.label)}
            </FormLabel>
          ) : null}
          <ReactSelect
            options={field.options || []}
            onChange={(e) => {
              setSelectedValue(e);
            }}
            styles={styles}
            placeholder={field.placeholder || ''}
            value={selectedValue || {}}
          />
        </Grid>
        <Grid item container xs={3} justify={'flex-end'}>
          <Button
            color="primary"
            variant="contained"
            disabled={props.disabled || false}
            onClick={() => {
              if (selectedValue && Object.keys(selectedValue).length !== 0) {
                addToArray(selectedValue);
                setSelectedValue({});
              }
            }}
          >
            <SvgIcon component={AddIcon} />
            {t('add', { ns: 'common' })}
          </Button>
        </Grid>
        {form.formState.errors[field.fieldName] && (
          <InputError message={t(field.error)} />
        )}
      </Grid>
      <Box className={'results-holder'}>
        {displayValues.map((value, index) => {
          let stagedForRemoval = !isValueinArray(value, values);

          return (
            <Grid
              item
              container
              xs={12}
              justify={'space-between'}
              key={`${index}-results-holder`}
            >
              <Grid item container justify="center" direction="column" xs={9}>
                <Typography variant={'body1'}> {value.label} </Typography>
              </Grid>

              <Grid item container xs={3} justify={'flex-end'}>
                <Button
                  color={!stagedForRemoval ? 'primary' : 'default'}
                  variant="contained"
                  onClick={() => {
                    if (!stagedForRemoval) {
                      removeFromArray(value);
                    } else {
                      addToArray(value);
                    }
                  }}
                  disabled={props.disabled || false}
                >
                  {!stagedForRemoval ? t('remove', { ns: 'common' }) : t('undo', { ns: 'common' })}
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>

      <Controller
        name={field.fieldName}
        defaultValue={values}
        render={() => <TextField name={field.fieldName} type={'hidden'} />}
        control={form.control}
        rules={{
          validate: (newValues) =>
            (field.required && newValues.length > 0) || !field.required,
        }}
      />

      {/*THIS IS A MOCK FIELD FOR STORING THE TEMP DATA FOR TAB SWITCHING PURPOSES*/}
      <Controller
        name={field.fieldName + '-temp'}
        render={() => (
          <TextField name={field.fieldName + '-temp'} type={'hidden'} />
        )}
        defaultValue={displayValues}
        control={form.control}
      />
    </Grid>
  );
};
