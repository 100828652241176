import React, { Fragment } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { TableFilter } from '../../containers/_Default/TableFilter/TableFilter';
import './SelectDelete.scss';

type SelectDeleteProps = {
  text: string;
  deleteItems: any;
  selecting: boolean;
  selectedCount: number;
  filters: any;
  submitFilters: any;
  checkedItems: string[];
  t: any;
};

export const SelectDelete: React.FC<SelectDeleteProps> = ({
  text,
  deleteItems,
  selecting,
  selectedCount,
  filters,
  submitFilters,
  checkedItems,
  t,
}) => {
  return (
    <div className="header">
      {selecting ? (
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justify="flex-end"
          className="selecting"
        >
          <Grid item xs={12}>
            <Typography variant="body2">
              {`${selectedCount} `}{t('itemsSelected')}
            </Typography>
          </Grid>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => deleteItems(checkedItems)}
          >
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </Grid>
      ) : (
        <Grid container wrap="nowrap" alignItems="center" className="title">
          {
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="h4">{text}</Typography>
              </Grid>
              <TableFilter filters={filters} submitFilters={submitFilters} />
            </Fragment>
          }
        </Grid>
      )}
    </div>
  );
};
