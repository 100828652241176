import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const DateTimeRangeFormField = ({ form, field, formData, point }) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <TextField
          label={`${field.label} - ${point}`}
          type="datetime-local"
          fullWidth
          disabled={field.disabled}
          defaultValue={
            form.getValues(`${field.fieldName}.value.${point}`) ||
            (formData && formData[`${field.fieldName}.value.${point}`]) ||
            field.defaultDate ||
            null
          }
          onChange={(e) => {
            form.setValue(`${field.fieldName}.value.${point}`, e);
            onChange(e);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      name={`${field.fieldName}.value.${point}`}
      control={form.control}
      rules={{
        required: field.required,
      }}
      defaultValue={
        form.getValues(`${field.fieldName}.value.${point}`) ||
        (formData && formData[`${field.fieldName}.value.${point}`]) ||
        field.defaultDate ||
        ''
      }
    />
  );
};
const DateTimeRangePickerField = (props) => {
  const { form, field, formData } = props;

  return (
    <Grid
      item
      container
      key={`${field.label}-form-field`}
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6} className={'search-datepicker'}>
        <DateTimeRangeFormField
          field={field}
          form={form}
          formData={formData}
          point={'from'}
        />
      </Grid>
      <Grid item xs={6} className={'search-datepicker'}>
        <DateTimeRangeFormField
          field={field}
          form={form}
          formData={formData}
          point={'to'}
        />
      </Grid>
    </Grid>
  );
};

export default DateTimeRangePickerField;
