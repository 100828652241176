import {
  GET_LOCALES,
  GET_BOARDS,
  GET_GROUP,
  GET_GROUP_ERROR,
  GROUP_CLEAR,
  GROUP_SINGLE_SUCCESS,
  GROUP_UPDATE_SUCCESS,
  GROUP_CREATE_SUCCESS,
  GROUP_AUDIT,
  GROUP_EDIT,
} from './GroupFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  data: {
    name: '',
    description: '',
    leaders: [],
    members: '',
    location: '',
  },
  members: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GROUP_AUDIT:
      return { ...state, audit: action.payload };

    case GET_BOARDS:
      return { ...state, boards: action.payload };

    case GET_LOCALES:
      return { ...state, locales: action.payload };

    case GET_GROUP:
      return { ...state, loading: true };

    case GROUP_SINGLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case GROUP_UPDATE_SUCCESS:
      return { ...state, loading: false };

    case GROUP_CREATE_SUCCESS:
      return { ...state, loading: false };

    case GET_GROUP_ERROR:
      return { ...state, loading: false, data: null, error: action.payload };

    case GROUP_CLEAR:
      return { ...state, loading: false, data: null, error: null };

    case GROUP_EDIT: {
      return { ...state, data: action.payload };
    }

    default:
      return state;
  }
};
