import {
  LOGIN_CHANGED_LANGAUGE,
  LOGIN_START,
  LOGIN_SUCCESS,
} from './LoginTypes';
import * as AuthRepository from 'repository/auth';
import * as AuthService from 'services/auth';
import * as AccountService from 'services/account';
import { getCustomSetting } from 'repository/setting';
import { ERROR } from '../Notification/NotificationTypes';

export const triggerInitialSetup = (query, success = (data) => {}) => {
  return async (dispatch) => {
    try {
      const response = await getCustomSetting(query);

      if (response.data && response.data && response.data.value === 'false') {
        success(response.data);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const loginChangedLanguage = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });

    dispatch({
      type: LOGIN_CHANGED_LANGAUGE,
    });
  };
};

export const loginUser = (data, redirect = true, success = () => {}) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });

    try {
      const response = await AuthRepository.loginUser(data);

      if (response.data && response.data.access_token) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });

        AuthService.saveUserToken(response.data.access_token);
        AccountService.saveAccount(response.data.user);

        if (redirect) {
          window.location.href = AccountService.isSuperAdmin()
            ? '/dashboard/company'
            : '/dashboard';
        }

        success();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: LOGIN_SUCCESS,
      });

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const loginADUser = (token, redirect = true, success = () => {}) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_START,
    });

    try {
      const response = await AuthRepository.loginADUserByToken(token);

      if (response.data && response.data.access_token) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });

        AuthService.saveUserToken(response.data.access_token);
        AccountService.saveAccount(response.data.user);

        if (redirect) {
          window.location.href = AccountService.isSuperAdmin()
            ? '/dashboard/company'
            : '/dashboard';
        }

        success();
      } else {
        window.location.href = '/ad/fail';
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'Oops! Something bad happened!';

      dispatch({
        type: LOGIN_SUCCESS,
      });

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
