import React, { useState, useEffect, useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { PageTitle } from 'components';
import { ITableHeaderColumn } from 'components/Table/components/TableHeader/TableHeader';
import { Table } from 'components/Table/Table';
import './Material.scss';
import { getBaseMaterials, getRecipes } from 'repository/material';
import MaterialForm from 'containers/MaterialForm/MaterialForm';
import * as RoleManager from '../../services/role';
import { Box, Button, SvgIcon, Tab, Tabs } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { PriorityHigh, RemoveRedEye } from '@material-ui/icons';

export enum MaterialType {
  RECIPE = 'Recipe',
  BASE = 'Base Material',
}

export const MaterialView = (props) => {
  const {
    data,
    getAll,
    filters,
    setFilters,
    isInModal,
    handleEdit,
    handleView,
    t,
  } = props;

  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const [formOpen, setFormOpen] = useState(false as any);
  const [tabValue, setTabValue] = useState(0);
  const formData = config?.forms?.materialForm;

  if (formData.tab1.fields.baseFields) {
    formData.tab1.fields =
      tabValue === 0
        ? config?.forms?.materialForm.tab1.fields.baseFields
        : config?.forms.materialForm.tab1?.fields?.recipeFields;
  }

  const handleEditClick = (material) => {
    handleEdit(material);
    setFormOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setFilters({ ...filters, enabled: !!tabValue });
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        className={'tabPanel'}
        {...other}
      >
        {
          <div style={{ display: value === index ? 'block' : 'none' }}>
            {children}
          </div>
        }
      </div>
    );
  };

  const visibleFields = Object.values(formData?.tab1?.fields)
    ?.map((field: any) => ({
      type: 'label',
      label: t(field.label),
      field: field.fieldName,
      sortable: field.sortable,
      showInOverviewTable: field.showInOverviewTable,
    }))
    .filter((field: any) => !!field.showInOverviewTable);

  const filteredInProgressMaterials = data.docs?.filter(
    (material) => material.usedInProgressProject,
  );

  const usedInProgressMaterialIds = new Set(
    filteredInProgressMaterials?.map((record) => record._id),
  );

  const tableColumns: ITableHeaderColumn[] = [
    ...visibleFields,
    {
      type: 'icons',
      icons: [
        {
          icon: <PriorityHigh />,
          show: (row) => usedInProgressMaterialIds.has(row._id),
          placeholder: t('recipeUsedInProgressMessage'),
          callbackMethod: () => {},
        },
        {
          icon: <EditIcon />,
          show: RoleManager.isAbleTo('material', 'create'),
          callbackMethod: handleEditClick,
        },
        {
          icon: <RemoveRedEye />,
          show: RoleManager.isAbleTo('material', 'findAll'),
          callbackMethod: handleView,
        },
      ],
    },
  ];

  useEffect(() => {
    getAll(filters);
  }, []);

  const renderTabs = () => (
    <div>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label={t('baseMaterialTab')} />
        {config.features?.recipe?.enabled ? (
          <Tab label={t('recipeTab')} />
        ) : null}
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          {RoleManager.isAbleTo('material', 'create') ? (
            <Button
              onClick={() => setFormOpen(true)}
              color="primary"
              variant="contained"
            >
              <SvgIcon component={AddIcon} />
              {t('addNewBaseMaterial')}
            </Button>
          ) : null}
        </Box>
        <Table
          columnNames={tableColumns}
          fetchDataMethod={
            tabValue === 0 ? getBaseMaterials : () => ({ data: { docs: [] } })
          }
          title={t('baseMaterials')}
          filtersPassed={filters}
          processData={(data) => {
            return data?.map((row) => {
              return {
                ...row,
                type: t('materialForm_baseMaterial', {
                  ns: 'materials',
                }),
              };
            });
          }}
        />
      </TabPanel>

      {config.features?.recipe?.enabled ? (
        <TabPanel value={tabValue} index={1}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            {RoleManager.isAbleTo('material', 'create') ? (
              <Button
                onClick={() => setFormOpen(true)}
                color="primary"
                variant="contained"
              >
                <SvgIcon component={AddIcon} />
                {t('addNewRecipe')}
              </Button>
            ) : null}
          </Box>
          <Table
            columnNames={tableColumns}
            fetchDataMethod={
              tabValue === 1 ? getRecipes : () => ({ data: { docs: [] } })
            }
            title={t('recipes')}
            filtersPassed={filters}
            processData={(data) => {
              return data?.map((row) => {
                return {
                  ...row,
                  type: t('materialForm_recipe', {
                    ns: 'materials',
                  }),
                };
              });
            }}
          />
        </TabPanel>
      ) : null}
    </div>
  );

  const renderMemoTabs = useMemo(() => renderTabs(), [tabValue, filters, t]);

  return (
    <div className="page-material">
      {!isInModal && <PageTitle title={t('materialManagement')}></PageTitle>}
      {renderMemoTabs}
      <MaterialForm
        open={formOpen}
        setOpen={setFormOpen}
        setFilters={setFilters}
        t={t}
        tabValue={tabValue}
      />
    </div>
  );
};
