import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { DynamicForm } from '../../../components/Forms/DynamicForm/DynamicForm';
import ProfileImage from './AvatarProfile/ProfileImage';

import './ProfileDetails.scss';

const files: string[] = [];
const ProfileDetails = ({
  form,
  fields,
  formData,
  handleConfirm,
  submitFormActions,
  handleImageChange,
  file,
  imagePath,
  t
}) => {
  if (files.length >= 2) {
    files.shift();
  }
  if (file.image) {
    files.push(file.image.name);
  }

  formData.data.notificationPreferences =
    formData.data.notificationPreferences?.frequency;

  return (
    <Grid
      container
      alignItems="flex-start"
      justify="flex-start"
      alignContent="flex-start"
      xs={12}
    >
      <div className={'profile-wrapper'}>
        <Grid
          item
          xl={3}
          justify={'center'}
          style={{ display: 'flex', marginRight: '50px' }}
          spacing={3}
        >
          <ProfileImage
            file={file}
            handleImageChange={handleImageChange}
            imagePath={imagePath}
            t={t}
          />
        </Grid>
        <Grid item xl={9}>
          <DynamicForm
            form={form}
            formData={formData?.data}
            fields={fields}
            confirm={handleConfirm}
            submitForm={submitFormActions}
            fileExists={file.changed}
            t={t}
          />
        </Grid>
      </div>
    </Grid>
  );
};

export default ProfileDetails;
