export default {
  colors: [
    '#403e41',
    '#ff1a53',
    '#9fc653',
    '#ddb43c',
    '#ff661a',
    '#1a8cff',
    '#b61aff',
  ],
};
