export const printFieldName = (data) => {
  const actualData = data?.data?.gb || data;

  if (actualData?.hasOwnProperty('name')) {
    return actualData.name;
  }

  if (actualData?.hasOwnProperty('title')) {
    return actualData.title;
  }

  let result = null;
  Object.keys(actualData).forEach((key) => {
    if (
      typeof actualData[key] === 'string' &&
      !result &&
      !actualData[key].match(/^[0-9a-fA-F]{24}$/g)
    ) {
      result = actualData[key];
    }
  });

  return result;
};
