import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { printFieldName } from 'services/name';
import { Link } from 'react-router-dom';

import './DraggableItem.scss';

export const DraggableItem = ({ item, index, entity }) => {
  return (
    <Draggable
      key={item._id}
      draggableId={item._id}
      index={item.order || index}
    >
      {(provided, snapshot) => (
        <div
          className="dropzone-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Link to={`/dashboard/${entity}/${item._id}`}>
            {printFieldName(item)}
          </Link>
        </div>
      )}
    </Draggable>
  );
};
