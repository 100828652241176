import {
  GET_MATERIAL,
  MATERIAL_AUDIT,
  MATERIAL_CLEAR,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_SINGLE_SUCCESS,
  MATERIAL_UPDATE_SUCCESS,
} from './MaterialFormTypes';
import {
  ERROR,
  SUCCESS,
} from 'containers/_Default/Notification/NotificationTypes';
import { getAudit } from 'repository/audit';
import { createMaterial, editMaterial } from 'repository/material';

export const newMaterial = (data: any, callback = (response) => {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MATERIAL,
      });

      const response = await createMaterial(data);
      dispatch({
        type: MATERIAL_CREATE_SUCCESS,
      });

      if (typeof callback === 'function' && response?.data?._id) {
        callback(response.data);
      }

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_createSuccess',
        },
      });

      const audit = await getAudit(response.data._id);
      dispatch({
        type: MATERIAL_AUDIT,
        payload: audit.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const createMatrixMaterial = (
  data: any,
  callback,
  onCreatedMaterial = (material) => {},
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MATERIAL,
      });

      const response = await createMaterial(data);
      dispatch({
        type: MATERIAL_CREATE_SUCCESS,
      });

      if (typeof callback === 'function' && response.status === 400) {
        callback();
      }

      if (typeof callback === 'function' && response.status === 201) {
        onCreatedMaterial(response.data);
      }

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_createSuccess',
        },
      });

      const audit = await getAudit(response.data._id);
      dispatch({
        type: MATERIAL_AUDIT,
        payload: audit.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterial = (id: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MATERIAL,
      });

      await editMaterial(id, data);
      dispatch({
        type: MATERIAL_UPDATE_SUCCESS,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_updateSuccess',
        },
      });

      const audit = await getAudit(id);

      dispatch({
        type: MATERIAL_AUDIT,
        payload: audit.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const selectMaterialToEdit = (data: any) => {
  return {
    type: MATERIAL_SINGLE_SUCCESS,
    payload: data,
  };
};

export const clearMaterial = () => {
  return {
    type: MATERIAL_CLEAR,
  };
};
