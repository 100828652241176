export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const embedPostLinkRegex = /https.*?(?=[?"])/;
export const passwordRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\!“#\\$%&‘\\(\\)\\*\\+,\\-\\.\\/\\:;\\<\\=\\>\\?@\\[\\\\\\]\\^_`\\{\\|\\}~])[A-Za-z\\d\\!“#\\$%&‘\\(\\)\\*\\+,\\-\\.\\/\\:;\\<\\=\\>\\?@\\[\\\\\\]\\^_`\\{\\|\\}~]{10,}$',
);

export const alphabeticOnly = /^([A-Za-z])+$/;

// Custom alphabetic without num or Symbols(except - or ')
export const alphabeticCustom = /^([A-Za-z-'--])+$/;

// emailRegex without symbols
export const emailRegexWithoutSymbols = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// Don't allow only spaces and special characters
export const dontAllowOnlySpacesandSpecialSymbols = /^[a-zA-Z]+[\/\- ]?[a-zA-Z]+/;
