import {
  PROJECT_PLAN_SELECT_RESULTS,
  PROJECT_PLAN_START,
  PROJECT_PLAN_START_ERROR,
  PROJECT_PLAN_START_SUCCESS,
} from './ProjectPlanOverviewTypes';

const INITIAL_STATE = {
  loading: true,
  error: false,
  data: [],
  selectedResults: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECT_PLAN_START:
      return { ...state, loading: true, error: null };

    case PROJECT_PLAN_START_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case PROJECT_PLAN_START_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROJECT_PLAN_SELECT_RESULTS:
      let selectedResults: any = [];
      for (let [indexKey, value] of Object.entries(action.payload)) {
        if (value) {
          if (state.data.docs[indexKey]._id) {
            selectedResults.push(state.data.docs[indexKey]._id);
          }
        }
      }
      return {
        ...state,
        selectedResults,
      };

    default:
      return state;
  }
};
