import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReactSelect from 'react-select';
import { Search } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { getFieldFromType } from '../../../components/Forms/FieldTypes';
import { get as getPath } from 'lodash';
import './QueryBuilder.scss';
interface IField {
  name: string;
  label: string;
  type: string;
  options?: { label: string; value: string }[];
}

const operationOptions = {
  $eq: {
    label: 'datasearch:queryBuilder_opEq',
    value: '$eq',
  },
  $ne: {
    label: 'datasearch:queryBuilder_opNotEq',
    value: '$ne',
  },
  $range: {
    label: 'datasearch:queryBuilder_opRange',
    value: '$range',
  },
  $gte: {
    label: 'datasearch:queryBuilder_opGte',
    value: '$gte',
  },
  $lte: {
    label: 'datasearch:queryBuilder_opLte',
    value: '$lte',
  },
  $contains: {
    label: 'datasearch:queryBuilder_opContains',
    value: '$contains',
  },
  $eon: {
    label: 'datasearch:queryBuilder_opEon',
    value: '$eon',
  },
  $regex: {
    label: 'datasearch:queryBuilder_opRegex',
    value: '$regex',
  },
};
export const QueryField = ({ fields, addedField, index, form, t }) => {
  Object.keys(operationOptions).forEach((key) => {
    operationOptions[key].label = t(operationOptions[key].label);
  });
  const [selectedField, setSelectedField] = useState(
    fields.find((field) => field.fieldName === addedField.value),
  );
  return (
    getFieldFromType(
      form,
      {
        ...selectedField,
        span: 12,
        required: false,
        label: `${t(selectedField.label)} (${t(
          operationOptions[addedField.operator].label,
        )})` as string,
        disabled: operationOptions[addedField.operator].value === '$eon',
        type:
          addedField.operator === '$range'
            ? `range-${selectedField.type}`
            : selectedField.type,
      },
      index,
      {},
      t,
      true,
      false,
    ) || <></>
  );
};

interface IQueryBuilder {
  fields: any[];
  submitMethod: Function;
  searchButtonLabel?: any;
  query?: object;
  isDataSearchLoading?: boolean;
  t?: any;
}

export const QueryBuilder = ({
  fields,
  submitMethod,
  searchButtonLabel,
  query,
  isDataSearchLoading,
  t,
}: IQueryBuilder) => {
  const [addedFields, setAddedFields] = useState([] as any);
  const [showNewFieldModal, setShowNewFieldModal] = useState(false);
  const form = useForm();

  useEffect(() => {
    if (query) {
      const existingFields = [] as any;
      const queryKeys = Object.keys(query);
      queryKeys.forEach((key) => {
        const field = fields.find((field) => field.fieldName === key);
        if (!!field?.label) {
          existingFields.push({
            ...field,
            label: field.label,
            operator: query[key].operator,
            value: field.fieldName,
          });
          form.setValue(field.fieldName, query[key].value);
        }
      });
      setAddedFields([...existingFields]);
    }
  }, [query]);

  const addNewField = (field, operator) => {
    setAddedFields([...addedFields, { ...field, operator }]);
    setShowNewFieldModal(false);
  };
  const removeField = (fieldName) => {
    setAddedFields(addedFields.filter((field) => field.value !== fieldName));
    form.unregister(fieldName);
  };
  return (
    <form
      onSubmit={form.handleSubmit((data) => {
        const payloadToSubmit = {};
        for (let field of addedFields) {
          payloadToSubmit[field.value] = {
            value:
              getPath(data, field.value)?.value || getPath(data, field.value),
            operator: field.operator,
            ...(!!field.type && { type: field.type }),
          };
        }
        submitMethod(payloadToSubmit);
      })}
    >
      <Grid container className={'query-builder'}>
        <Grid container item spacing={4}>
          {addedFields.map((field, index) => (
            <Grid
              item
              container
              xs={4}
              className={'search-field-holder'}
              key={`query-field-${field.label}`}
            >
              <QueryField
                fields={fields}
                addedField={field}
                index={index}
                form={form}
                t={t}
              />
              <IconButton
                color={'primary'}
                className="remove-search-field"
                onClick={() => removeField(field.value)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          ))}
          <Grid item xs={2}>
            <Button
              aria-label="add-field"
              color={'primary'}
              disabled={showNewFieldModal}
              onClick={() => setShowNewFieldModal(true)}
            >
              <AddIcon /> {t('datasearch:queryBuilder_newField')}
            </Button>
            {showNewFieldModal && (
              <QueryBuilderFieldSelect
                fields={fields}
                addedFields={addedFields}
                addNewField={addNewField}
                hideQueryBuildField={() => setShowNewFieldModal(false)}
                t={t}
              />
            )}
          </Grid>
          <Grid container item xs={12} justify={'flex-end'}>
            {!isDataSearchLoading && (
              <Button color={'primary'} variant={'contained'} type={'submit'}>
                {searchButtonLabel ? (
                  searchButtonLabel
                ) : (
                  <>
                    <Search /> {t('datasearch:queryBuilder_search')}
                  </>
                )}
              </Button>
            )}
            {isDataSearchLoading && (
              <Button color="primary" variant="contained" disabled={true}>
                Searching...
                <CircularProgress size={15} />
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export const QueryBuilderFieldSelect = ({
  addNewField,
  hideQueryBuildField,
  fields,
  addedFields,
  t,
}) => {
  const [fieldType, setFieldType] = useState({
    label: t('datasearch:queryBuilder_None'),
    value: '',
  } as { label: string; value: string } | null);
  const [selectedField, setSelectedField] = useState({
    label: t('datasearch:queryBuilder_None'),
    value: '',
  } as { label: string; value: string } | null);

  const [selectedOperation, setSelectedOperation] = useState({
    label: t('datasearch:queryBuilder_None'),
    value: '',
  } as { label: string; value: string } | null);
  const calculateOperations = (operations) => {
    const calculatedOperations = [] as any;
    Object.keys(operationOptions).forEach(
      (operation) =>
        (operationOptions[operation].label = t(
          operationOptions[operation].label,
        )),
    );
    operations.forEach((operation) => {
      if (operationOptions[operation]) {
        calculatedOperations.push(operationOptions[operation]);
      }
    });
    if (!calculatedOperations.length) {
      calculatedOperations.push(operationOptions['$contains']);
      calculatedOperations.push(operationOptions['$eon']);
    }
    return calculatedOperations;
  };
  const addedFieldsValues = addedFields?.map((field) => field.value);
  const fieldOptions = fieldType?.value
    ? fields
        .filter((field) => field.fieldName.includes(fieldType.value))
        .filter((field) => !addedFieldsValues.includes(field.fieldName))
        .map((field) => ({ label: t(field.label), value: field.fieldName }))
    : [];

  const operatorOptions = selectedField?.value
    ? calculateOperations(
        fields.find((field) => field.fieldName === selectedField?.value)
          ?.operations || [],
      )
    : [
        {
          label: t('datasearch:queryBuilder_opContains'),
          value: '$contains',
        },
      ];

  return (
    <Grid className={'field-selector-popup'} spacing={4} xs={5}>
      <Grid className={'field-selector-field'}>
        <label id="field-type-label" htmlFor="field-type">
          {t('datasearch:queryBuilder_selectFieldType')}
        </label>
        <ReactSelect
          aria-labelledby={'field-type-label'}
          id={'field-type'}
          options={[
            {
              label: t('datasearch:queryBuilder_None'),
              value: '',
            },
            {
              label: t('datasearch:queryBuilder_project'),
              value: 'project',
            },
            {
              label: t('datasearch:queryBuilder_test'),
              value: 'test',
            },
            {
              label: t('datasearch:queryBuilder_results'),
              value: 'results',
            },
            {
              label: t('datasearch:queryBuilder_metadata'),
              value: 'metadata',
            },
          ]}
          defaultValue={fieldType}
          onChange={(value) => {
            setFieldType(value);
            setSelectedField({
              label: t('datasearch:queryBuilder_None'),
              value: '',
            });
            setSelectedOperation({
              label: t('datasearch:queryBuilder_None'),
              value: '',
            });
          }}
        />
      </Grid>
      <Grid className={'field-selector-field'}>
        <label id="field-label" htmlFor="field-name">
          {t('datasearch:queryBuilder_selectField')}
        </label>
        <ReactSelect
          aria-labelledby={'field-label'}
          id={'field-name'}
          options={[
            {
              label: t('datasearch:queryBuilder_None'),
              value: '',
            },
            ...fieldOptions,
          ]}
          key={selectedField?.value}
          defaultValue={selectedField}
          onChange={(value) => {
            setSelectedField(value);
          }}
        />
      </Grid>
      <Grid className={'field-selector-field'}>
        <label id="field-operator" htmlFor="field-operator">
          {t('datasearch:queryBuilder_selectOperator')}
        </label>
        <ReactSelect
          aria-labelledby={'field-label'}
          id={'field-name'}
          options={[...operatorOptions]}
          key={selectedOperation?.value}
          defaultValue={selectedOperation}
          onChange={(value) => {
            setSelectedOperation(value);
          }}
        />
      </Grid>
      <Grid container justify={'space-between'}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() =>
            addNewField(selectedField, selectedOperation?.value || 'contains')
          }
          disabled={
            !fieldType?.value ||
            !selectedField?.value ||
            !selectedOperation?.value
          }
        >
          {t('datasearch:queryBuilder_addField')}
        </Button>
        <Button onClick={hideQueryBuildField} color={'primary'}>
          {t('datasearch:queryBuilder_close')}
        </Button>
      </Grid>
    </Grid>
  );
};
