import React from 'react';
import Warning from '@material-ui/icons/Warning';

import './InputWarning.scss';

type InputErrorProps = {
  message?: string;
};

export const InputWarning: React.FC<InputErrorProps> = ({ message }) => (
  <span className="input-warning">
    <Warning />
    <span>{message}</span>
  </span>
);
