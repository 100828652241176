import React, { useEffect, useState } from 'react';
import {
  Box,
  TableBody,
  TableContainer,
  TableRow as TableRowM,
  Table as TableM,
  TableCell,
} from '@material-ui/core';
import { BoxWrap } from '..';
import { TableRow } from './components/TableRow/TableRow';
import { TablePagination } from './components/TablePagination/TablePagination';
import { TableHeader } from './components/TableHeader/TableHeader';
import { SelectDelete } from '../SelectDelete/SelectDelete';

import './Table.scss';
import { useTranslation } from 'react-i18next';

export const Table = (props) => {
  let {
    columnNames,
    title = '',
    processData = (data) => data,
    dataPassed = {},
    filtersPassed = {
      page: 0,
      limit: 4,
      filter: '',
    },
    fetchDataMethod,
    handleDeleteClick,
    searchEnabled = true,
    tablePagination = true,
    useLocalData = false,
    type = 'default',
  } = props;
  const { t } = useTranslation('common');
  const [checkedItems, setCheckedItems] = useState([] as string[]);
  const [filters, setFilters] = useState({ filter: '', ...filtersPassed });
  const [data, setData] = useState(dataPassed);
  const [rows, setRows] = useState(data.docs);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, event.target.value]);
    } else {
      setCheckedItems(checkedItems.filter((id) => id !== event.target.value));
    }
  };

  async function getData() {
    try {
      // Check if data is provided flag
      if (!useLocalData) {
        const response = await fetchDataMethod(filters);
        setData(response.data);
        setRows(processData([...data.docs]));
      } else {
        setData(dataPassed);
        setRows(processData([...dataPassed.docs]));
      }
      setCheckedItems([]);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setFilters(filtersPassed);
    getData();
  }, [filtersPassed]);

  useEffect(() => {
    if (useLocalData) {
      setFilters(filters);
    } else {
      getData();
    }
  }, [filters]);

  useEffect(() => {
    if (data.docs) {
      setRows(processData([...data.docs]));
    }
  }, [data]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedItems(data.docs.map((row) => row._id));
    } else {
      setCheckedItems([]);
    }
  };

  return (
    <Box style={{ width: '100%' }} className="labv-table">
      {searchEnabled && (
        <BoxWrap.Toolbar>
          <SelectDelete
            text={title}
            deleteItems={handleDeleteClick}
            selecting={checkedItems.length > 0}
            selectedCount={checkedItems.length}
            filters={filters}
            submitFilters={setFilters}
            checkedItems={checkedItems}
            t={t}
          />
        </BoxWrap.Toolbar>
      )}
      <TableContainer className="table-body">
        <TableM aria-label="custom pagination table">
          <TableHeader
            columnNames={columnNames}
            rows={data.docs}
            checkedItems={checkedItems}
            handleCheckAll={handleCheckAll}
            setFilters={setFilters}
            filters={filters}
          />
          <TableBody>
            {(rows || [])?.map((row, index) => {
              return (
                <TableRow
                  columns={columnNames}
                  checkedItems={checkedItems}
                  row={row}
                  handleCheck={handleCheck}
                  key={index + '-row'}
                  pageToRedirect={row.pageToRedirectTo}
                  type={type}
                />
              );
            })}

            {!data.docs?.length ? (
              <TableRowM>
                <TableCell colSpan={columnNames.length}>
                  <div className="no-results">
                    <h4>{t('table_noResultsFound')}</h4>
                  </div>
                </TableCell>
              </TableRowM>
            ) : null}
          </TableBody>
        </TableM>
      </TableContainer>

      {tablePagination && (
        <TablePagination
          setFilters={setFilters}
          filters={filters}
          totalPages={data.totalPages}
          nextPage={data.nextPage}
          prevPage={data.prevPage}
          t={t}
        />
      )}
    </Box>
  );
};
