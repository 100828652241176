import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Loading.scss';

export const Loading = () => (
  <div className="loading-wrapper">
    <div className="loading-bar">
      <CircularProgress />
    </div>
  </div>
);
