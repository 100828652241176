import { getPermissions } from './permissions';

export const isAbleTo = (namespace: string, action: string) => {
  const permissions = getPermissions();

  if (!permissions) {
    return false;
  }
  return permissions[namespace] && permissions[namespace][action];
};
