import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import ProfileView from './ProfileView';
import {
  clearProfile,
  fetchProfile,
  updateProfileAction,
} from './components/ProfileActions';
import { profileDetailsFields } from './components/ProfileDetailsFields';
import { ERROR } from '../_Default/Notification/NotificationTypes';

import './Profile.scss';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const [fields, setFields] = useState(profileDetailsFields) as any;
  const [file, setFile] = useState<any>({
    image: profile?.data?.imagePath || null,
    previewImage: null,
    changed: false,
  });

  const localFormInstance = useForm({
    shouldUnregister: false,
    reValidateMode: 'onBlur',
  });

  const formInstance = localFormInstance;

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const handleConfirm = async (formTouched = false) => {
    if (formTouched) {
      setFile({
        image: profile?.data?.imagePath || null,
        previewImage: null,
        changed: false,
      });
      await dispatch(fetchProfile());
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0] && e.target.files[0]?.type?.startsWith('image/')) {
      setFile({
        image: e.target.files[0],
        previewImage: URL.createObjectURL(e.target.files[0]),
        changed: true,
      });
    } else {
      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message: 'profile_invalidFileType',
        },
      });
    }
  };

  const submitFormActions = async (editProfileData) => {
    const newEditProfileData = {
      ...editProfileData,
      notificationPreferences: {
        frequency: editProfileData.notificationPreferences,
      },
      imagePath: file.image,
    };

    const equalObjectProperties =
      Object.entries(newEditProfileData).toString() !==
        Object.entries(profile.data).toString() ||
      newEditProfileData.notificationPreferences.frequency !==
        profile.data.notificationPreferences.frequency;

    if (equalObjectProperties) {
      if (profile.data.email !== newEditProfileData?.email) {
        await dispatch(
          updateProfileAction(newEditProfileData, true, file.image),
        );
      }

      await dispatch(
        updateProfileAction(newEditProfileData, false, file.image),
      );

      setFile({
        ...file,
        changed: false,
      });

      dispatch(fetchProfile());
    }
  };

  if (profile.loading) {
    return null;
  }

  return (
    <div className="page-company">
      <ProfileView
        form={formInstance}
        fields={fields}
        formData={profile}
        file={file || profile.data.imagePath}
        handleConfirm={handleConfirm}
        submitFormActions={submitFormActions}
        handleImageChange={handleImageChange}
        imagePath={profile.data.imagePath}
        t={t}
      />
    </div>
  );
};
export default Profile;
