import React from 'react';
import { Arrow } from 'components';

import './Sorter.scss';
import { Grid, Typography } from '@material-ui/core';

type SorterProps = {
  label?: string;
  handleSort: Function;
};

export const Sorter: React.FC<SorterProps> = ({ label, handleSort }) => (
  <Grid container alignItems="center" justify="flex-start" alignContent="flex-start">
    <Grid item xs={12} id='table-header'>
      <Typography variant="body2">
        {label}
      <span className="cell-sorter">
        <span onClick={() => handleSort('-')}>
          <Arrow color="#000" direction="up" />
        </span>
        <span onClick={() => handleSort('')}>
          <Arrow color="#000" direction="down" />
        </span>
      </span>
      </Typography>
    </Grid>
  </Grid>
);
