import { DEVICE_START, DEVICE_SUCCESS, DEVICE_ERROR } from './DeviceTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DEVICE_START:
      return { ...state, loading: true, error: null };

    case DEVICE_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case DEVICE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
