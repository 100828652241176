import {
  RESULT_START,
  RESULT_SUCCESS,
  RESULT_ERROR,
  RESULT_CLEAR,
  RESULT_ADD_TO_EXPORT,
  RESULT_REMOVE_FROM_EXPORT,
  RESULT_CHART_SUCCESS,
  RESULT_SEARCH_CONFIGURATION_SUCCESS,
} from './TestResultsTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  results: [],
  resultsToExport: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESULT_START:
      return { ...state, loading: true, error: null };

    case RESULT_SUCCESS:
      return {
        ...state,
        results: (action.payload || []).map((result) => {
          return {
            ...result,
            files: (result?.files || []).filter((file) => file.confirmed),
          };
        }),
        loading: false,
      };

    case RESULT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case RESULT_CLEAR:
      return { ...INITIAL_STATE };

    case RESULT_ADD_TO_EXPORT:
      return { ...state, resultsToExport: action.payload, loading: false };

    case RESULT_REMOVE_FROM_EXPORT:
      return { ...state, resultsToExport: action.payload, loading: false };

    case RESULT_CHART_SUCCESS:
      return {
        ...state,
        chartUrl: action.payload,
        loading: false,
      };
    case RESULT_SEARCH_CONFIGURATION_SUCCESS:
      return {
        ...state,
        searchConfiguration: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
