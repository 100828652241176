import {
  TEMPLATE_EDIT_START,
  TEMPLATE_EDIT_SUCCESS,
  TEMPLATE_EDIT_ERROR,
} from './TemplateFormTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  template: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TEMPLATE_EDIT_START:
      return { ...state, loading: true, error: null };

    case TEMPLATE_EDIT_SUCCESS:
      return { ...state, template: action.payload, loading: false };

    case TEMPLATE_EDIT_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
