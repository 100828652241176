import {
  GET_LOCALES,
  GET_BOARDS,
  GET_USER,
  GET_USER_ERROR,
  USER_CLEAR,
  USER_SINGLE_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_AUDIT,
  USER_EDIT,
} from './UserFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  data: {
    title: '',
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    position: '',
    phone: '',
    isActive: '',
    companyId: '',
    projects: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_AUDIT:
      return { ...state, audit: action.payload };

    case GET_BOARDS:
      return { ...state, boards: action.payload };

    case GET_LOCALES:
      return { ...state, locales: action.payload };

    case GET_USER:
      return { ...state, loading: true };

    case USER_EDIT:
      return { ...state, loading: false, data: action.payload };

    case USER_SINGLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case USER_UPDATE_SUCCESS:
      return { ...state, loading: false };

    case USER_CREATE_SUCCESS:
      return { ...state, loading: false };

    case GET_USER_ERROR:
      return { ...state, loading: false, data: null, error: action.payload };

    case USER_CLEAR:
      return {
        ...state,
        data: { ...INITIAL_STATE.data },
        error: null,
        loading: false,
      };

    default:
      return state;
  }
};
