import React, { useEffect } from 'react';
import { CheckCircleOutlined } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { loginADUser } from '../../LoginActions';

export const ActiveDirectorySuccess = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loginADUser(match.params.token));
  }, [match?.params?.token]);
  return (
    <Grid
      item
      container
      direction={'column'}
      justify={'center'}
      alignItems={'center'}
      style={{ marginTop: '300px' }}
    >
      Authentication successful. Logging you in...
      <Grid>
        <CheckCircleOutlined color={'secondary'} fontSize={'large'} />
      </Grid>
    </Grid>
  );
};
