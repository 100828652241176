import {
  GET_TEST_STANDARD,
  GET_TEST_STANDARD_ERROR,
  TEST_STANDARD_FORM_CLEAR,
  TEST_STANDARD_SINGLE_SUCCESS,
} from './TestStandardFormTypes';
import { LOCATION_UPDATE_SUCCESS } from '../LocationForm/LocationFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  data: {
    name: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TEST_STANDARD:
      return { ...state, loading: true };

    case TEST_STANDARD_SINGLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case LOCATION_UPDATE_SUCCESS:
      return { ...state, loading: false };

    case GET_TEST_STANDARD_ERROR:
      return { ...state, loading: false, data: null, error: action.payload };

    case TEST_STANDARD_FORM_CLEAR:
      return { ...state, loading: false, data: null, error: null };

    default:
      return state;
  }
};
