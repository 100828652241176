export const saveUserToken = (token) => {
  localStorage.setItem('token', token);
};

export const logoutUser = () => {
  localStorage.removeItem('token');
  document.title = 'LabV';
};

export const isAuth = () => {
  return localStorage.getItem('token');
};
