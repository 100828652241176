import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nHttpLoader from 'i18next-http-backend';
import ChainedBackend from 'i18next-chained-backend';
import I18NextHttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

const account = JSON.parse(localStorage.getItem('account') || '{}');

const bundledResources = {
  en: {
    //language for which resources are being provided
    notFound: {
      notFound: 'Not found',
      getBack: 'Get back to',
    },
    login: {
      //namespace for which resources are being provided
      login: 'Login',
      signInMicrosoft: 'Sign in with Microsoft',
      email: 'Email Address',
      emailError: 'Valid email is required',
      password: 'Password',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
      passwordError: 'Valid password is required',
      passwordRulesError:
        'Password should contain small & large letters, numbers and at least one symbol',
      passwordMismatch: 'Passwords are not the same',
      termsAndConditions: 'Accept Terms and conditions',
      loginButton: 'Login',
      forgotPassword: 'Forgot password',
      resetPassword: 'Reset password',
      passwordReset: 'Password has been reset!',
      checkEmailMessage: 'Please check your email',
      newPasswordMessage:
        'You can now use your new password to log in to your account',
      initMessage:
        'Please make sure you enter valid email address. This action is available only once.',
      initTitle: 'Create admin',
      createAdmin: 'Create admin',
      capsLockActive: 'Caps Lock is on',
      backToLogin: 'Back to Login',
      savePassword: 'Save',
      setPassword: 'Set password',
      passwordRules:
        'Your password must contain a lowercase letter, an upppercase letter, a number, a special character, and be at least 10 characters long.',
    },
  },
  de: {
    notFound: {
      notFound: 'Seite nicht gefunden',
      getBack: 'Zurück',
    },
    login: {
      login: 'Login',
      signInMicrosoft: 'Mit Microsoft anmelden',
      email: 'E-Mail-Adresse',
      emailError: 'Eine gültige E-Mail-Adresse ist erforderlich',
      password: 'Passwort',
      newPassword: 'Neues Passwort',
      repeatPassword: 'Passwort wiederholen',
      passwordError: 'Ein gültiges Passwort ist erforderlich',
      passwordRulesError:
        'Das Passwort sollte Klein- und Großbuchstaben, Ziffern und zumindest ein Symbol enthalten',
      passwordMismatch: 'Die Passwörter stimmen nicht überein',
      termsAndConditions: 'Bitte akzeptieren Sie die Nutzungsbedingungen',
      loginButton: 'Login',
      forgotPassword: 'Passwort vergessen',
      resetPassword: 'Passwort zurücksetzen',
      passwordReset: 'Das Passwort wurde zurückgesetzt!',
      checkEmailMessage: 'Bitte überprüfen Sie Ihre E-Mails',
      newPasswordMessage:
        'Sie können nun Ihr neues Passwort für den Login verwenden',
      initMessage:
        'Bitte stellen Sie sicher, dass Sie eine gültige E-Mail-Adresse eingeben. Diese Aktion ist nur einmal möglich.',
      initTitle: 'Admin erstellen',
      createAdmin: 'Admin erstellen',
      capsLockActive: 'Feststelltaste ist aktiv',
      backToLogin: 'Zurück zum Login',
      savePassword: 'Speichern',
      setPassword: 'Passwort festlegen',
      passwordRules:
        'Das Passwort muss mindestens 10 Zeichen lang sein und einen Kleinbuchstaben, einen Großbuchstaben, eine Ziffer sowie ein Sonderzeichen enthalten.',
    },
  },
};

const backendConfig = {
  interpolation: {
    escapeValue: false,
  },
  lng: account.language || window.navigator.language.slice(0, 2),
  fallbackLng: 'de',
  react: {
    useSuspense: false,
  },
  ns: ['project'],
  defaultNS: 'login',
  backend: {
    backends: [I18NextHttpBackend, resourcesToBackend(bundledResources)],
    backendOptions: [
      {
        loadPath: `${process.env.REACT_APP_API_URL}/translation/{{lng}}/{{ns}}`,
        parse: (data) => {
          return JSON.parse(data);
        },
        customHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    ],
  },
};

i18n.use(initReactI18next).use(ChainedBackend).init(backendConfig);
export default i18n;
