import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PublicRoutes } from 'routes/PublicRoutes';
import { Notification } from 'containers';
import { NotFound } from 'containers';

export const PublicLayout = ({ path }) => {
  const routes = PublicRoutes.map((route, index) => {
    const routeProps = { ...route, path: path + route.path };
    return <Route exact {...routeProps} key={route.path + index} />;
  });

  return (
    <div className="public">
      <div className="content">
        <React.Suspense fallback="loading..">
          <Switch>
            <Redirect exact from="/" to="/login" />
            {routes}
            <Route component={NotFound} />
          </Switch>
        </React.Suspense>
      </div>
      <Notification />
    </div>
  );
};
