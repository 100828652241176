export const GET_COMPANY = 'company/company-form-start';
export const GET_COMPANY_SUCCESS = 'company/company-form-success';
export const GET_COMPANY_ERROR = 'company/company-form-error';
export const COMPANY_CLEAR = 'company/company-form-clear';
export const COMPANY_SINGLE_SUCCESS = 'company/company-single-success';
export const COMPANY_UPDATE_SUCCESS = 'company/company-update-success';
export const COMPANY_CREATE_SUCCESS = 'company/company-create-success';
export const COMPANY_AUDIT = 'company/audit';
export const COMPANY_EDIT = 'company/company-edit';
export const COMPANY_DEVICES_SUCCESS = 'company/company-devices-success';
