import React from 'react';
import { Typography } from '@material-ui/core';
import mailImg from './mail-img.png';
import './PasswordResetSuccess.scss';
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

export const PasswordResetSuccess = (props) => {
  const { t, i18n } = useTranslation('login');
  return (
    <>
      <Typography variant="h2" align="center">
        {t('passwordReset')}
      </Typography>
      <Typography variant="body2" align="center" className="pr-subtitle">
        {t('checkEmailMessage')}
      </Typography>
      <img
        alt="password-reset"
        src={mailImg}
        height="60px"
        width="70px"
        className={'password-reset-image'}
      ></img>
      <Typography variant="body2" align="center">
        {t('newPasswordMessage')}
      </Typography>
    </>
  );
};
