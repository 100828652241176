import { getConfig } from 'repository/configRegistry';
import { isAuth } from 'services/auth';

class Configuration {
  private instance: any;
  constructor() {
    if (!this.instance) {
      this.getConfigFromServer();
    }
    // Initialize object
    return this.instance;
  }
  // Properties & Methods

  private async getConfigFromServer() {
    const configData = await getConfig();
    this.instance = configData.data;
    this.setConfig('config', configData.data);
    localStorage.setItem('latestConfigSync', Date.now().toString());

    const interval = setInterval(async () => {
      if (!isAuth()) {
        clearInterval(interval);
        return;
      }
      let configData = await getConfig();
      localStorage.setItem('latestConfigSync', Date.now().toString());
      this.instance = configData.data;

      this.setConfig('config', configData.data);
    }, 5 * 60 * 1000);
  }
  getConfigFromLocalStorage(configName, defaultValue = false) {
    return localStorage.getItem(configName) || defaultValue;
  }
  setConfig(configName, data) {
    localStorage.setItem(configName, JSON.stringify(data));
  }

  getConfig() {
    return this.instance;
  }
}

export default Configuration;
