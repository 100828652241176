import React, { useEffect, useState } from 'react';
import { Avatar, Button, Grid } from '@material-ui/core';

import './ProfileImage.scss';
import { getSignedUrl } from 'repository/testResult';

const ProfileImage = ({ file, handleImageChange, imagePath, t }) => {
  const [signedImageUrl, setSignedImageUrl] = useState('');

  const getImageSignedUrl = async () => {
    if (!imagePath) {
      return;
    }

    try {
      const { data: signedUrl } = await getSignedUrl(
        `${process.env.REACT_APP_S3_BUCKET_UPLOADS}`,
        imagePath,
      );

      setSignedImageUrl(signedUrl);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getImageSignedUrl();
  }, [imagePath]);

  return (
    <Grid container lg={3} xs={3} className="avatar-wrapper" justify={'center'}>
      <Grid
        className="avatar-wrapper-content"
        item
        style={{ marginTop: '50px' }}
      >
        <Avatar
          src={file.previewImage || signedImageUrl || file.image}
          className="avatar"
          style={{ height: '150px', width: '150px' }}
        />
        <Button
          variant="contained"
          component="label"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        >
          {t('editPhoto')}
          <input
            accept="image/*"
            type="file"
            hidden
            onChange={handleImageChange}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileImage;
