import { MATERIAL_START, MATERIAL_SUCCESS } from './MaterialTypes';
import {
  ERROR,
  LOADING,
  CLEAR,
} from 'containers/_Default/Notification/NotificationTypes';
import { getMaterials } from 'repository/material';

export const fetchMaterials = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'material_loading',
        },
      });

      const response: any = await getMaterials(params);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: MATERIAL_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
