
import {
  GET_LOCALES,
  GET_BOARDS,
  GET_LOCATION,
  GET_LOCATION_ERROR,
  LOCATION_CLEAR,
  LOCATION_SINGLE_SUCCESS,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_CREATE_SUCCESS,
  LOCATION_AUDIT,
} from './LocationFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  data: {
    name: '',
    address1: '',
    address2: '',
    phone1: '',
    phone2: '',
    city: '',
    state: '',
    country: '',
    email: '',
    companyId: '',
  },

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATION_AUDIT:
      return { ...state, audit: action.payload };

    case GET_BOARDS:
      return { ...state, boards: action.payload };

    case GET_LOCALES:
      return { ...state, locales: action.payload };
      
    case GET_LOCATION:
      return { ...state, loading: true };

    case LOCATION_SINGLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    
    case LOCATION_UPDATE_SUCCESS:
      return { ...state, loading: false };
    
    case LOCATION_CREATE_SUCCESS:
      return { ...state, loading: false };

    case GET_LOCATION_ERROR:
      return { ...state, loading: false, data: null, error: action.payload };
    
    case LOCATION_CLEAR:
      return { ...state, loading: false, data: null, error: null };


    default:
      return state;
  }
};
