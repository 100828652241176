import React from 'react';
import {
  Checkbox,
  Grid,
  IconButton,
  TableCell,
  TableRow as TableRowM,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { get as getPath } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateRecentNotification } from '../../../RecentNotification/RecentNotificationsActions';
import { makeStyles } from '@material-ui/core/styles';

import './TableRow.scss';

export const TableRow = (props) => {
  const {
    columns = [],
    row = {},
    handleCheck,
    checkedItems = [],
    pageToRedirect,
    type,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const notificationCentreType = type === 'notification-centre';

  const useStyles = makeStyles({
    root: {
      width: '1%',
    },
  });

  const classes = useStyles();

  const handlePageRedirect = () => {
    if (notificationCentreType) {
      history.push(props?.pageToRedirect?.linkScrollToComment);
      dispatch(updateRecentNotification(props?.row?._id));
    }
  };

  return (
    <TableRowM
      hover={true}
      className={'table-row'}
      onClick={handlePageRedirect}
      style={pageToRedirect?.linkScrollToComment && { cursor: 'pointer' }}
    >
      {(columns || []).map((columnObject, index) => {
        if (columnObject.type === 'checkbox') {
          return (
            <TableCell scope="row" key={index}>
              <Checkbox
                onChange={(event) => handleCheck(event)}
                value={row._id}
                checked={checkedItems.includes(row._id)}
              />
            </TableCell>
          );
        }
        if (columnObject.type === 'icon') {
          return (
            <TableCell scope="row" key={index}>
              <IconButton
                aria-label="edit"
                color="secondary"
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => {
                  columnObject.callbackMethod(row);
                }}
              >
                {columnObject.icon}
              </IconButton>
            </TableCell>
          );
        }
        if (columnObject.type === 'icons') {
          return (
            <TableCell
              scope="row"
              key={index}
              align={'right'}
              className="sticky-column"
            >
              <Grid container justify="flex-end">
                {columnObject.icons.map((icon, index) => {
                  if (typeof icon.show === 'function') {
                    return icon.show(row) ? (
                      <Grid item xs={3}>
                        <IconButton
                          key={index}
                          aria-label="edit"
                          color="secondary"
                          size="small"
                          disabled={
                            typeof icon.checkDisabled === 'function'
                              ? icon.checkDisabled(row)
                              : false
                          }
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => {
                            icon.callbackMethod(row);
                          }}
                        >
                          <Tooltip title={icon.placeholder || ''} arrow>
                            {icon.icon}
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    ) : null;
                  } else {
                    return icon.show ? (
                      <Grid item xs={3}>
                        <IconButton
                          key={index}
                          aria-label="edit"
                          color="secondary"
                          size="small"
                          disabled={
                            typeof icon.checkDisabled === 'function'
                              ? icon.checkDisabled(row)
                              : false
                          }
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => {
                            icon.callbackMethod(row);
                          }}
                        >
                          <Tooltip title={icon.placeholder || ''} arrow>
                            {icon.icon}
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    ) : null;
                  }
                })}
              </Grid>
            </TableCell>
          );
        }
        return (
          <TableCell
            scope="row"
            key={index}
            className={pageToRedirect?.linkScrollToComment && classes.root}
          >
            <Typography
              variant={'body2'}
              style={{
                maxWidth: `500px`,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...(notificationCentreType &&
                  !pageToRedirect.read && { color: '#000' }),
              }}
            >
              {!!getPath(row, columnObject.field)
                ? getPath(row, columnObject.field)
                : ''}
            </Typography>
          </TableCell>
        );
      })}
    </TableRowM>
  );
};
