import {
  Company,
  CompanyForm,
  Device,
  DeviceForm,
  Group,
  GroupForm,
  Location,
  LocationForm,
  Project,
  ProjectOverview,
  Role,
  TestResults,
  User,
  UserForm,
  TemplateOverview,
  TemplateForm,
  ProjectPlanOverview,
  Material,
  TestStandardOverview,
  TestStandard,
  ProjectResultWrapper,
} from 'containers';
import * as RoleManager from 'services/role';
import DataSearch from '../containers/DataSearch/DataSearch';
import NotificationCentre from '../containers/NotificationCentre/NotificationCentre';
import Profile from '../containers/Profile/Profile';
import MaterialDetails from '../containers/MaterialDetails/MaterialDetails';

export const AdminRoutes = [
  {
    component: User,
    path: 'user',
    allowed:
      RoleManager.isAbleTo('user', 'findAll') ||
      RoleManager.isAbleTo('user', 'create') ||
      RoleManager.isAbleTo('user', 'updateOne') ||
      RoleManager.isAbleTo('user', 'deleteOne') ||
      RoleManager.isAbleTo('user', 'findOne'),
  },
  {
    component: UserForm,
    path: 'user/create',
    allowed: RoleManager.isAbleTo('user', 'create'),
  },
  {
    component: UserForm,
    path: 'user/:id',
    allowed:
      RoleManager.isAbleTo('user', 'updateAll') ||
      RoleManager.isAbleTo('user', 'updateOne'),
  },
  {
    component: UserForm,
    path: 'profile/:id',
    allowed: RoleManager.isAbleTo('user', 'updateOne'),
  },
  {
    component: Role,
    path: 'role',
    allowed:
      RoleManager.isAbleTo('role', 'findAll') ||
      RoleManager.isAbleTo('role', 'findOne'),
  },

  // route injection line - do not remove or edit
  {
    component: Device,
    path: 'device',
    allowed:
      RoleManager.isAbleTo('device', 'findAll') ||
      RoleManager.isAbleTo('device', 'findOne'),
  },
  {
    component: DeviceForm,
    path: 'device/create',
    allowed: RoleManager.isAbleTo('device', 'create'),
  },
  {
    component: DeviceForm,
    path: 'device/:id',
    allowed:
      RoleManager.isAbleTo('device', 'updateAll') ||
      RoleManager.isAbleTo('device', 'updateSelf'),
  },
  {
    component: Project,
    path: 'project/create',
    allowed: RoleManager.isAbleTo('project', 'create'),
  },
  {
    component: ProjectOverview,
    path: 'project',
    allowed:
      RoleManager.isAbleTo('project', 'findAll') ||
      RoleManager.isAbleTo('project', 'findOne'),
  },
  {
    component: TestResults,
    path: 'project/:id/data',
    allowed: true,
  },
  {
    component: ProjectResultWrapper,
    path: 'project/:id/results',
    allowed: true,
  },
  {
    component: Project,
    path: 'project/:id/edit',
    allowed: true,
  },
  {
    component: Project,
    path: 'project/:id',
    allowed: true,
  },
  {
    component: Group,
    path: 'group',
    allowed:
      RoleManager.isAbleTo('group', 'editMembers') ||
      RoleManager.isAbleTo('group', 'findAll') ||
      RoleManager.isAbleTo('group', 'findOne'),
  },
  {
    component: GroupForm,
    path: 'group/create',
    allowed: RoleManager.isAbleTo('group', 'create'),
  },
  {
    component: GroupForm,
    path: 'group/:id',
    allowed:
      RoleManager.isAbleTo('group', 'updateAll') ||
      RoleManager.isAbleTo('group', 'updateSelf'),
  },
  {
    component: Location,
    path: 'location',
    allowed:
      RoleManager.isAbleTo('location', 'findAll') ||
      RoleManager.isAbleTo('location', 'findOne'),
  },
  {
    component: LocationForm,
    path: 'location/create',
    allowed: RoleManager.isAbleTo('location', 'create'),
  },
  {
    component: LocationForm,
    path: 'location/:id',
    allowed:
      RoleManager.isAbleTo('location', 'updateAll') ||
      RoleManager.isAbleTo('location', 'updateSelf'),
  },
  {
    component: User,
    path: 'user',
    allowed:
      RoleManager.isAbleTo('user', 'findAll') ||
      RoleManager.isAbleTo('user', 'findOne'),
  },
  {
    component: UserForm,
    path: 'user/create',
    allowed: RoleManager.isAbleTo('user', 'create'),
  },
  {
    component: UserForm,
    path: 'user/:id',
    allowed:
      RoleManager.isAbleTo('user', 'updateAll') ||
      RoleManager.isAbleTo('user', 'updateSelf'),
  },
  {
    component: Company,
    path: 'company',
    allowed:
      RoleManager.isAbleTo('company', 'findAll') ||
      RoleManager.isAbleTo('company', 'findOne'),
  },
  {
    component: CompanyForm,
    path: 'company/create',
    allowed: RoleManager.isAbleTo('company', 'create'),
  },
  {
    component: CompanyForm,
    path: 'company/:id',
    allowed:
      RoleManager.isAbleTo('company', 'updateAll') ||
      RoleManager.isAbleTo('company', 'updateSelf'),
  },
  {
    component: TemplateOverview,
    path: 'template',
    allowed: RoleManager.isAbleTo('project', 'create'),
  },
  {
    component: ProjectPlanOverview,
    path: 'overview-project-plans',
    allowed: RoleManager.isAbleTo('project', 'create'),
  },
  {
    component: TemplateForm,
    path: 'template/:id',
    allowed: RoleManager.isAbleTo('project', 'create'),
  },
  {
    component: DataSearch,
    path: 'data-search',
    allowed: true,
  },
  {
    component: Profile,
    path: 'profile',
    allowed: true,
  },
  {
    component: NotificationCentre,
    path: 'notification-centre',
    allowed: true,
  },
  {
    component: Material,
    path: 'material',
    allowed:
      RoleManager.isAbleTo('material', 'findAll') ||
      RoleManager.isAbleTo('material', 'findOne'),
  },
  {
    component: MaterialDetails,
    path: 'material/:id',
    allowed:
      RoleManager.isAbleTo('material', 'findAll') ||
      RoleManager.isAbleTo('material', 'findOne'),
  },
  {
    component: TestStandardOverview,
    path: 'test-standard',
    allowed:
      RoleManager.isAbleTo('project', 'findAll') ||
      RoleManager.isAbleTo('project', 'findOne'),
  },
  {
    component: TestStandard,
    path: 'test-standard/:id',
    allowed:
      RoleManager.isAbleTo('project', 'findAll') ||
      RoleManager.isAbleTo('project', 'findOne'),
  },
];
