import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import { getFieldFromType } from '../../../../components/Forms/FieldTypes';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import { filterNumericColumns } from '../../../../utils/transform-helpers';

export enum MATHEMATICAL_OPERATIONS {
  SUBTRACT,
  ADD,
  MULTIPLY,
  DIVIDE,
}

export const AddTableColumn = ({ columns, submitData, t }) => {
  const [openAddColumnPopper, setOpenAddColumnPopper] = useState(false);
  const anchorRef: any = useRef();
  const dataSearch = useSelector((state: any) => state.dataSearch);

  const form = useForm();
  const { handleSubmit } = form;
  const operation = form.watch('operation');

  useEffect(() => {
    form.register('operant2', {
      validate: (data) => {
        if (
          parseFloat(data) === 0 &&
          operation === MATHEMATICAL_OPERATIONS.DIVIDE
        ) {
          form.setError('operant2', {
            type: 'custom',
            message: t('divideZero'),
          });
          return false;
        }
        return true;
      },
    });
  }, [operation, form.watch('operant2numeric')]);

  useEffect(() => {
    form.register('operant1', {
      validate: (data) => {
        if (!data) {
          form.setError('operant1', {
            type: 'custom',
            message: t('operant1_error'),
          });
          return false;
        }
        return true;
      },
    });
  }, [form.watch('operant1numeric')]);

  const handleAddColumnOpen = () => {
    setOpenAddColumnPopper(true);
  };
  return (
    <form
      onSubmit={handleSubmit((data) => {
        submitData(data);
        form.reset();
        setOpenAddColumnPopper(false);
      })}
    >
      <Grid
        item
        container
        direction={'row'}
        justify={'flex-end'}
        style={{ position: 'relative' }}
      >
        <Button
          onClick={handleAddColumnOpen}
          color="primary"
          variant="contained"
          style={{ marginRight: 10 }}
        >
          <SvgIcon component={Add} />
          {t('addColumn')}
        </Button>
        {openAddColumnPopper && (
          <Popper
            open={openAddColumnPopper}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            className="user-dropdown statistical-dropdown"
            placement="bottom-end"
            style={{
              position: 'absolute',
              top: '40px',
              left: '-200px',
              minWidth: '400px',
              zIndex: 1,
              padding: '14px',
            }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <Grid container justify={'flex-end'}>
                    <IconButton
                      onClick={() => {
                        form.reset();
                        setOpenAddColumnPopper(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>

                  <MenuList>
                    <MenuItem>
                      <Grid
                        item
                        container
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Grid
                          item
                          container
                          direction={'row'}
                          alignItems={'center'}
                          xs={6}
                          style={{ marginTop: 7, marginBottom: 4 }}
                        >
                          <Typography>{t('operant#1')}</Typography>

                          {getFieldFromType(
                            form,
                            {
                              fieldName: 'operant1numeric',
                              label: t('operant1'),
                              type: 'switch',
                            } as any,
                            0,
                            {},
                            t,
                            true,
                          )}
                        </Grid>
                        <Grid item container direction={'row'} xs={6}>
                          {!!form.watch('operant1numeric')
                            ? getFieldFromType(
                                form,
                                {
                                  fieldName: 'operant1',
                                  label: 'operant1',
                                  type: 'text',
                                  subType: 'Float',
                                  multiline: false,
                                  required: true,
                                  span: 12,
                                  maxLength: 255,
                                  error: 'operant1_error',
                                } as any,
                                0,
                                {},
                                t,
                                true,
                              )
                            : getFieldFromType(
                                form,
                                {
                                  fieldName: 'operant1',
                                  label: 'operant1',
                                  type: 'select',
                                  options: filterNumericColumns(
                                    columns,
                                    dataSearch.hiddenColumns,
                                    true,
                                  ),
                                  required: true,
                                  span: 12,
                                  maxLength: 255,
                                  error: 'operant1_error',
                                } as any,
                                0,
                                {},
                                t,
                                true,
                              )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem>
                      <Grid
                        item
                        container
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Grid
                          item
                          container
                          direction={'row'}
                          alignItems={'center'}
                          xs={6}
                          style={{ marginTop: 7, marginBottom: 4 }}
                        >
                          <Typography>{t('operation')}</Typography>
                        </Grid>
                        <Grid item container direction={'row'} xs={6}>
                          {getFieldFromType(
                            form,
                            {
                              fieldName: 'operation',
                              label: 'operation',
                              type: 'select',
                              options: [
                                {
                                  label: 'divide',
                                  value: MATHEMATICAL_OPERATIONS.DIVIDE,
                                },
                                {
                                  label: 'multiply',
                                  value: MATHEMATICAL_OPERATIONS.MULTIPLY,
                                },
                                {
                                  label: 'add',
                                  value: MATHEMATICAL_OPERATIONS.ADD,
                                },
                                {
                                  label: 'subtract',
                                  value: MATHEMATICAL_OPERATIONS.SUBTRACT,
                                },
                              ],
                              required: true,
                              disabled: false,
                              span: 12,
                              maxLength: 255,
                              error: 'operation_error',
                            } as any,
                            0,
                            {},
                            t,
                            true,
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem>
                      <Grid
                        item
                        container
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Grid
                          item
                          container
                          direction={'row'}
                          alignItems={'center'}
                          xs={6}
                          style={{ marginTop: 7, marginBottom: 4 }}
                        >
                          <Typography>{t('operant#2')}</Typography>
                          {getFieldFromType(
                            form,
                            {
                              fieldName: 'operant2numeric',
                              label: t('operant2'),
                              type: 'switch',
                            } as any,
                            0,
                            {},
                            t,
                            true,
                          )}
                        </Grid>
                        <Grid item container direction={'row'} xs={6}>
                          {!!form.watch('operant2numeric')
                            ? getFieldFromType(
                                form,
                                {
                                  fieldName: 'operant2',
                                  label: 'operant2',
                                  type: 'text',
                                  subType: 'Float',
                                  multiline: false,
                                  required: true,
                                  span: 12,
                                  maxLength: 255,
                                  error:
                                    parseFloat(form.watch('operant2')) === 0 &&
                                    form.watch('operation') ===
                                      MATHEMATICAL_OPERATIONS.DIVIDE
                                      ? t('divideZero')
                                      : 'operant2_error',
                                } as any,
                                0,
                                {},
                                t,
                                true,
                              )
                            : getFieldFromType(
                                form,
                                {
                                  fieldName: 'operant2',
                                  label: 'operant2',
                                  type: 'select',
                                  options: filterNumericColumns(
                                    columns,
                                    dataSearch.hiddenColumns,
                                    true,
                                  ),
                                  required: true,
                                  span: 12,
                                  maxLength: 255,
                                  error: 'operant2_error',
                                } as any,
                                0,
                                {},
                                t,
                                true,
                              )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        type={'submit'}
                        variant={'contained'}
                        color={'primary'}
                      >
                        {t('addColumn_submit')}
                      </Button>
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </Grid>
    </form>
  );
};
