import {
  DASHBOARD_START,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
} from './DashboardTypes';
import { getDashboardData } from 'repository/dashboard';
import {
  CLEAR,
  ERROR,
  LOADING,
} from 'containers/_Default/Notification/NotificationTypes';

export const fetchDashboardData = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DASHBOARD_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'dashboard_loading',
        },
      });

      const response: any = await getDashboardData();

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: DASHBOARD_SUCCESS,
        payload: response.data,
      });
    } catch (error: any) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });

      dispatch({
        type: DASHBOARD_ERROR,
        payload: message,
      });
    }
  };
};
