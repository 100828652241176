import React from 'react';
import { BoxWrap, PageTitle } from '../../components';
import ProfileDetails from './components/ProfileDetails';

const ProfileView = ({
  form,
  fields,
  formData,
  handleConfirm,
  submitFormActions,
  handleImageChange,
  file,
  imagePath,
  t
}) => {
  return (
    <>
      <PageTitle title={t('profile')} />
      <BoxWrap className="box-holder box-wrapper">
        <ProfileDetails
          form={form}
          fields={fields}
          formData={formData}
          handleConfirm={handleConfirm}
          submitFormActions={submitFormActions}
          handleImageChange={handleImageChange}
          file={file}
          imagePath={imagePath}
          t={t}
        />
      </BoxWrap>
    </>
  );
};

export default ProfileView;
