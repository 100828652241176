import {
  GROUP_START,
  GROUP_SUCCESS,
  GROUP_ERROR
} from './GroupTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: []
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GROUP_START:
      return { ...state, loading: true, error: null };

    case GROUP_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case GROUP_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
