import { Button, Grid } from '@material-ui/core';
import React from 'react';
import './LanguageSwitcher.scss';

type LanguageSwitcherProps = {
  i18n?: any;
  onChangeLanguage?: Function;
};

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => {
  const { i18n, onChangeLanguage = () => {} } = props;

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="flex-start"
      style={{ marginTop: 50 }}
    >
      <Grid item xs={2} className="language-switcher">
        <img
          src={'/images/english.png'}
          className={i18n.language === 'en' ? 'active' : 'disabled'}
          onClick={() => {
            i18n.changeLanguage('en');
            onChangeLanguage();
          }}
        />
      </Grid>
      <Grid item xs={2} className="language-switcher">
        <img
          src={'images/german.png'}
          className={i18n.language === 'de' ? 'active' : 'disabled'}
          onClick={() => {
            i18n.changeLanguage('de');
            onChangeLanguage();
          }}
        />
      </Grid>
    </Grid>
  );
};
