export const GET_USER = 'user/user-form-start';
export const GET_USER_SUCCESS = 'user/user-form-success';
export const GET_USER_ERROR = 'user/user-form-error';
export const USER_CLEAR = 'user/user-form-clear';
export const USER_EDIT = 'user/user-form-edit';
export const USER_SINGLE_SUCCESS = 'user/user-single-success';
export const USER_UPDATE_SUCCESS = 'user/user-update-success';
export const USER_CREATE_SUCCESS = 'user/user-create-success';
export const USER_AUDIT = 'user/audit';
export const GET_LOCALES = 'user/locales-success';
export const GET_BOARDS = 'user/get-boards';
