import {
  DATA_SEARCH_ERROR,
  GET_DATA_FIELDS,
  GET_DATA_SEARCH_RESULTS,
  GET_DATA_SEARCH,
  DATA_SEARCH_EXPORT_RESULTS,
} from './DataSearchTypes';
import {
  exportSearchResults,
  getDataSearchFields as getDataSearchFieldsAPI,
  getSearchResults,
} from 'repository/dataSearch';
import {
  ERROR,
} from 'containers/_Default/Notification/NotificationTypes';

export const getDataSearchFields = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_DATA_SEARCH,
      });

      const response = await getDataSearchFieldsAPI();
      dispatch({
        type: GET_DATA_FIELDS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';
      dispatch({
        type: DATA_SEARCH_ERROR,
      });
      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const getDataSearchResults = (query, pagination) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_DATA_SEARCH,
      });

      const response = await getSearchResults(query, pagination);

      if (!response.data.totalDocs) {
        const message = 'emptySearchResults';
        dispatch({
          type: ERROR,
          payload: {
            isOpen: true,
            message,
          },
        });
      }

      dispatch({
        type: GET_DATA_SEARCH_RESULTS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: DATA_SEARCH_ERROR,
      });
      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message:
            error?.response?.status === 504
              ? 'failedRequest'
              : message,
        },
      });
    }
  };
};

export const exportDataSearchResults = (
  query,
  pagination,
  selectedResults,
  columns,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_DATA_SEARCH,
      });

      const response = await exportSearchResults(
        query,
        pagination,
        selectedResults,
        columns,
      );
      dispatch({
        type: DATA_SEARCH_EXPORT_RESULTS,
      });

      return response.data;
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';
      dispatch({
        type: DATA_SEARCH_ERROR,
      });
      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
