import {
  MATERIAL_DETAILS_START,
  MATERIAL_DETAILS_SUCCESS, 
  MATERIAL_INGREDIENT_SUCCESS, 
  MATERIAL_SPECIFICATION_SUCCESS,
} from './MaterialDetailsTypes';
import {
  LOADING,
  CLEAR,
} from 'containers/_Default/Notification/NotificationTypes';
import {
  editMaterialSpecification,
  getMaterial, 
  updateRecipeIngredients,
} from '../../repository/material';
import {
  ERROR,
  SUCCESS,
} from 'containers/_Default/Notification/NotificationTypes';
import { editMaterial } from 'repository/material';

export const fetchMaterial = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'material_loading',
        },
      });

      const response: any = await getMaterial(params);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: MATERIAL_DETAILS_SUCCESS,
        payload: {
          ...response.data,
        },
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterialInfo = (id: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await editMaterial(id, data);
      dispatch({
        type: MATERIAL_DETAILS_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_updateSuccess',
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterialSpecification = (id: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await editMaterialSpecification(id, data);
      dispatch({
        type: MATERIAL_SPECIFICATION_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_updateSuccess',
        },
      });
      return true;
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterialIngredient = (id: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await updateRecipeIngredients(id, data);
      dispatch({
        type: MATERIAL_INGREDIENT_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_updateSuccess',
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
