import React from 'react';
import { Sorter } from '../../..';
import {
  TableRow,
  Typography,
  TableHead,
  TableCell,
  Checkbox,
} from '@material-ui/core';
import './TableHeader.scss';

enum ColumnTypes {
  CHECKBOX = 'checkbox',
  LABEL = 'label',
}

export interface ITableHeaderColumn {
  type: string; // checkbox, label, icon.
  label?: string; // Display name of the column.
  field?: string; // Which field in the data corresponds to this column. Empty if none.
  sortable?: boolean; // User can perform sorting by that column.
  sortBy?: string; // Non mandatory field. If not present, sorting will be done by "field".
  icon?: any; // HTML element representing the icon. Can be a MaterialUI icon.
  icons?: any; //Array of icons to be displayed in a single cell
  callbackMethod?: any; //Function which executes on click of the icon.
}

export const TableHeader = (props) => {
  const {
    columnNames,
    checkedItems,
    rows,
    setFilters,
    filters,
    handleCheckAll = () => {},
  } = props;

  const handleSort = (direction, field) => {
    setFilters({ ...filters, sort: `${direction}${field}` });
  };
  return (
    <TableHead>
      <TableRow>
        {columnNames.map((column) => {
          if (column.type === 'icon') {
            return null;
          }

          let template = (
            <Typography variant="body2">{column.label}</Typography>
          );

          if (column.type === ColumnTypes.CHECKBOX) {
            template = (
              <Checkbox
                checked={checkedItems.length === rows?.length}
                onClick={handleCheckAll}
                color="secondary"
              />
            );
          } else if (column.type === ColumnTypes.LABEL && !!column.sortable) {
            template = (
              <Sorter
                label={column.label}
                handleSort={(direction) =>
                  handleSort(direction, column.sortBy || column.field)
                }
              />
            );
          }

          return (
            <TableCell
              key={column.label || column.type}
              className={column.type === 'icons' ? 'sticky-column' : ''}
            >
              {template}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
