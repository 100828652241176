import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import './CustomModal.scss';
import { Typography } from '@material-ui/core';

export const CustomModal = (props) => {
  const {
    children,
    isOpen,
    setIsOpen,
    confirm,
    data,
    disableActions = false,
    disableContentText = false,
  } = props;

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    confirm();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="alert-dialog"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant={'h4'}>{data.dialogTitle}</Typography>
      </DialogTitle>
      {!disableContentText && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {data.dialogContentText}
          </DialogContentText>
        </DialogContent>
      )}
      {children}
      {!disableActions && (
        <DialogActions className="alert-dialog-actions">
          {data.cancelText && (
            <Button onClick={handleClose} variant="contained">
              {data.cancelText}
            </Button>
          )}
          <Button
            onClick={handleConfirm}
            disabled={data.disabledConfirm || false}
            color="primary"
            variant="contained"
            autoFocus
          >
            {data.confirmText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
