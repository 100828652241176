import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';

const DateTimePickerField = (props) => {
  const { form, field, formData } = props;

  return (
    <Grid
      item
      container
      key={`${field.label}-form-field`}
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextField
            label={`${field.label}`}
            type="datetime-local"
            fullWidth
            disabled={field.disabled}
            defaultValue={
              form.getValues(`${field.fieldName}`) ||
              (formData && formData[`${field.fieldName}`]) ||
              field.defaultDate ||
              null
            }
            onChange={(e) => {
              form.setValue(`${field.fieldName}`, e);
              onChange(e);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        name={`${field.fieldName}`}
        control={form.control}
        rules={{
          required: field.required,
        }}
        defaultValue={
          form.getValues(`${field.fieldName}`) ||
          (formData && formData[`${field.fieldName}`]) ||
          field.defaultDate ||
          ''
        }
      />
    </Grid>
  );
};

export default DateTimePickerField;
