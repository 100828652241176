import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { CustomModal } from 'components/CustomModal/CustomModal';
import { getFieldFromType } from '../FieldTypes';
import isEqual from 'lodash/isEqual';

import './MultiTabForm.scss';

export const MultiTabForm = (props) => {
  const { fields, formData, form, confirm, editMode, t, canSave = true } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const renderFormFields = (fields) => {
    return (
      <Grid container spacing={6}>
        {Object.keys(fields).map((key, index) => {
          return getFieldFromType(
            form,
            fields[key],
            index,
            formData,
            t,
            editMode,
            !canSave
          );
        })}
      </Grid>
    );
  };
  // useEffect(() => {}, formData);
  return (
    <div className="dynamic-form">
      {renderFormFields(fields)}
      <Grid container justify="flex-end" spacing={2}>
        <Grid item>
          <Button
            className="submit-button"
            variant="contained"
            onClick={() => {
              const values = form.getValues();
              const outputs = {};
              if (formData) {
                Object.keys(formData).forEach((key) => {
                  outputs[key] = values[key];
                  if (['_id', '__v', 'createdAt', 'updatedAt'].includes(key)) {
                    outputs[key] = formData[key];
                  }
                });
              } else return confirm();
              return !isEqual(formData, outputs)
                ? setModalOpen(true)
                : confirm();
            }}
          >
            {t('cancel', { ns: 'common' })}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className="submit-button"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!canSave}
          >
            {formData?._id ? t('saveChanges', { ns: 'common' }) : t('create', { ns: 'common' })}
          </Button>
        </Grid>
      </Grid>
      <CustomModal
        data={{
          dialogTitle: t('cancelConfirm', { ns: 'common' }),
          dialogContentText: '',
          cancelText: t('cancelConfirmNo', { ns: 'common' }),
          confirmText: t('cancelConfirmYes', { ns: 'common' }),
        }}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        confirm={confirm}
      />
    </div>
  );
};
