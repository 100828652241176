import { RESET_START, RESET_SUCCESS, RESET_ERROR } from './ForgotPasswordTypes';

const INITIAL_STATE = {
  loading: false,
  done: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case RESET_START:
      return { ...state, loading: true, error: null };

    case RESET_SUCCESS:
      return { ...state, loading: false, done: true };
    
    case RESET_ERROR:
      return {...INITIAL_STATE};

    default:
      return state;
  }
};
