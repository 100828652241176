import { createMuiTheme } from '@material-ui/core/styles';

import './main.scss';

const colors = {
  primary: {
    main: '#2c5364',
    dark: '#203a43',
    font: '#212121',
  },
  secondary: {
    main: '#fca744',
    dark: '#b0640c',
    font: '#5f6368',
  },
  primaryFont: {
    light: '#ebebeb',
    dark: '#212121',
  },
};

export const AppTheme = createMuiTheme({
  typography: {
    fontFamily: '"Nunito", sans-serif',
    h1: {
      fontSize: '34px',
      lineHeight: '36px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '26px',
      lineHeight: '30px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '22px',
      lineHeight: '27px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 700,
    },
    h5: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 700,
      color: '#212121',
    },
    h6: {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
      color: '#212121',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 400,
      color: '#5f6368',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 700,
      color: '#5f6368',
    },
  },
  palette: {
    primary: {
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
  },
  overrides: {
    MuiButtonGroup: {
      root: {
        '&.create-btn-group': {
          '& button.MuiButton-containedSecondary': {
            color: colors.primary.main,
          },
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:last-child td': {
          borderBottom: 0,
          boxShadow: 'none',
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#d9d9d9',
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#fff',
        color: '#212121 !important',
        height: '70px',
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#fff',
        padding: '15px',
        color: '#5f6368',
        border: '1px solid #5f6368',
        borderRadius: '4px',
      },
    },
    MuiToolbar: {
      regular: {
        height: '80px',
      },
    },
    MuiSvgIcon: {
      root: {
        '&.search-icon': {
          color: colors.primary.main,
        },
      },
      colorPrimary: {
        color: colors.primary.main,
      },
      colorSecondary: {
        color: colors.secondary.dark,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.primary.main,
      },
      colorPrimary: {
        color: colors.primary.main,
      },
      colorSecondary: {
        color: colors.secondary.dark,
      },
    },
    MuiInput: {
      root: {
        color: colors.primary.font,
        '&$focused': {
          color: colors.primary.font,
        },
      },
      underline: {
        '&:before': {
          borderBottom: `2px solid #e0e0e0`,
        },
        '&:after': {
          display: 'none',
          borderBottom: `1px solid ${colors.secondary.font}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${colors.secondary.dark}`,
        },
        '&.Mui-focused:before': {
          borderBottom: `1px solid ${colors.secondary.dark}`,
        },
      },
    },
    MuiInputBase: {
      input: {
        marginBottom: '5px',
        fontSize: '15px',
        lineHeight: '18px',
      },
    },
    MuiFormControlLabel: {
      root: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.secondary.font,
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
        textAlign: 'left',
        '&$focused': {
          color: colors.secondary.dark,
          fontSize: '16px',
          lineHeight: '16px',
        },
      },
      filled: {
        color: colors.secondary.dark,
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.03)',
      },
      rounded: {
        borderRadius: '3px',
      },
    },
    MuiLink: {
      root: {
        fontSize: '14px',
        lineHeight: '17px',
        color: colors.secondary.dark,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#fff',
          color: colors.primary.font,

          '&:hover': {
            backgroundColor: '#fff',
            color: colors.secondary.font,
          },
        },
      },
      button: {
        '&.sidebar-item': {
          '&:hover': {
            backgroundColor: '#2c5364',
            color: '#',
          },
          '& a': {
            color: '#2c5364',
          },
          '& .item--selected': {
            color: colors.primary.main,
            '&:hover': {
              color: colors.primary.main,
            },
            '& .sidebar-icon': {
              color: colors.primary.main,
            },
          },
        },

        '&:hover': {
          backgroundColor: '#2c5364',
          color: colors.primary.font,
        },

        '& a': {
          color: '#212121',
          paddingLeft: '16px',
          paddingRight: '16px',
          width: '100%',
          height: '100%',

          '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#2c5364',
            color: '#fff',
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        padding: '10px 20px',
        backgroundColor: colors.primary.main,
        textTransform: 'inherit',
        fontSize: '14px',
        lineHeight: '17px',
        textAlign: 'left',
        height: '48px',
        '&:hover': {
          backgroundColor: 'auto',
        },
        color: '#fff',
      },
      text: {
        borderRadius: '6px',
        padding: '15px 20px',
        background: '#f2f2f2',
        fontSize: '13px',
        textTransform: 'inherit',
        color: '#fff',
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#f2f2f2',
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
      sizeLarge: {
        padding: '15px 50px',
        fontSize: '14px',
        lineHeight: '17px',
      },
      sizeSmall: {
        padding: '10px 20px',
        fontSize: '10px',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#fff',
        boxShadow: '0 2px 0 0 rgba(43, 45, 55, 0.1)',
        color: colors.secondary.dark,
      },
    },
    MuiTableContainer: {
      root: {
        padding: 10,
        width: '98%',
      },
    },
    MuiTableCell: {
      root: {
        padding: '5px',
        boxShadow: '0 2px 0 0 rgba(43, 45, 55, 0.05)',
        '&.minused': {
          color: colors.primary.main,
        },
        '& p': {
          lineHeight: '24px',
        },
      },
      head: {
        color: colors.secondary.dark,
      },
      body: {
        color: colors.secondary.font,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '15px',
        lineHeight: '18px',
        '&.Mui-selected': {
          textColorPrimary: {
            color: colors.primaryFont.dark,
            borderBottom: '0px',
            fontSize: '15px',
            lineHeight: '18px',
          },
          fontWeight: 600,
        },
      },
      textColorPrimary: {
        color: colors.primary.font,
      },
      textColorSecondary: {
        color: colors.secondary.font,
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `2px solid #e0e0e0`,
        margin: '10px 0',
      },
      indicator: {
        backgroundColor: colors.secondary.dark,
      },
    },
    MuiDrawer: {
      root: {
        overflowY: 'auto',
        height: '100%',
      },
      paperAnchorRight: {
        maxWidth: 600,
        minWidth: 600,
        padding: '0px 25px',
      },
    },
    MuiDivider: {
      root: {
        color: '#e0e0e0',
        height: '2px',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: colors.primary.dark,
        '&.Mui-checked': {
          color: colors.primary.dark,
        },
      },
      colorSecondary: {
        color: colors.secondary.font,
        '&.Mui-checked': {
          color: colors.secondary.dark,
        },
      },
    },
    MuiDialog: {
      paper: {
        padding: '25px 33px',
      },
      paperScrollPaper: {
        maxHeight: '60%',
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: `2px solid #e0e0e0`,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiAccordion: {
      root: {
        margin: '16px 0px',
        '&$expanded': {
          margin: '16px 0px',
        },
      },
    },
    MuiStepper: {
      root: {
        width: '50%',
        backgroundColor: 'transparent',
        padding: '0px 0px 60px 0px',
      },
    },
    MuiStepLabel: {
      root: {
        fontWeight: 600,
      },
      label: {
        color: '#212121',
        fontWeight: 600,
        '&$active': {
          color: '#212121',
          fontWeight: 600,
        },
      },
    },
    MuiStepConnector: {
      line: {
        color: '#2b2d37',
        borderColor: '#2b2d37',
        opacity: 0.1,
      },
    },
    MuiStepIcon: {
      root: {
        color: '#2c5364',
        opacity: 0.1,
        '&$active': {
          opacity: 1,
        },
      },
      text: {
        fill: 'transparent',
      },
    },
  },
});
