import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { InputError } from 'components';
import { Controller } from 'react-hook-form';

export const TestTypesField = (props) => {
  const { form, field: fieldData, formData, t } = props;
  const [disabled, setDisabled] = useState(true);
  const companyWatcher = form.watch(
    'company',
    formData.company?._id.toString(),
  );
  const deviceTypeWatcher = form.watch('deviceType', formData.deviceType);

  const config = JSON.parse(localStorage.getItem('config') || '{}');

  const [options, setOptions] = useState([
    ...config.testTypes?.map((item) => ({
      label: item.type,
      value: item._id,
    })),
  ]);

  useEffect(() => {
    if (companyWatcher && deviceTypeWatcher) {
      setOptions([
        ...config.testTypes
          ?.filter((item) => item.company === companyWatcher)
          ?.map((item) => ({
            label: item.label,
            type: item.type,
            value: item._id,
          })),
      ]);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [companyWatcher, deviceTypeWatcher]);

  useEffect(() => {
    // if (disabled) {
    //   form.setValue('testType', '');
    // }
  }, [disabled]);

  const setDefaultValue = ({ value, options }) => {
    const defaultValue = options.find(
      (option) => option.label === value || option.value === value,
    );
    if (defaultValue) {
      return defaultValue.value;
    } else {
      return '';
    }
  };

  return !disabled ? (
    <Grid item xs={fieldData.span} key={`testType-form-field`}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <TextField
            label={
              fieldData.required
                ? `${t(fieldData.label)} *`
                : t(fieldData.label)
            }
            select
            defaultValue={
              formData &&
              setDefaultValue({
                value: formData[fieldData.fieldName],
                options: options,
              })
            }
            disabled={disabled}
            margin={'dense'}
            fullWidth
            variant="standard"
            error={form.formState.errors[fieldData.fieldName]}
            helperText={
              form.formState.errors[fieldData.fieldName] && (
                <InputError message={t(fieldData.error)} />
              )
            }
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={(e) => onChange((value = e.target.value))}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(`testType_${option.type}`, { ns: 'project' })}
              </MenuItem>
            ))}
          </TextField>
        )}
        name={fieldData.fieldName}
        control={form.control}
        defaultValue={
          formData &&
          setDefaultValue({
            value: formData[fieldData.fieldName],
            options: options,
          })
        }
        rules={{
          required: fieldData.required,
        }}
      />
    </Grid>
  ) : null;
};
