
export const GET_GROUP = 'group/group-form-start';
export const GET_GROUP_SUCCESS = 'group/group-form-success';
export const GET_GROUP_ERROR = 'group/group-form-error';
export const GROUP_CLEAR = 'group/group-form-clear';
export const GROUP_SINGLE_SUCCESS = 'group/group-single-success';
export const GROUP_UPDATE_SUCCESS = 'group/group-update-success';
export const GROUP_CREATE_SUCCESS = 'group/group-create-success';
export const GROUP_AUDIT = 'group/audit';
export const GROUP_EDIT = 'group/group-edit';
export const GET_LOCALES = 'group/locales-success';
export const GET_BOARDS = 'group/get-boards';