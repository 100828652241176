import { ROLE_START, ROLE_SUCCESS } from './RoleTypes';

const INITIAL_STATE = {
  loading: false,
  data: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ROLE_START:
      return { ...state, loading: true, error: null };

    case ROLE_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    default:
      return state;
  }
};
