import {
  PROJECT_RECIPE_RESET,
  PROJECT_RECIPE_ERROR,
  PROJECT_RECIPE_SUCCESS,
  PROJECT_RECIPE_START,
  RECIPE_ADD_ROW,
  RECIPE_ADD,
  RECIPE_EDIT_PERCENTAGE,
  RECIPE_EDIT_MATERIAL,
  RECIPE_SAVE_SUCCESS,
  RECIPE_MARK_FINAL,
  PROJECT_RECIPE_FILTER_RECIPE,
  PROJECT_RECIPE_FILTERED_RECIPES_RESULTS_SUCCESS,
} from './ProjectRecipeTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    projectId: '',
    recipeRowMap: {},
    recipe: {
      selectedSpecifications: [],
      selectedRecipesResults: [],
    },
    recipes: [],
    referenceRecipe: {},
    selectedSpecifications: [],
    selectedRecipesResults: [],
    filteredRecipes: [],
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECT_RECIPE_START:
      return { ...state, loading: true, error: null };

    case PROJECT_RECIPE_SUCCESS:
      const {
        projectId,
        referenceRecipe,
        recipes,
      } = action.payload;


      const recipeRowMap = {};

      if(referenceRecipe && recipes.length == 0){
        let fromReferenceRecipe = { ...referenceRecipe, new: true, _id: undefined, name: `${projectId}_${referenceRecipe.name}-f${state.data.recipes.length + 1}` };
        recipes.push(fromReferenceRecipe);
      }

      recipes[recipes?.length - 1]?.ingredients?.map(
        (recipeRow, rowIndex) => {
          const generatedId = Date.now() + Math.floor(Math.random() * 1000);
          recipeRowMap[generatedId] = {
            ...recipeRow,
            generatedId,
            recipes: [],
          };

          recipes.map((recipe, index) => {
            recipeRowMap[generatedId].recipes[index] = {
              percentage: recipe.ingredients[rowIndex]?.percentage || 0,
            };
          });
        },
      );

      recipes.map((recipe) => {
        if (recipe.results?.length) {
          recipe.isEditable = recipe.results.every((result) => {
            return result.hasData === false;
          });
        }
        return recipe;
      });

      return {
        ...state,
        data: {
          ...action.payload,
          recipeRowMap: recipeRowMap,
          recipes: recipes,
        },
        loading: false,
      };

    case RECIPE_ADD_ROW:
      const tempRecipeRowMap = { ...state.data.recipeRowMap };
      console.log('tempRecipeRowMap', tempRecipeRowMap);
      const generatedId = Date.now() + Math.floor(Math.random() * 1000);
      tempRecipeRowMap[generatedId] = {
        generatedId,
        new: true,
        recipes: [],
      };

      let tempRecipes = state.data.recipes;
      console.log('tempRecipes', tempRecipes);
      if (!tempRecipes.length) {
        tempRecipes = [
          {
            name: `${state.data.recipe.name}-f${state.data.recipes.length + 1}`,
            ingredients: [],
            new: true,
          },
        ];
      }

      tempRecipes = tempRecipes.map((recipe, index) => {
        tempRecipeRowMap[generatedId].recipes[index] = {
          percentage: 0,
        };
        return {
          ...recipe,
          ingredients: [
            ...recipe?.ingredients,
            {
              materialNumber: '',
              materialName: '',
              percentage: 0,
              position: recipe.ingredients.length + 1,
            },
          ],
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipeRowMap: tempRecipeRowMap,
          recipes: tempRecipes,
        },
      };

    case RECIPE_ADD:
      if (state.data.recipes.length === 0) {
        const generatedId = Date.now() + Math.floor(Math.random() * 1000);
        state.data.recipeRowMap[generatedId] = {
          percentage: 0,
          generatedId,
          recipes: [],
          new: true,
        };
      }

      const newRecipe = {
          ...state.data.recipes[0],
          name: `${state.data.recipe.name}-f${state.data.recipes.length + 1}`,
          ingredients: state.data.recipes.length
          ? [...state.data.recipes]?.pop()?.ingredients.map((ingredient) => {
              delete ingredient._id;
              return { ...ingredient };
            })
          : [
              {
                materialNumber: '',
                materialName: '',
                percentage: 0,
                position: 1,
              },
            ],
        new: true,
      };
      delete newRecipe._id;
      Object.keys(state.data.recipeRowMap).forEach((key, stateRowIndex) => {
        state.data.recipeRowMap[key] = {
          ...state.data.recipeRowMap[key],
          recipes: [
            ...state.data.recipeRowMap[key].recipes,
            {
              percentage: newRecipe.ingredients[stateRowIndex]?.percentage || 0,
              new: true,
            },
          ],
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipes: [
            ...state.data.recipes, newRecipe,
          ],
          filteredRecipes: [
            ...state.data.filteredRecipes,
          ],
        },
      };

    case RECIPE_EDIT_PERCENTAGE:
      const { rowIndex, recipeIndex, value } = action.payload;

      state.data.recipes[recipeIndex].ingredients.map((row, index) => {
        if (index === rowIndex) {
          row.percentage = +value;
        }
      });

      return {
        ...state,
      };

    case RECIPE_EDIT_MATERIAL:
      const tmpRecipes = state.data.recipes;

      tmpRecipes.map((recipe) => {
        console.log(action.payload, recipe.ingredients);
        recipe.ingredients[action.payload.rowIndex].materialNumber =
          action.payload.value;

        recipe.ingredients[action.payload.rowIndex].materialName =
          action.payload.value;
        return { ...recipe };
      });

      return {
        ...state,
        data: {
          ...state.data,
          recipe: {
            ...state.data.recipe,
          },
          recipes: tmpRecipes,
        },
      };

    case RECIPE_SAVE_SUCCESS:
      return { ...state, loading: false, error: null };

    case RECIPE_MARK_FINAL:
      return {
        ...state,
        data: {
          ...state.data,
          recipes: state.data.recipes.map((recipe) => {
            if (recipe._id === action.payload.recipe) {
              recipe.final = action.payload.final;
            }

            return recipe;
          }),
        },
        loading: false,
        error: null,
      };

    case PROJECT_RECIPE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROJECT_RECIPE_RESET:
      return INITIAL_STATE;

    case PROJECT_RECIPE_FILTER_RECIPE:
      const { recipe, checked } = action.payload;

      let tempFilteredRecipes = [
        ...(state.data.filteredRecipes || []),
      ];

      if (checked && !tempFilteredRecipes.includes(recipe)) {
        tempFilteredRecipes.push(recipe);
      } else {
        tempFilteredRecipes = tempFilteredRecipes.filter(
          (filteredRecipe) => filteredRecipe !== recipe,
        );
      }

      return {
        ...state,
        data: {
          ...state.data,
          filteredRecipes: tempFilteredRecipes,
        },
        loading: false,
      };

    case PROJECT_RECIPE_FILTERED_RECIPES_RESULTS_SUCCESS:
      const selectedRecipesResults: Array<string> = [];
      for (const recipe of action.payload.recipes) {
        if (action.payload.filteredRecipes.includes(recipe._id)) {
          for (const results of recipe.results) {
            for (const resultTable of results.resultTables) {
              selectedRecipesResults.push(resultTable._id);
            }
          }
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          recipes: action.payload.recipes,
          filteredRecipes: action.payload.filteredRecipes,
          selectedRecipesResults: selectedRecipesResults,
        },
      };

    default:
      return state;
  }
};
