import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

import * as Sentry from '@sentry/react';

export const configureStore = () => {
  const composeEnhancers =
    window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

  const middleware = [thunk];
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  return createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
  );
};
