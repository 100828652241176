import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import IconLeft from './assets/icon-left.svg';
import IconRight from './assets/icon-right.svg';

import './TablePagination.scss';
import { useTranslation } from 'react-i18next';

export const TablePagination = (props) => {
  const { nextPage, prevPage, totalPages, setFilters, filters, t } = props;

  const handlePageClick = (pageNumber) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        page: pageNumber,
      };
    });
  };
  return (
    <Grid
      className={'pagination'}
      direction={'row'}
      container
      justify="flex-end"
      alignItems="flex-end"
    >
      {totalPages > 0 && (
        <button
          className={'pagination-button fixed-button'}
          disabled={prevPage == null}
          onClick={() => handlePageClick(0)}
        >
          <Typography variant={'body2'}>
            {t('tablePagination_first')}
          </Typography>
        </button>
      )}

      <Box className={'numbered-pagination'}>
        {totalPages > 0 && (
          <button
            disabled={prevPage === null}
            className={'pagination-button left'}
            onClick={() => handlePageClick(filters.page - 1)}
          >
            <img src={IconLeft} alt="previous" />
          </button>
        )}

        {filters.page - 2 >= 0 && (
          <button
            className={'pagination-button'}
            onClick={() => handlePageClick(filters.page - 2)}
          >
            <Typography variant={'body2'}>{filters.page - 1}</Typography>
          </button>
        )}

        {filters.page - 1 >= 0 && (
          <button
            className={'pagination-button'}
            onClick={() => handlePageClick(filters.page - 1)}
          >
            <Typography variant={'body2'}>{filters.page}</Typography>
          </button>
        )}

        <button className={'pagination-button active'} disabled>
          <Typography variant={'body2'}>{filters.page + 1}</Typography>
        </button>

        {filters.page + 1 < totalPages && (
          <button
            className={'pagination-button'}
            onClick={() => handlePageClick(filters.page + 1)}
          >
            <Typography variant={'body2'}>{filters.page + 2}</Typography>
          </button>
        )}

        {filters.page + 2 < totalPages && (
          <button
            className={'pagination-button'}
            onClick={() => handlePageClick(filters.page + 2)}
          >
            <Typography variant={'body2'}>{filters.page + 3}</Typography>
          </button>
        )}

        {totalPages > 0 && (
          <button
            className={'pagination-button right'}
            disabled={nextPage === null}
            onClick={() => handlePageClick(filters.page + 1)}
          >
            <img src={IconRight} alt="next" />
          </button>
        )}
      </Box>

      {totalPages > 0 && (
        <button
          className={'pagination-button fixed-button'}
          disabled={nextPage == null}
          onClick={() => handlePageClick(totalPages - 1)}
        >
          <Typography variant={'body2'}>
            {' '}
            {t('tablePagination_last')}
          </Typography>
        </button>
      )}
    </Grid>
  );
};
