export const GET_CONVERSATIONS_START = 'chat/chat-get-conversations';
export const GET_CONVERSATIONS_SUCCESS = 'chat/chat-get-conversations-success';
export const GET_CONVERSATIONS_ERROR = 'chat/chat-get-conversations-error';
export const CONVERSATIONS_CLEAR = 'chat/chat-conversations-clear';
export const CONVERSATION_SINGLE_START = 'chat/chat-conversation-single-start';
export const CONVERSATION_SINGLE_SUCCESS =
  'chat/chat-conversation-single-success';
export const CONVERSATION_CLEAR = 'chat/chat-conversation/clear';
export const CONVERSATION_SINGLE_ERROR = 'chat/chat-conversation-single-error';
export const MESSAGE_SINGLE_START = 'chat/message-single-start';
export const MESSAGE_SINGLE_LOADING = 'chat/message-single-loading';
export const MESSAGE_SINGLE_SUCCESS = 'chat/message-single-success';
