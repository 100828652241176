import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Link, Paper, Typography } from '@material-ui/core';

import { ChartWidget, TableWidget } from '../Widgets';

import './DashboardWidget.scss';

export const DashboardWidget = ({ config, data, t }) => {
  const history = useHistory();
  const render = () => {
    if (config && data) {
      switch (config.type) {
        case 'table':
          return <TableWidget config={config} data={data} />;
        case 'chart':
          return <ChartWidget config={config} data={data} t={t}/>;
        default:
          return null;
      }
    }
  };

  return (
    <Paper className="dashboard-widget-container">
      <Grid container>
        <Grid item sm={12} container>
          <Grid item sm={8}>
            <Typography variant="h3" className="dashboard-widget-title">
              {config.title}
            </Typography>
          </Grid>
          {config.redirect && (
            <Grid
              item
              sm={4}
              container
              justify="flex-end"
              alignContent="center"
              spacing={3}
            >
              <Link
                color="secondary"
                className="dashboard-widget-redirect"
                onClick={() => history.push(config.redirect)}
              >
                {t('viewAll')}
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>

      {render()}
    </Paper>
  );
};
