import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink, Link } from 'react-router-dom';
import * as RoleManager from 'services/role';

import CallSplitIcon from '@material-ui/icons/CallSplit';
import LaunchIcon from '@material-ui/icons/Launch';

import './Sidebar.scss';

type SidebarProps = {
  isOpen: boolean;
  closeSidebar: Function;
};

const menuItems = [
  /* // injection line - do not remove or edit */
  {
    url: 'projectedit',
    name: 'Project',
    icon: <LaunchIcon className="sidebar-icon" />,
    hasAccess:
      RoleManager.isAbleTo('projectedit', 'findAll') ||
      RoleManager.isAbleTo('projectedit', 'findOne'),
  },
  {
    url: 'device',
    name: 'Device',
    icon: <LaunchIcon className="sidebar-icon" />,
    hasAccess:
      RoleManager.isAbleTo('device', 'findAll') ||
      RoleManager.isAbleTo('device', 'findOne'),
  },
  {
    url: 'group',
    name: 'Group',
    icon: <LaunchIcon className="sidebar-icon" />,
    hasAccess:
      RoleManager.isAbleTo('group', 'findAll') ||
      RoleManager.isAbleTo('group', 'findOne'),
  },
  {
    url: 'location',
    name: 'Location',
    icon: <LaunchIcon className="sidebar-icon" />,
    hasAccess:
      RoleManager.isAbleTo('location', 'findAll') ||
      RoleManager.isAbleTo('location', 'findOne'),
  },
  {
    url: 'user',
    name: 'User',
    icon: <LaunchIcon className="sidebar-icon" />,
    hasAccess:
      RoleManager.isAbleTo('user', 'findAll') ||
      RoleManager.isAbleTo('user', 'findOne'),
  },
  {
    url: 'company',
    name: 'Company',
    icon: <LaunchIcon className="sidebar-icon" />,
    hasAccess:
      RoleManager.isAbleTo('company', 'findAll') ||
      RoleManager.isAbleTo('company', 'findOne'),
  },
];

export const Sidebar: React.FC<SidebarProps> = ({ isOpen, closeSidebar }) => {
  const account = useSelector((state) => state.account);
  return (
    <Drawer
      className={`sidebar ${isOpen ? 'sidebar--open' : 'sidebar--closed'}`}
      anchor="left"
      open={true}
      variant="persistent"
    >
      <List
        className="sidebar-list"
        onClick={() => {
          closeSidebar();
        }}
      >
        {(menuItems || []).map((item: any) => {
          if (!item.hasAccess) return null;
          return (
            <ListItem key={item.name} button className="sidebar-item">
              <NavLink
                exact
                to={`/dashboard/${item.url}`}
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title={item.name}>
                    {item.icon}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
      {!menuItems.length && (
        <div className="no-entities">
          <Typography variant="h4">Looks like you have no Entities</Typography>
          <p>
            You can create one by running <code>hygen page new</code> and
            following the instructions provided.
          </p>
          <p>
            Newly created entities will appear here. To speed up the creation of
            new Entities you can use the{' '}
            <Link to="/dashboard/entitycreator/create">
              Entity Creator Assistant
            </Link>
            .
          </p>
          <p>
            For more help you can always check out the official{' '}
            <Link to="/dashboard/help">Help page</Link>.
          </p>
        </div>
      )}
      <ul
        className="bottom-links"
        onClick={() => {
          closeSidebar();
        }}
      >
        {(account?.user?.workspace || []).map((workspace, index) => (
          <li key={index}>
            <CallSplitIcon className="sidebar-bottom-icon" />
            {workspace?.name}
          </li>
        ))}
      </ul>
    </Drawer>
  );
};
