import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const DateRangeFormField = ({ form, field, formData, point }) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={'dd/MM/yyyy'}
            margin="normal"
            fullWidth
            disabled={field.disabled}
            id="date-picker-inline"
            label={`${field.label}-from` || 'Please select a date'}
            autoOk={true}
            value={
              form.getValues(`${field.fieldName}.value.${point}`) ||
              (formData && formData[`${field.fieldName}.value.${point}`]) ||
              field.defaultDate ||
              null
            }
            onChange={(e) => {
              const date = moment.utc(e);
              e = date.toDate();
              form.setValue(`${field.fieldName}.value.${point}`, e);
              onChange(e);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            inputVariant={'standard'}
          />
        </MuiPickersUtilsProvider>
      )}
      name={`${field.fieldName}.value.${point}`}
      control={form.control}
      rules={{
        required: field.required,
      }}
      defaultValue={
        form.getValues(`${field.fieldName}.value.${point}`) ||
        (formData && formData[`${field.fieldName}.value.${point}`]) ||
        field.defaultDate ||
        null
      }
    />
  );
};
const DateRangePickerField = (props) => {
  const { form, field, formData } = props;

  return (
    <Grid
      item
      container
      key={`${field.label}-form-field`}
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={6} className={'search-datepicker'}>
        <DateRangeFormField
          field={field}
          form={form}
          formData={formData}
          point={'from'}
        />
      </Grid>
      <Grid item xs={6} className={'search-datepicker'}>
        <DateRangeFormField
          field={field}
          form={form}
          formData={formData}
          point={'to'}
        />
      </Grid>
    </Grid>
  );
};

export default DateRangePickerField;
