import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Typography } from '@material-ui/core';
import { Loading, InputError, LanguageSwitcher } from 'components';
import { emailRegex } from 'services/config';

import './InitForm.scss';
import { useTranslation } from 'react-i18next';

type InitFormProps = {
  init: any;
  initUser: Function;
};

export const InitForm: React.FC<InitFormProps> = ({ init, initUser }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref, ...rest } = register('username', {
    required: true,
    pattern: emailRegex,
  });

  const submitForm: any = ({ username, password, rememberMe }) => {
    initUser({ username, password, rememberMe });
  };

  const { t, i18n } = useTranslation('login');

  useEffect(() => {
    const handleLanguageChange = () => {
      i18n.changeLanguage(navigator.language);
    };
    //added event listener to listen for changes in the browser's default language
    window.addEventListener('languagechange', handleLanguageChange);

    return () => {
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  return (
    <div className="init-form-wrap">
      {init && init.loading ? <Loading /> : null}
      <Typography variant="h2" align="center" className="create-admin-title">
        {t('initTitle')}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        className="create-admin-subtitle"
      >
        {t('initMessage')}
      </Typography>
      <form onSubmit={handleSubmit(submitForm)}>
        <TextField
          inputProps={{ ...rest, name: 'username' }}
          inputRef={ref}
          className="init-input"
          margin="normal"
          variant="standard"
          label={t('email')}
          helperText={
            errors.username && <InputError message={t('emailError')} />
          }
        />

        <Button
          type="submit"
          className="init-button"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          {t('createAdmin')}
        </Button>

        <LanguageSwitcher i18n={i18n} />
      </form>
    </div>
  );
};
