export const RESULT_START = 'result/result-start';
export const RESULT_SUCCESS = 'result/result-success';
export const RESULT_ERROR = 'result/result-error';
export const RESULT_CLEAR = 'result/result-clear';

export const RESULT_ADD_TO_EXPORT = 'result/result-add-to-export';
export const RESULT_REMOVE_FROM_EXPORT = 'result-result-remove-from-export';

export const RESULT_CHART_START = 'result/result-chart-start';
export const RESULT_CHART_SUCCESS = 'result/result-chart-success';
export const RESULT_CHART_CLEAR = 'result/result-chart-clear';
export const RESULT_SEARCH_CONFIGURATION_START =
  'result/result-search-configuration-start';
export const RESULT_SEARCH_CONFIGURATION_SUCCESS =
  'result/result-search-configuration-success';
export const RESULT_SEARCH_CONFIGURATION_ERROR =
  'result/result-search-configuration-error';
