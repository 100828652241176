export const notificationsScrollToName = [
  'additional',
  'test',
  'result',
  'comment',
];

export const notificationScrollTo = (content) => {
  let hashType;
  notificationsScrollToName.forEach((element) => {
    if (content.key?.includes(element)) {
      hashType = element.toLowerCase();
    }
  });
  return hashType || '';
};

export const linkScrollTo = (link, content, hashType) => {
  let entityId = '';
  if (content.key?.includes('mentionedInComment')) {
    entityId = link.split('/')[4];

    link = `dashboard/project/${link.split('/')[2]}/data`;
    return `/${link}/#${entityId}`;
  } else if (content.key?.includes('mentionedInDocumentComment')) {
    entityId = link.split('/')[4];

    link = `dashboard/project/${link.split('/')[2]}/data`;
    return `/${link}/#${entityId}`;
  }
  return `/${link}/#${hashType}`;
};
