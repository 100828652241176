import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { CustomModal } from 'components/CustomModal/CustomModal';
import InfoIcon from '@material-ui/icons/Info';
import { getFieldFromType } from '../FieldTypes';
import { useForm } from 'react-hook-form';

import './DynamicForm.scss';

export const DynamicForm = (props) => {
  const {
    submitForm,
    fields,
    formData,
    confirm,
    t = (string) => 'Missing translation',
    editMode = false,
    fileExists = false,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const form = useForm({ defaultValues: (formData && formData[fields]) || {} });
  const { isDirty } = form.formState;

  const compareDirtyFieldEntries = () => {
    let dirtyFieldKeys = Object.keys(form.formState.dirtyFields);
    let modifiedFields = Array<any>();
    if (dirtyFieldKeys.length) {
      for (let fieldKey in dirtyFieldKeys) {
        if (
          form?.getValues(dirtyFieldKeys[fieldKey]) !==
          formData?.[dirtyFieldKeys[fieldKey]]
        )
          modifiedFields.push(dirtyFieldKeys[fieldKey]);
      }
      return modifiedFields.length;
    } else return false;
  };

  const onSubmit = async (data) => {
    await submitForm(data);
    confirm();
  };

  const renderFormFields = (fields) => {
    return (
      <Grid container spacing={6}>
        {Object.keys(fields).map((key, index) => {
          return getFieldFromType(
            form,
            fields[key],
            index,
            formData,
            t,
            editMode,
          );
        })}
        {props.information && (
          <Grid item xs={12} key="informaton">
            <Grid
              container
              alignItems="center"
              justify="center"
              className="information"
            >
              <Grid item xs={1}>
                <InfoIcon className="infoIcon"></InfoIcon>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h5">{props.information}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const doesFileExist = fileExists || isDirty;

  return (
    <div className="dynamic-form">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {renderFormFields(fields)}
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              className="submit-button"
              variant="contained"
              onClick={() => {
                (isDirty && compareDirtyFieldEntries()) || doesFileExist
                  ? setModalOpen(true)
                  : confirm(
                      (isDirty && compareDirtyFieldEntries()) || doesFileExist,
                    );
              }}
            >
              {t('cancel', { ns: 'common' })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="submit-button"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!doesFileExist}
            >
              {formData?._id
                ? t('saveChanges', { ns: 'common' })
                : t('create', { ns: 'common' })}
            </Button>
          </Grid>
        </Grid>
        <CustomModal
          data={{
            dialogTitle: t('cancelConfirm', { ns: 'common' }),
            dialogContentText: '',
            cancelText: t('cancelConfirmNo', { ns: 'common' }),
            confirmText: t('cancelConfirmYes', { ns: 'common' }),
          }}
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          confirm={() =>
            confirm((isDirty && compareDirtyFieldEntries()) || doesFileExist)
          }
        />
      </form>
    </div>
  );
};
