export const RECENT_NOTIFICATIONS_START =
  'recent-notifications/recent-notifications-start';
export const RECENT_NOTIFICATIONS_SUCCESS =
  'recent-notifications/recent-notifications-success';
export const RECENT_NOTIFICATIONS_ERROR =
  'recent-notifications/recent-notifications-error';

export const UNREAD_NOTIFICATIONS_START =
  'recent-notifications/unread-notifications-start';
export const UNREAD_NOTIFICATIONS_SUCCESS =
  'recent-notifications/unread-notifications-success';
export const UNREAD_NOTIFICATIONS_ERROR =
  'recent-notifications/unread-notifications-error';

export const MARK_ALL_READ_NOTIFICATIONS_START =
  'recent-notifications/mark-all-read-notifications-start';
export const MARK_ALL_READ_NOTIFICATIONS_SUCCESS =
  'recent-notifications/mark-all-read-notifications-success';
export const MARK_ALL_READ_NOTIFICATIONS_ERROR =
  'recent-notifications/mark-all-read-notifications-error';
