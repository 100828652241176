import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';

import './IntroCard.scss';

type IntroCardProps = {
  title: string;
  subtitle: string;
  children?: any;
};

export const IntroCard: React.FC<IntroCardProps> = (props) => {
  const { title, subtitle, children } = props;
  return (
    <Grid
      className="intro-card"
      justify="center"
      alignContent="center"
      alignItems="center"
      container
    >
      <Grid item
            style={{maxWidth: "960px", width: "100%"}}>
        <Paper className="intro-paper">
          <Grid container className="intro-container">
            <Grid item xs={6} lg={6} xl={6} className="intro-intro">
              <div className="intro-message">
                <Typography variant="h2" className="intro-title">
                  {title}
                </Typography>
                <div className="tagline">{subtitle}</div>
              </div>
            </Grid>
            <Grid item xs={6} lg={6} xl={6} className="content-section">
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
