import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  exportDataSearchResults,
  getDataSearchFields,
  getDataSearchResults,
} from './DataSearchActions';
import { DataSearchView } from './DataSearchView';
import {
  DATA_SEARCH_HIDE_COLUMNS,
  DATA_SEARCH_RESET,
  DATA_SEARCH_SELECT_RESULTS,
  DATA_SEARCH_SET_QUERY,
} from './DataSearchTypes';
import { getExportedFile } from '../../repository/testResult';
import { useTranslation } from "react-i18next";

export default (props) => {
  const { t } = useTranslation(['datasearch', 'common']);
  const dispatch = useDispatch();
  const dataSearch = useSelector((state: any) => state.dataSearch);
  useEffect(() => {
    dispatch({
      type: DATA_SEARCH_RESET,
    });
    dispatch(getDataSearchFields());
    return () => {
      dispatch({
        type: DATA_SEARCH_RESET,
      });
    };
  }, []);
  const searchResults = (data, pagination = { limit: 10, page: 0 }) => {
    dispatch(getDataSearchResults(data, pagination));
    dispatch({
      type: DATA_SEARCH_SET_QUERY,
      payload: data,
    });
  };

  const setSelectedResults = (results) => {
    dispatch({ type: DATA_SEARCH_SELECT_RESULTS, payload: results });
  };

  const setHiddenColumns = (columns) => {
    dispatch({ type: DATA_SEARCH_HIDE_COLUMNS, payload: columns });
  };

  const exportSearchResults = async (columns) => {
    const response = await dispatch(
      exportDataSearchResults(
        dataSearch.query,
        dataSearch.pagination || {},
        dataSearch.selectedResults,
        columns,
      ),
    );
    if (response?.s3Url) {
      await getExportedFile(response?.s3Url);
    }
  };
  return (
    <div>
      <DataSearchView
        resultSearchMethod={searchResults}
        fields={dataSearch.fields}
        data={dataSearch.results}
        query={dataSearch.query}
        loading={dataSearch.loading}
        hiddenColumns={dataSearch.hiddenColumns}
        setSelectedResults={setSelectedResults}
        setHiddenColumns={setHiddenColumns}
        selectedResults={dataSearch.selectedResults}
        exportSearchResults={exportSearchResults}
        totalNumberOfResults={dataSearch.totalNumberOfResults}
        t={t}
      />
    </div>
  );
};
