import {
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_CLEAR,
} from './ProfileTypes';
import {
  CLEAR,
  ERROR,
  LOADING,
  SUCCESS,
} from '../../_Default/Notification/NotificationTypes';
import { getProfile, editProfile } from 'repository/profile';
import { logoutUser } from '../../../services/auth';
import { profileFieldsName } from './ProfileDetailsFields';
import i18n from 'i18next';

export const fetchProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROFILE_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'profile_loading',
        },
      });

      const response: any = await getProfile();

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      if (response.data) {
        dispatch({
          type: PROFILE_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: PROFILE_ERROR,
          payload: {
            message: 'profile_userNotFound',
          },
        });
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      if (error?.response?.status === 400) {
        dispatch({
          type: PROFILE_ERROR,
          payload: {
            isOpen: true,
            message: 'profile_notFound',
            redirectToOverview: true,
          },
        });
      } else {
        dispatch({
          type: ERROR,
          payload: {
            isOpen: true,
            message,
          },
        });
      }
    }
  };
};

export const updateProfileAction = (
  data: any,
  emailChanged = false,
  file: any,
) => {
  let newData = new FormData();

  newData.append('file', file);

  ['title', 'firstName', 'lastName', 'email', 'language'].forEach((element) =>
    newData.append(element, data[element]),
  );

  newData.append(
    'notificationPreferences[frequency]',
    data.notificationPreferences.frequency,
  );

  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'profile_saving',
        },
      });
      const response = await editProfile(newData);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      if (response.data) {
        dispatch({
          type: PROFILE_SUCCESS,
          payload: response.data?.message,
        });

        dispatch({
          type: SUCCESS,
          payload: {
            isOpen: true,
            message: 'profile_updateSuccess',
          },
        });

        setTimeout(() => {
          if (response.data.message === 'OK') {
            let loggedInUser = JSON.parse(
              localStorage.getItem('account') || '{}',
            );

            profileFieldsName.forEach(
              (element) => (loggedInUser[element] = data[element]),
            );

            i18n.changeLanguage(loggedInUser.language);

            localStorage.setItem('account', JSON.stringify(loggedInUser));
            if (emailChanged) {
              logoutUser();
              window.location.href = '/login';
            }
          }
        }, 500);
      } else {
        dispatch({
          type: PROFILE_ERROR,
          payload: {
            message: 'profile_userNotFound',
          },
        });
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const clearProfile = () => {
  return {
    type: PROFILE_CLEAR,
  };
};
