import API from 'services/api';

export const getConfig = async () => {
  return API.get('/config');
};

export const getTestTypes = async () => {
  return API.get('/config/test-types');
};

export const getTestSubTypes = async () => {
  return API.get('/config/test-subtypes');
};

export const getFiles = async () => {
  return API.get('/config/files');
};
export const getFeatures = async () => {
  return API.get('/config/features');
};

export const createTestType = async (data: any) => {
  return API.post('/config/test-types', data);
};

export const createFile = async (data: any) => {
  return API.post('/config/files', data);
};

export const updateTestType = async (id: number, data: any) => {
  return API.put(`/config/test-types/${id}`, data);
};

export const updateFile = async (id: number, data: any) => {
  return API.put(`/config/files/${id}`, data);
};

export const updateFeatures = async (id: number, data: any) => {
  return API.put('/config/features', data);
};

export const getTemplatesByEntity = async (id: string) => {
  return API.get(`/config/template/entity/${id}`);
};

export const getTemplate = async (id: string) => {
  return API.get(`/config/template/${id}`);
};

export const editTemplate = async (id: number, data: any) => {
  return await API.put(`/config/template/${id}`, data);
};

export const getTemplates = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/config/template', {
    params: { limit, page, sort, name, filter },
  });
};

export const removeTemplate = async (ids) => {
  return await API.delete(`/config/template`, {
    data: {
      ids,
    },
  });
};

export const getTestsWithManualInputDevices = async () => {
  return API.get('/config/tests-manual-input-device');
};
