import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import './PageTitle.scss';

type PageTitleProps = {
  title: string;
  children?: any;
  dimensions?: any;
};

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  children,
  dimensions = { left: 6, right: 6 },
}) => {
  return (
    <Grid container className="page-title">
      <Grid item md={dimensions.left} className="page-title__text">
        <Typography variant="h2">{title}</Typography>
      </Grid>
      <Grid item md={dimensions.right} className="page-title__options">
        {children}
      </Grid>
    </Grid>
  );
};
