import {
  GET_MATERIAL,
  GET_MATERIAL_ERROR,
  MATERIAL_CLEAR,
  MATERIAL_SINGLE_SUCCESS,
  MATERIAL_UPDATE_SUCCESS,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_AUDIT,
} from './MaterialFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  data: {
    number: '',
    name: '',
    type: '',
    category: '',
    manufacturer: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MATERIAL_AUDIT:
      return { ...state, audit: action.payload };

    case GET_MATERIAL:
      return { ...state, loading: true };

    case MATERIAL_SINGLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case MATERIAL_UPDATE_SUCCESS:
      return { ...state, loading: false };

    case MATERIAL_CREATE_SUCCESS:
      return { ...state, loading: false };

    case GET_MATERIAL_ERROR:
      return { ...state, loading: false, data: null, error: action.payload };

    case MATERIAL_CLEAR:
      return { ...state, loading: false, data: null, error: null };

    default:
      return state;
  }
};
