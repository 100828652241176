import {
  LOGIN_CHANGED_LANGAUGE,
  LOGIN_START,
  LOGIN_SUCCESS,
} from './LoginTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  changedLanguage: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, loading: true, error: null };

    case LOGIN_SUCCESS:
      return { ...state, loading: false };

    case LOGIN_CHANGED_LANGAUGE:
      return { ...state, changedLanguage: true, loading: false };

    default:
      return state;
  }
};
