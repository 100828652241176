import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearMaterial,
  newMaterial,
  updateMaterial,
} from './MaterialFormActions';
import { DrawerTabs } from 'components/DrawerTabs/DrawerTabs';
import { MaterialType } from '../Material/MaterialView';

export default (props) => {
  const { t, tabValue } = props;
  const dispatch = useDispatch();
  const materialData = useSelector((state: any) => state.materialForm);
  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const formData = config?.forms?.materialForm;

  if (formData.tab1.fields.baseFields) {
    formData.tab1.fields =
      tabValue === 0
        ? config?.forms?.materialForm.tab1.fields.baseFields.filter(
            (field) => field.showInMaterialForm,
          )
        : config?.forms?.materialForm.tab1.fields.recipeFields.filter(
            (field) => field.showInMaterialForm,
          );
  }

  if (materialData.data?.usedInProgressProject) {
    const fields = formData.tab1.fields;
    for (const key in fields) {
      fields[key].disabled = true;
    }
  }

  const submitFormActions = [
    async (materialFormData) => {
      if (materialData.data && materialData.data._id) {
        await dispatch(
          updateMaterial(materialData.data._id, {
            ...materialFormData,
            type: tabValue === 0 ? MaterialType.BASE : MaterialType.RECIPE,
          }),
        );
      } else {
        await dispatch(
          newMaterial(
            {
              ...materialFormData,
              type: tabValue === 0 ? MaterialType.BASE : MaterialType.RECIPE,
            },
            () => {},
          ),
        );
      }
      props.setFilters((prevState) => {
        return { ...prevState, date: Date.now() };
      });
    },
  ];

  const clearFormData = async () => {
    await dispatch(clearMaterial());
  };

  return (
    <DrawerTabs
      formData={materialData}
      clearFormData={clearFormData}
      tabs={formData}
      submitFormActions={submitFormActions}
      open={props.open}
      setOpen={props.setOpen}
      title={
        materialData.data && materialData.data._id
          ? t('materialForm_editMaterial')
          : t('materialForm_createMaterial')
      }
      t={t}
    />
  );
};
