import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import './ComponentModal.scss';

export const ComponentModal = (props) => {
  const { isOpen, setIsOpen, onSelect, Component, selectedData = [] } = props;

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      className="component-modal"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Component onSelect={onSelect} selectedData={selectedData} />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
