import {
  DASHBOARD_START,
  DASHBOARD_SUCCESS,
  DASHBOARD_ERROR,
} from './DashboardTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DASHBOARD_START:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };

    case DASHBOARD_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case DASHBOARD_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
