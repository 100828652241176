import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import {
  Loading,
  InputError,
  InputWarning,
  LanguageSwitcher,
} from 'components';
import { emailRegex, passwordRegex } from 'services/config';

import './LoginForm.scss';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

type LoginFormProps = {
  login: any;
  loginUser: Function;
  clearErrors: Function;
  onChangeLanguage: Function;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  login,
  loginUser,
  clearErrors,
  onChangeLanguage,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref: refUsername, ...restUsername } = register('username', {
    required: true,
    pattern: emailRegex,
  });
  const { ref: refPassword, ...restPassword } = register('password', {
    required: true,
    pattern: passwordRegex,
  });
  const { ref: refTerms, ...restTerms } = register('terms', { required: true });

  const submitForm: any = ({ username, password, rememberMe }) => {
    loginUser({ username, password, rememberMe });
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const { t, i18n } = useTranslation('login');

  return (
    <Grid
      container
      className="login-form-wrap"
      justify="center"
      alignItems="center"
    >
      {login && login.loading ? <Loading /> : null}
      <Grid item xs={12}>
        <Typography variant="h2" className="title">
          {t('login')}
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(submitForm)} className="form-controls">
        <Grid item xs={12}>
          <TextField
            inputRef={refUsername}
            margin="normal"
            variant="standard"
            label={t('email')}
            inputProps={{
              ...restUsername,
              name: 'username',
            }}
            fullWidth
            helperText={
              errors.username && <InputError message={t('emailError')} />
            }
            onBlur={(e: any) => (e.target.value = e.target.value.trim())}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputRef={refPassword}
            margin="normal"
            variant="standard"
            label={t('password')}
            inputProps={{
              ...restPassword,
              name: 'password',
            }}
            fullWidth
            helperText={
              errors.password && <InputError message={t('passwordError')} />
            }
            type={passwordShown ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisiblity}
                  >
                    {passwordShown ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ReactIsCapsLockActive>
            {(active) => (
              <span>
                {active ? (
                  <InputWarning message={t('capsLockActive')} />
                ) : (
                  <p className="caps-lock-warning"> &nbsp; </p>
                )}
              </span>
            )}
          </ReactIsCapsLockActive>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            {...restTerms}
            inputRef={refTerms}
            control={
              <Checkbox
                color="secondary"
                inputProps={{
                  name: 'terms',
                }}
                required={true}
              />
            }
            label={
              <div>
                <Link to="/terms" className="visited">
                  {t('termsAndConditions')}
                </Link>
              </div>
            }
          />
        </Grid>

        <Button
          type="submit"
          className="login-button"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
        >
          {t('login')}
        </Button>
        {process.env.REACT_APP_ACTIVE_DIRECTORY && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            href={`${process.env.REACT_APP_API_URL}/auth/ad/login`}
          >
            <img
              src={'/images/Microsoft_logo.png'}
              height={'30px'}
              width={'30px'}
              style={{ marginRight: '10px' }}
            />
            {'     '}
            <span>{t('signInMicrosoft')}</span>
          </Button>
        )}
        <Grid item xs={12} className="forgot-link">
          <Link to="/forgot-password" className="visited">
            {t('forgotPassword')}
          </Link>
        </Grid>
        <LanguageSwitcher i18n={i18n} onChangeLanguage={onChangeLanguage} />
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!login.error}
        autoHideDuration={6000}
        onClose={() => {
          clearErrors();
        }}
        message={<span id="message-id">{login.error}</span>}
      />
    </Grid>
  );
};
