import React from 'react';

export const termsText = (
  <div>
    Imprint <br />
    NEDGEX GmbH
    <br />
    Gebrüder-Netzsch-Straße 19
    <br />
    95100 Selb, Germany
    <br />
    Tel.: +49 9287 75-0
    <br />
    Fax: +49 9287 75208
    <br />
    E-Mail: <a href="mailto:hello@nedgex.com">hello@nedgex.com</a>
    <br />
    www: <a href="https://www.nedgex.com">https://www.nedgex.com</a>
    <br />
    Managing Directors:
    <br />
    Moritz Netzsch, Jens Niessner, Christian Baier
    <br />
    Court of Registration: Amtsgericht Hof
    <br />
    Registration Number: HRB 6076
    <br />
    VAT Identification Number in accordance with §27a of Value Added Tax Act:
    coming soon Responsible for content pursuant to § 55 Abs. 2 RStV: Christian
    Baier
    <br />
    NEDGEX GmbH
    <br />
    Gebrüder-Netzsch-Straße 19
    <br />
    95100 Selb, Germany
    <br />
    <h3>Disclaimer</h3> As a service provider, we are responsible for the
    content of this website as stated in the general provisions (see § 7 Abs. 1
    TMG). We are under no obligation to monitor transmitted or stored
    third-party content (see §§ 8-10 TMG). Obligations to remove or block the
    use of information under general law remain unaffected. However, such
    liability is only possible from the point of awareness of a specific
    infringement. Insofar as we are informed of infringements, relevant content
    will be removed immediately.
    <br />
    The pages of this website contain links to external third-party websites. We
    have no influence over the content of such websites and can therefore assume
    no liability in this regard; nor do we adopt such content as our own. The
    respective provider or operator of the linked websites is responsible for
    the content of those websites. We checked the linked websites at the time
    they were linked and did not detect any illegal content. Continuous
    monitoring of the linked websites without specific cause is not reasonable.
    However, if we become aware of infringements, the relevant links will be
    removed immediately.
    <br />
    <h3>Copyright</h3>The content and works available on this website are
    subject to German copyright law. Please be advised that any duplication,
    modification, distribution or utilization of any nature beyond the scope of
    the copyright law requires the written consent of the respective copyright
    owner. Copyrights of third parties are respected on this website. However,
    should a copyright infringement occur, corresponding notice shall be
    requested. As soon as we become aware of infringements, all affected content
    will be deleted immediately. Advertising Caveat Finally, we hereby prohibit
    the use of contact data published in the imprint for sending unsolicited
    advertising.
    <br />
    <br />
    I. Scope
    <br />
    1. The conditions of purchase below shall be exclusively applicable to
    orders. The Supplier's terms and conditions deviating from or supplementing
    our purchase conditions shall be non-binding on the Purchaser even if the
    Purchaser fails to object or if the Supplier states that it is only willing
    to supply under its terms and conditions. Even if supplies or services are
    accepted without express objection, this shall not be deemed acknowledgement
    of, or consent to, Supplier's terms and conditions. If the Supplier is not
    in agreement with the above handling, it must immediately point this out in
    a special letter. We reserve the right to rescind the contract in such case.
    <br />
    2. Deviations from these conditions shall require the express written
    consent of the Purchaser. Confirmation or execution of the order without any
    objections shall be deemed acknowledgement of these Terms and Conditions of
    Purchase.
    <br />
    3. These Terms and Conditions of Purchase shall apply to Erich NETZSCH GmbH
    & Co. Holding KG as well as its direct and indirect German subsidiaries,
    particularly NETZSCH Pumpen & Systeme GmbH, Selb/Waldkraiburg,
    NETZSCH-Feinmahltechnik GmbH, Selb, NETZSCH-Gerätebau GmbH, Selb, NETZSCH
    Trockenmahltechnik GmbH, Selb/Hanau, NETZSCH Werbe- und Service-GmbH, Selb,
    RPV Vermögensverwaltungs-GmbH, Selb, RPV Vermögensverwaltungs-GmbH II, Selb,
    RPV Vermögensverwaltungs-GmbH III, Selb, RPV Vermögensverwaltungs-GmbH IV,
    Selb, NETZSCH Lohnmahltechnik GmbH, Selb/Bobingen, NETZSCH Vakumix GmbH,
    Selb/Weyhe, 
NETZSCH GABO Instruments GmbH, Selb/Ahlden and NEDGEX GmbH,
    Selb
    <br />
    II. Conclusion of Contract, Amendments
    <br />
    1. Enquiries and offers shall always be free of charge for and non-binding
    on the Purchaser, otherwise relevant notification must be made prior to
    preparation.
    <br />
    2. Orders shall require written form. Oral agreements shall only be binding
    if they are confirmed in writing by the Purchaser.
    <br />
    The order placed in writing shall be solely definitive relating to the scope
    and type of delivery. The Purchaser must be issued with an order
    confirmation without delay subsequent to receipt of such order.
    <br />
    3. If changes are necessary, notably in the manner of composing the
    processed material or in the execution of the design compared with former
    similar supplies and services or compared with the placed order, they must
    be notified to the Purchaser prior to commencement of fabrication or, in the
    case of amendments to be subsequently carried out, prior to their execution.
    They shall require written confirmation. The Purchaser shall not be
    obligated to examine supplies and services for similarity subsequent to
    receipt.
    <br />
    III. Delivery, Delivery Documentation and Passage of Risk
    <br />
    1. Deliveries shall be effected at the Supplier's costs, free of expenses,
    to the stipulated shipping address unless otherwise expressly agreed. Unless
    otherwise requested by the Purchaser, the Supplier must issue the forwarding
    advice, delivery note and the invoices in duplicate and – if this forms part
    of the delivery – submit any acceptance test certificates for materials and
    send them separately from the goods on the day of despatch. The forwarding
    advice, delivery note and invoice must contain our reference number, the
    departmental comment and the item code/material number. If this information
    is missing, the Purchaser shall not be responsible for delays in processing
    and settlement.
    <br />
    2. The risk shall only pass to the Purchaser at acceptance at the shipping
    address.
    <br />
    IV. Delivery Dates, Delay in Delivery
    <br />
    1. The agreed delivery dates shall be binding. Receipt of the goods at the
    stipulated shipping address shall be definitive for determining whether or
    not the delivery date has been observed. Information on delivery times
    without a delivery date shall not be expedient for the Purchaser because
    they fail to constitute a scheduling basis. The Purchaser shall therefore
    request information on the exact delivery date.
    <br />
    2. The delivery period shall only be reasonably extended if the Supplier is
    obstructed in the performance of its obligations due to force majeure. As
    soon as the Supplier can see that it will be unable to comply with the
    agreed delivery period or the agreed delivery date, it must inform the
    Purchaser in writing without delay of the grounds and the anticipated
    duration of the delay. If the Supplier is in breach of its duty to furnish
    information, it must compensate the Purchaser for the resulting losses
    incurred. In all other respects, the Purchaser shall reserve all rights
    concerning the delay.
    <br />
    3. In the event of a delay in delivery, the Purchaser may rescind the
    contract after fruitless lapse of an additional period. There shall be no
    requirement to set a deadline if the Supplier seriously and conclusively
    refuses performance, if compliance with the delivery period is material for
    the Purchaser (business to be settled on a fixed date) or if - weighing up
    mutual interests - other circumstances are shown to exist that justify
    immediate rescission. In addition, the Purchaser may request compensation if
    the Supplier fails to provide verification to the former that it is not
    responsible for overrun of the delivery period. The Purchaser may keep
    partial deliveries and in all other respects rescind the contract. No waiver
    of possible compensation claims shall be deemed in the assumption of delayed
    delivery or performance.
    <br />
    V. Fixed Compensation
    <br />
    In the event of delay on the part of the Purchaser, for each week of
    too-late delivery, the Purchaser shall be entitled to lump-sum compensation
    in the amount of 1 per cent of the purchase price, at the maximum 5 per cent
    of the total price. The Supplier shall be free to prove that no losses were
    incurred or not incurred in that amount. Further-reaching claims of the
    Purchaser on grounds of the delay in delivery shall remain unaffected, in
    particular, the assertion of a compensation claim shall not be ruled out by
    the lump-sum compensation if the compensation claims exceeds the amount of
    the lump-sum compensation. The lump-sum compensation will be set off against
    a possible compensation claim.
    <br />
    The Purchaser may then also request the lump-sum compensation if it fails to
    expressly reserve the right to it when accepting the goods.
    <br />
    VI. Title 1. The Supplier shall be obligated to transfer title in the
    contractual goods to the Purchaser at handover, at the latest upon payment
    of the relevant delivery. Any extension or enlargement of possible
    reservation of title declared by the Supplier shall be contrary to the
    contract and shall obligate the Supplier to compensation. 2. The Purchaser
    shall always exclusively undertake to finish and process the delivered goods
    for itself, if applicable, also in the event of a reservation of title on
    the part of the Supplier. VII. Prices and Terms of Payment 1. The prices
    shall be binding, delivered at our premises and shall include packaging,
    transport and insurance costs. Payment deadlines at the Purchaser's choice -
    within 30 days after receipt of invoice with 3 per cent cash discount,
    within 60 days after receipt of invoice with 2 per cent cash discount or net
    after 90 days. 2. Payments shall be effected at the Purchaser's choice by
    sending a crossed cheque or by transfer to Supplier's bank or postal
    account. The post office or bank outgoing stamp shall be definitive for
    timely payment. 3. As soon as and if a claim of Supplier's is juxtaposed
    against a due counterclaim of Purchaser's, the Purchaser may carry out set
    off, taking account of the cash discounts pursuant to Clause 1. Such set-off
    shall bring about termination of the mutual claims in the amount of the
    set-off and, if applicable, of the cash amount. 4. The Purchaser shall be
    entitled to rights of retention in the statutory amount. VIII. Warranty 1.
    In accordance with the customary practice, the Purchaser shall examine the
    incoming goods for defects. If a defect is revealed during such examination
    or if the goods are not in accordance with the ordered goods, the Purchaser
    will notify thereof within two weeks of acceptance. To protect its rights,
    it shall suffice if the Purchaser has sent the notification of defects
    within this deadline.
    <br />
    If a defect is revealed later, e.g. during processing or usage, the
    Purchaser shall notify thereof within a period of two weeks from discovery.
    To protect its rights, it shall suffice if the Purchaser has sent the
    notification of defects to the Supplier within this deadline.
    <br />
    2. Claims due to defects shall become statute-barred three years after
    delivery unless a longer limitation period is applicable by virtue of law.
    If the goods have not been put in use by the Purchaser within the aforesaid
    warranty period, the Purchaser may still exercise its warranty rights within
    two months after having been put in use.
    <br />
    3. If the products supplied are defective, the Purchaser may demand
    subsequent performance or substitute delivery at its choice. If the Supplier
    fails to comply with the reasonable deadline he has been set or if
    subsequent remedy has failed or is unreasonable, the Purchaser shall be
    entitled to replace defective parts at Supplier's costs, remedy them,
    rescind the contract or reduce the purchase price. The Purchaser's claims
    for compensation and under the German Product Liability Act shall remain
    unaffected.
    <br />
    4. During subsequent performance carried out by the Supplier or during
    subsequent remedy carried out by the Purchaser pursuant to (3) second
    sentence, lapse of the warranty period shall be retarded.
    <br />
    <br />
    IX. Substance Restrictions
    <br />
    The Supplier shall ensure that the goods delivered meet the requirements of
    Regulation (EC) No. 1907/2006 (REACH) and of all other national and EU-wide
    regulations with regard to prohibitions and restrictions on chemical
    substances. The Supplier shall also ensure that the goods delivered do not
    contain any substances included in the then-current version of the candidate
    list issued by the European Chemicals Agency (ECHA) – known as “substances
    of very high concern” (SVHC) – in concentrations of over 0.1 mass percent.
    Without being requested to do so the supplier shall provide the purchaser
    with safety data sheets by attaching them to the relevant quotations. The
    purchaser must be immediately informed on any transgressions of substance
    restrictions and delivery of prohibited substances. Additionally, the
    provisions of the EU Directive 2011/65/EC (RoHS Directive) relating to the
    restriction of certain hazardous substances in electrical and electronic
    products and components shall be observed.
    <br />
    <br />
    X. Assignment
    <br />
    Claims of the Supplier against the Purchaser may only be assigned with the
    consent of the Purchaser.
    <br />
    <br />
    XI. Liability
    <br />
    1. In cases of breach of contractual, statutory or regulatory provisions,
    the Supplier shall be responsible for intent and any kind of negligence,
    also in terms of its vicarious agents.
    <br />
    2. If the Supplier is responsible for product damage, it shall be obliged to
    release the Purchaser from compensation claims of third parties to the
    extent that the cause is in the Supplier's sphere of control and
    organisation and that it, itself, is liable vis-à-vis third parties.
    <br />
    <br />
    XII. Industrial Property Rights
    <br />
    The Supplier shall be liable relating to non-infringement of industrial
    property rights through shipment and use of the purchased goods. If this
    obligation is breached, the Purchaser shall be entitled to the statutory
    warranty rights. If the Purchaser asserts claims for compensation, the
    Supplier shall be obligated towards the Purchaser, notably to indemnify the
    latter from claims of third parties unless it proves that it is not
    responsible for the breach of duty.
    <br />
    <br />
    XIII. Delivery in Accordance with Information, Drawings and Models of the
    Purchaser
    <br />
    1. If the ordered goods are manufactured in accordance with information,
    drawings or models provided by the Purchaser, the following shall be
    applicable:
    <br />
    The goods ordered by the Purchaser and the special facilities, matrices and
    the like used for their manufacture may only be used for the processing of
    our order and may not be delivered to third parties. This shall also be
    applicable if the Supplier has procured the special facilities, matrices and
    the like at its own costs or if the Purchaser has refused acceptance of the
    goods due to delayed or defective delivery or if the Purchaser refrains from
    further orders despite proper delivery. If improvements at the Supplier's
    originate in connection with execution of the orders, the Purchaser shall
    have a costless, non-exclusive right of use for commercial use of the object
    of improvement and any relevant property rights (Clause 1 shall also be
    applicable in this case). The right of disposition relating to
    contract-linked production facilities and tooling, notably concerning joint
    use, change or destruction, shall remain exclusively with the Purchaser.
    Models, patterns, drawings or technical documentation of any kind shall
    remain the property of the Purchaser and must be kept secret. They may only
    be used for the processing of our order and by wish of the Purchaser must be
    returned together with any copies made.
    <br />
    2. Material provided by the Purchaser shall remain the property of the
    Purchaser.
    <br />
    <br />
    XIV. Place of Performance, Place of Jurisdiction, Applicable Law
    <br />
    1. The place of performance for all deliveries and services shall be at our
    registered seat, unless a different place to which the goods are to be
    delivered is agreed.
    <br />
    2. The court at our registered seat shall be solely competent for all
    disputes arising from and in connection with this contract. However, we
    shall also be entitled to file claim at any other legal place of
    jurisdiction.
    <br />
    3. The law of the Federal Republic of Germany shall be applicable. The
    application of the United Nations Convention on Contracts for the
    International Sale of Goods (CISG) shall be ruled out.
    <br />
    XV. Miscellaneous
    <br />
    1. If one or more than one provision of these Terms and Conditions of
    Purchase should be or become invalid, this shall not affect the validity of
    the other provisions. The same shall also apply in the case of a regulatory
    gap.
    <br />
    2. These Terms and Conditions of Purchase in their German version shall be
    definitive unless a different language is the contractual language. This
    shall also be applicable if a translation of the Terms and Conditions of
    Purchase into a different language was used in addition to the
    German-language conditions.
    <br />
    3. Unless other terms have been set via specific contract, the 
 supplier’s
    goods and/or services – presuming an order has been placed – are subject to
    the regulations set forth in the most current version of the relevant legal
    stipulations, in particular those of the German “Kreislaufwirtschaftsgesetz
    (KrWG)” recycling 
 management and waste law, as well as its subordinate 

    regulations, such as regional and local waste disposal laws.
    <br />
    <br />
    Effective as of July 24, 2019
    <h2>Privacy Policy</h2>
    Thank you for visiting our website and your interest in our services. The
    protection of your privacy is very important to us. We place great emphasis
    on the protection of your personal data and your right to informational
    self-determination. We collect, process and use personal data solely in
    compliance with the General Data Protection Regulation (hereinafter referred
    to as "GDPR") and the applicable country-specific data protection
    regulations. Below we will inform you, as the data subject, as well as the
    public about the nature, scope and purpose of the personal data we collect,
    use and process. In addition, your legal rights will be explained to you, as
    the data subject, by means of this privacy statement. If you are not in
    agreement with these regulations, we ask that you do not access or use this
    website in any other way. Should you have questions or other concerns,
    please do not hesitate to contact us. Contact information can be found under
    point II.
    <br />
    I. Responsible Entities
    <br />
    The responsible entity according to the General Data Protection Regulation
    and other applicable data protection regulations is:
    <br />
    NEDGEX GmbH
Gebrüder-Netzsch-Straße 19
95100 Selb, Germany
Tel.: +49 9287
    75-0
Fax: +49 9287
    75208
E-Mail: hello‎@‎nedgex.com
Website: https://www.nedgex.com
    <br />
    II. Processing of Personal Data
    <br />
    1. General Information on Visiting our Website
    <br />
    When you visit our website for information purposes only, it is basically
    not required to provide personal data. In this case, we collect and use only
    the data that your internet browser automatically transmits to us, such as
    your browser type (including version) and the browser settings, the
    operating system used by the accessing system, date and time of your visit
    to our website, the name of your internet service provider, your IP address
    and the website from which you visited us. We draw no conclusions about the
    data subject when collecting and processing these data. The data are stored
    separately from all other personal data provided by a data subject.
    Collection and processing are carried out solely to enable the use of the
    websites you have accessed, for statistical purposes and to improve our
    website.
    <br />
    2. Cookies
    <br />
    To make our websites more attractive and to enable the use of certain
    functions, we use so-called cookies on various pages. These are text files
    which are stored on your device. Cookies help us determine the frequency of
    use and the number of users of our websites, and to make our offerings as
    convenient and efficient for you as possible. On the one hand, we use
    so-called "session cookies," which are cached only during the time you are
    using one of our websites. On the other hand, we use "permanent cookies"
    (including "flash cookies"), to capture information about computers that
    repeatedly access our websites. In this way, we can offer an optimal user
    interface and also "recognize" you when you return so that we can present
    maximum variety and new content. In your browser under Extras/Internet
    Options, you can disable cookies, limit them to certain websites or set your
    browser to notify you when a cookie is sent. You also have the option to
    remove existing cookies with your browser. More detailed information about
    these functions can be found in the documentation or in your browser's help
    file. You can remove Flash cookies using the settings in your Adobe Flash
    Player. Further information on this can be found
    under http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager06.html.
    Please note that, with cookies disabled, a limited display of our web pages
    and a limited user interface are to be expected.
    <br />
    3. Other Tracking
    <br />
    Google Analytics
    <br />
    Our websites also use Google Analytics, a web analytics service provided by
    Google, Inc. ("Google"). Google Analytics uses so-called "cookies," which
    are text files placed on your computer to enable the website analyze how
    visitors use the site. The information generated by the cookie about your
    use of our websites will generally be transmitted to a Google server in the
    United States and stored there. However, because IP anonymization has been
    activated on our websites, within the Member States of the European Union or
    other states that are party to the Agreement on the European Economic Area,
    your IP address will first be truncated. Only in exceptional cases will the
    full IP address be sent to a Google server in the US and shortened there.
    Google will use this information on our behalf for the purpose of evaluating
    your use of our websites, compiling reports on website activity and
    providing us with other services related to website and internet usage. The
    IP address transmitted from your browser as part of Google Analytics will
    not be merged with other Google data. You can prevent the storage of cookies
    using the appropriate settings in your browser; however, please note that by
    doing so, you may lose some of the functionality of the websites. In
    addition, you can prevent the collection and processing by Google of the
    data generated by the cookie and related to your use of our websites
    (including your IP address) by downloading and installing the browser
    plug-in available under: tools.google.com/dlpage/gaoptout Additional
    information can be found
    at tools.google.com/dlpage/gaoptout or www.google.com/intl/en/analytics/privacyoverview.html (general
    information on Google Analytics and data protection). Please note that our
    websites use Google Analytics with the extension "gat._anonymizeIp();" to
    ensure anonymized collection of IP addresses (so-called IP masking).
    Wiredminds
    <br />
    Our website uses a counting pixel technology provided by wiredminds GmbH
    (www.wiredminds.de) to analyze visitor behavior. If necessary, data is
    collected, processed and stored, from which user profiles are created under
    a pseudonym. Wherever possible and reasonable, these usage profiles are
    completely anonymized. Cookies can be used for this purpose. Cookies are
    small text files that are stored in the visitor's Internet browser and serve
    to recognize the Internet browser. The collected data, which may also
    contain personal data, will be transmitted to wiredminds or collected
    directly by wiredminds. wiredminds may use information that is left by
    visiting the websites to create anonymized usage profiles. The data obtained
    without explicit consent of the affected person will not be used to
    personally identify the visitor of this website and will not be merged with
    personal data of the bearer of the pseudonym. Whenever IP addresses are
    recorded, their immediate anonymization takes place by deleting the last
    number block.
Exclude from tracking.
    <br />
    4. Use of other Services Offered
    <br />
    Additional data are collected and processed when visiting these websites
    only if you provide these data yourself in connection with the use of
    services offered separately on our websites. Please note that, in this
    context, there is fundamentally no legal or contractual obligation to
    provide your personal data. However, if you choose not to provide personal
    data, the use of our services may be restricted or excluded. Contact via our
    Website
    <br />
    Due to legal requirements, our website contains information that enables
    quick electronic contact with our company as well as direct communication
    with us, which also includes a general, so-called electronic mail (e-mail)
    address. If a data subject contacts the data controller by e-mail or through
    a contact form, the personal data provided by the data subject will be saved
    automatically. Such personal data transmitted voluntarily by a data subject
    to the controller will be stored for purposes of processing or for
    contacting the data subject.
    <br />
    III. Processing Purposes and Legal Basis as well as Legitimate Interest
    <br />
    When personalized services are utilized, we process the personal data you
    share with us for the purpose of providing the service you have requested.
    We use the personal data collected in connection with the communication
    initiated via our contact form solely for purposes of processing or for
    contacting the data subject. Legal basis for this is the legitimate interest
    (see Art. 6 Para. 1 lit. f GDPR).
    <br />
    IV. Storage Duration and Routine Deletion
    <br />
    The legislature has issued a variety of retention requirements and retention
    periods. Typically, these are also the basis for the storage period for
    personal data. Upon expiry of these periods, the corresponding data are
    routinely deleted or their processing is restricted. If data are not
    affected, they will be deleted as soon as the respective uses no longer
    apply. We save the personal data that you provide us within the context of
    the contact, subject to further processing purposes (for example, inquiry
    via the contact form) only until such time that your inquiry has been
    processed or pursuant to the in-house deletion period.
    <br />
    V. Rights of Data Subjects
    <br />
    <br />
    According to the General Data Protection Regulation, you, as the data
    subject, have various rights in connection with the collection and
    processing of your personal data, which we would like to explain below.
    Fundamentally, these rights can be asserted by the data subject at any time.
    To assert these rights, and for questions in this regard, we ask that the
    data subject contact our person in charge (see point II). He will be happy
    to answer your questions and, in individual cases, take necessary measures
    to implement the rights asserted by the data subject without delay.
    <br />
    Right to Confirmation and Access
    <br />
    Every data subject has the right to request from the controller a
    confirmation of whether personal data about him have been processed. If that
    is the case, the data subject also has the right to receive, free of charge,
    information about his personal data that has been stored as well as a copy
    of that information.
    <br />
    Right to Rectification
    <br />
    Every data subject has the right to request immediate rectification of
    personal data about him that is incorrect. Further, he has the right to
    request the completion of incomplete personal data — including by means of a
    supplementary statement — taking into account the purposes of the
    processing.
    <br />
    Right to Deletion
    <br />
    Every data subject has the right to request that the controller immediately
    remove personal data about him, if any of the following applies and if
    processing is not required:
    <br />
    The personal data were collected or otherwise processed for purposes for
    which they are no longer necessary.
    <br />
    The data subject lodges an objection to the processing and there are no
    legitimate grounds for the processing.
    <br />
    The personal data were unlawfully processed.
    <br />
    The person responsible is otherwise required by law to delete.
    <br />
    The personal data are those collected from children as part of information
    society services.
    <br />
    Insofar as we, the controller, have made the personal data public and are
    required to delete them, we will take the appropriate steps, under
    consideration of the available technology and the costs of implementation,
    to inform other controllers who process the published personal data that the
    data subject has requested deletion. In addition, we will inform these other
    controllers that the data subject has requested the deletion of all links to
    these personal data, along with any copies or replications, to the extent
    that processing is not required.
    <br />
    Right to Restriction/Blocking of Processing
    <br />
    Every data subject has the right to request that the controller restrict the
    processing if one of the following conditions is met:
    <br />
    The accuracy of the personal is contested by the data subject, for a period
    of time that allows the controller to verify the accuracy of the personal
    data.
    <br />
    The processing is unlawful; the data subject rejects deletion of the
    personal data and demands instead that use of the personal data be
    restricted.
    <br />
    The controller no longer requires the personal data for processing purposes,
    but the data subject requires them to assert, exercise or defend legal
    claims.
    <br />
    The data subject has lodged an objection and it has not yet been determined
    whether the legitimate grounds of the controller outweigh those of the data
    subject.
    <br />
    Right to Data Portability
    <br />
    In principle, every data subject has the right to receive the personal data
    he has made available to a controller in a structured, common and
    machine-readable format. He also has the right to transmit these data to
    another controller without obstruction by the controller to whom the
    personal data were originally provided, insofar as the processing is based
    on consent or a contract and the processing takes place using an automated
    process.
    <br />
    In addition, the data subject has the right to have the personal data
    transmitted directly from one controller to another, provided that it is
    technically possible and does not compromise the rights and freedoms of
    others.
    <br />
    Right to Object
    <br />
    Every data subject has the right, for reasons that arise from his particular
    situation, to object to the processing of his personal data, insofar as the
    processing is carried out to safeguard the legitimate interests of the
    controller or third parties.
    <br />
    In the case of an objection, we will no longer process the personal data,
    unless we can demonstrate compelling legitimate grounds for processing that
    outweigh the interests, rights and freedoms of the data subject, or the
    processing serves to assert, exercise or defend legal claims.
    <br />
    If personal data are processed for the purpose of direct advertising, the
    data subject has the right to object at any time to the processing of his
    personal data for the purpose of such advertising. If the data subject
    objects to processing for direct advertising purposes, the personal data
    will no longer be processed for these purposes.
    <br />
    Right to Appeal
    <br />
    Independent of the above stated rights and independent of other remedies,
    every data subject has the right to appeal to a supervisory authority, if he
    believes that the processing of his personal data violates the data
    protection regulations.
    <br />
    VI. Data Security
    <br />
    Please note that data transmission via the internet is essentially
    unsecured. There is a possibility that transmitted data can be read and
    perhaps even falsified by unauthorized persons. For secure communication, we
    offer, by default, encrypted communication over the SSL protocol, which we
    use to transfer your personal data. In addition, we employ current technical
    and organizational measures to secure our website and other systems against
    loss, destruction, access, modification or dissemination of your data by
    unauthorized persons. These are always consistent with the current state of
    the art.
    <br />
    VII. Transmission to a Third Country or an International Organization
    <br /> A transfer of data to offices in countries outside the European Union
    or the European Economic Area (so-called third countries) shall not take
    place.
  </div>
);
