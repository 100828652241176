import React, { useState } from 'react';
import { Table } from '../../components/Table/Table';
import { Button, Grid } from '@material-ui/core';
import { PageTitle } from '../../components';
import { format, isValid } from 'date-fns';
import { getAllNotifications } from '../../repository/recentNotifications';
import { notificationsTableColumn } from './NotificationsTableColumn';
import {
  linkScrollTo,
  notificationScrollTo,
} from '../../components/RecentNotification/RecentNotificationsHelper';
import { CustomModal } from '../../components/CustomModal/CustomModal';
import { de, enGB, enUS } from 'date-fns/locale';

export const NotificationCentreView = ({
  filters,
  unreadNotifications,
  markAllAsRead,
  t,
  i18n,
}) => {
  const [readAllModal, setReadAllModal] = useState(false);
  notificationsTableColumn.forEach((field) => {
    field.label = t(field.label);
  });
  return (
    <Grid item xs={12}>
      <PageTitle title={t('notificationCentre')} />
      <Grid container justify={'flex-end'}>
        <Button
          color={'primary'}
          style={{ marginBottom: '30px' }}
          variant={'contained'}
          disabled={!unreadNotifications.length}
          onClick={() => setReadAllModal(true)}
        >
          {t('markAllRead')}
        </Button>
      </Grid>
      <Grid container>
        <Table
          columnNames={notificationsTableColumn}
          title={t('notifications')}
          processData={(data) => {
            return data.map((dataEntry) => {
              const { link, content, read, createdAt } = dataEntry;

              const hashType = notificationScrollTo(content);
              const linkScrollToComment = linkScrollTo(link, content, hashType);
              dataEntry.type = t(`type${dataEntry.type}`);
              return {
                ...dataEntry,
                content: t(content.key, { variable: content.variable }),
                pageToRedirectTo: {
                  linkScrollToComment,
                  read,
                },
                createdAt: (
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    spacing={1}
                  >
                    <Grid item>
                      {isValid(new Date(createdAt)) &&
                        format(new Date(createdAt), 'dd MMMM yyyy H:mm:ss', {
                          locale: i18n.language === 'en' ? enGB : de,
                        })}
                    </Grid>
                  </Grid>
                ),
              };
            });
          }}
          filtersPassed={filters}
          fetchDataMethod={getAllNotifications}
          searchEnabled={false}
          notifications={true}
          type="notification-centre"
        />
      </Grid>
      <CustomModal
        setIsOpen={setReadAllModal}
        isOpen={readAllModal}
        confirm={() => {
          markAllAsRead();
        }}
        data={{
          dialogTitle: t('readAll'),
          dialogContentText: t('readAllConfirm'),
          cancelText: t('readAllConfirmNo'),
          confirmText: t('readAllConfirmYes'),
        }}
      />
    </Grid>
  );
};
