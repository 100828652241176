import React from 'react';
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import './TimeRangePicker.scss';
const TimeRangeFormField = ({ form, field, formData, point }) => {
  return (
    <Controller
      render={({ field: { onChange, value } }) => {
        return (
          <TimeField
            value={
              formData && formData[`${field.fieldName}.value.${point}`]
                ? moment
                    .utc(formData[`${field.fieldName}.value.${point}`])
                    .format('HH:mm')
                : '00:00'
            }
            onChange={(e: any) => {
              if (e) {
                const startTime = moment.utc('1970-01-01 ' + e.target.value);
                e = startTime.toDate();
              }
              form.setValue(`${field.fieldName}.value.${point}`, e);
            }}
            input={
              <TextField
                label={`${field.label} - ${point}`}
                fullWidth
                disabled={field.disabled}
                value={
                  formData && formData[`${field.fieldName}.value.${point}`]
                    ? moment.utc(formData[field.fieldName]).format('HH:mm')
                    : '00:00'
                }
                className="dataSearchTimeFieldInput"
              />
            }
          />
        );
      }}
      name={`${field.fieldName}.value.${point}`}
      control={form.control}
      rules={{
        required: field.required,
      }}
      defaultValue={
        form.getValues(`${field.fieldName}.value.${point}`) ||
        (formData && formData[`${field.fieldName}.value.${point}`]) ||
        field.defaultDate ||
        null
      }
    />
  );
};

const TimeRangePickerField = (props) => {
  const { form, field, formData } = props;
  return (
    <Grid
      item
      container
      key={`${field.label}-form-field`}
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6}>
        <TimeRangeFormField
          form={form}
          field={field}
          formData={formData}
          point={'from'}
        />
      </Grid>
      <Grid item xs={6}>
        <TimeRangeFormField
          form={form}
          field={field}
          formData={formData}
          point={'to'}
        />
      </Grid>
    </Grid>
  );
};

export default TimeRangePickerField;
