import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchDashboardData } from './DashboardActions';

import { Grid, Typography } from '@material-ui/core';
import { DashboardWidget } from './components/DashboardWidget/DashboardWidget';
import { Edit, Timeline } from '@material-ui/icons';

import './Dashboard.scss';
import { PageTitle } from '../../components';
import { transformVisibleTableFields } from 'utils/transform-helpers';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation(['dashboard', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();
  const stateData = useSelector((state: any) => state.dashboard.data);

  const config = JSON.parse(localStorage.getItem('config') || '{}');

  const myProjectsTableColumns = transformVisibleTableFields(
    config?.forms?.projectForm?.fields
      .filter((field) => field.fieldName !== 'projectId')
      .map((field) => ({ ...field, label: t(`project:${field.fieldName}`) })),
    'showInDashboardTable',
  );
  const CurrentDashboardWidgets = [
    {
      title: t('myProjects'),
      field: 'myProjects',
      type: 'table',
      redirect: '/dashboard/project',
      show: true,
      tableColumns: [
        {
          type: 'label',
          label: t('project:projectId'),
          field: 'projectId',
        },
        ...(myProjectsTableColumns || []),
        {
          type: 'icons',
          icons: [
            {
              icon: <Edit />,
              callbackMethod: (project) =>
                history.push(`/dashboard/project/${project._id}`),
              show: true,
              placeholder: t('projectView'),
            },
            {
              icon: <Timeline />,
              callbackMethod: (project) =>
                history.push(`/dashboard/project/${project._id}/data`),
              show: true,
              placeholder: t('data'),
            },
          ],
        },
      ],
      processData: (data) => {
        return data.map((row) => {
          return {
            ...row,
            startDate: `${row.startDate &&
              new Intl.DateTimeFormat('en-GB').format(
                new Date(row.startDate),
              )}`,
            dueDate: `${row.dueDate &&
              new Intl.DateTimeFormat('en-GB').format(new Date(row.dueDate))}`,
          };
        });
      },
    },
    {
      title: t('workload'),
      type: 'chart',
      field: 'workload',
      show: config.features?.workload?.enabled,
    },
  ];

  const getDashboardData = () => {
    dispatch(fetchDashboardData());
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <>
      <PageTitle title={t('dashboard')} />
      <Grid container spacing={2}>
        {CurrentDashboardWidgets.map((data, index) => (
          <Grid item xs={6} key={`dashboard-widget-${index}`}>
            {data.show ? (
              <DashboardWidget config={data} data={stateData} t={t} />
            ) : null}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
