import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Hidden } from '@material-ui/core';
import { Arrow } from 'components';
import { logoutUser } from 'services/auth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ProfileIcon from '@material-ui/icons/Person';

import './UserProfile.scss';
import { useTranslation } from 'react-i18next';

export const UserProfile = () => {
  const account = useSelector((state: any) => state.account);
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef();

  const { t } = useTranslation('header');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current !== undefined &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  let name = 'John Doe';
  let role = '';

  if (account?.user) {
    name = account.user.email;

    if (account.user.name) {
      name = account.user.name;
    }
  }

  if (account?.user?.role) {
    role = account.user.role.name;
  }

  return (
    <>
      <Button className="btn-profile" ref={anchorRef} onClick={handleToggle}>
        <span className="profile-wrap">
          <div className="profile-name">{name}</div>
          <Hidden xsDown>
            <div className="profile-type">{role}</div>
          </Hidden>
        </span>
        <span className="arrow">
          <Arrow color="#212121" />
        </span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className="user-dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom-end',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={handleClose}>
                    <Link to="/dashboard/profile">
                      {t('profile')}
                      <ProfileIcon className="menu-icon" />
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/" onClick={logoutUser}>
                      {t('logout')}
                      <ExitToAppIcon className="menu-icon" />
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
