import {
  COMPANY_START,
  COMPANY_SUCCESS,
  COMPANY_ERROR,
  COMPANY_UPDATE_RESULTS,
} from './CompanyOverviewTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    docs: [],
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case COMPANY_START:
      return {
        ...state,
        data: {
          docs: [],
        },
        loading: true,
        error: null,
      };

    case COMPANY_SUCCESS:
      return { ...state, data: action.payload, loading: false };

    case COMPANY_ERROR:
      return { ...state, loading: false, error: action.payload };

    case COMPANY_UPDATE_RESULTS:
      return { ...state, loading: false };

    default:
      return state;
  }
};
