import React, { useEffect } from 'react';
import { HighlightOff } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

export const ActiveDirectoryFail = ({ history }) => {
  useEffect(() => {
    setTimeout(() => {
      history.push('/login');
    }, 2000);
  }, []);
  return (
    <Grid
      item
      container
      direction={'column'}
      justify={'center'}
      alignItems={'center'}
      style={{ marginTop: '300px' }}
    >
      Authentication unsuccessful. Redirecting you to the login screen...
      <Grid>
        <HighlightOff color={'secondary'} fontSize={'large'} />
      </Grid>
    </Grid>
  );
};
