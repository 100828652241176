import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem,
  IconButton,
  Popper,
  Grow,
  Paper,
  MenuList,
  Badge,
  ClickAwayListener,
  Grid,
  Divider,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  fetchRecentNotifications,
  fetchUnreadNotifications,
  updateRecentNotification,
} from './RecentNotificationsActions';
import {
  linkScrollTo,
  notificationScrollTo,
} from './RecentNotificationsHelper';

import './RecentNotifications.scss';
import { useTranslation } from 'react-i18next';

enum NotificationStatus {
  read = '#fff',
  unread = '#E2F0FE',
}

const RecentNotifications = () => {
  const { t } = useTranslation(['notifications', 'common']);
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.recentNotification);
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchRecentNotifications({ _limit: 5 }));
    dispatch(fetchUnreadNotifications());
    setOpen(false);
  }, [location]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleHover = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current !== undefined &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const handleRecentNotification = (id) => (e) => {
    dispatch(updateRecentNotification(id));
  };

  return (
    <Grid
      item
      onClick={handleToggle}
      onMouseOver={handleHover}
      onMouseLeave={handleClose}
    >
      <IconButton className="btn-notification" ref={anchorRef}>
        <Badge
          badgeContent={notifications.unreadNotifications.length}
          color="error"
        >
          <span className="notification-wrap">
            <div className="notification-name">
              <NotificationsIcon color="primary" />
            </div>
          </span>
        </Badge>
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className="notification-dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom-end',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {notifications.data.map(
                    ({ _id, type, content, read, link }) => {
                      let hashType = notificationScrollTo(content);
                      const linkScrollToComment = linkScrollTo(
                        link,
                        content,
                        hashType,
                      );

                      return (
                        <>
                          <MenuItem
                            key={_id}
                            style={{
                              backgroundColor: read
                                ? NotificationStatus.read
                                : NotificationStatus.unread,
                            }}
                            onClick={handleRecentNotification(_id)}
                          >
                            <Link
                              to={`${linkScrollToComment}`}
                              onClick={handleClose}
                            >
                              <h3
                                style={{
                                  fontWeight: 'bold',
                                  lineHeight: '4px',
                                }}
                              >
                                {t(`type${type}`)} {t('page')}
                              </h3>
                              <p>
                                {t(content.key, { variable: content.variable })}
                              </p>
                            </Link>
                          </MenuItem>
                          <Divider />
                        </>
                      );
                    },
                  )}
                  {!notifications.data.length && (
                    <h4 style={{ display: 'flex', justifyContent: 'center' }}>
                      {t('noNotifications')}
                    </h4>
                  )}
                  <Divider light />
                  <MenuItem className="notification-dropdown__see-all">
                    <Link
                      to="/dashboard/notification-centre"
                      onClick={handleClose}
                      style={{ color: '#fff' }}
                    >
                      <h3>{t('seeAll')}</h3>
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};
export default RecentNotifications;
