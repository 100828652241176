import React from 'react';

import './Arrow.scss';

type ArrowProps = {
  color?: string;
  direction?: string;
};

export const Arrow: React.FC<ArrowProps> = (props) => {
  const { color = '#FFF', direction = 'down' } = props;

  return (
    <svg
      className={`arrow-direction-${direction}`}
      width="20px"
      height="11px"
      viewBox="0 0 20 11"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <desc>Created with Sketch.</desc>
      <g
        id="Screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Order-details" transform="translate(-1860.000000, -46.000000)">
          <g id="user" transform="translate(1650.000000, 36.000000)">
            <g
              id="icon/arrow-down\"
              transform="translate(207.000000, 3.000000)"
            >
              <polygon id="Path" points="24 24 0 24 0 0 24 0"></polygon>
              <polygon
                id="Path"
                fill={color}
                fillRule="nonzero"
                transform="translate(12.823879, 12.367500) scale(1, -1) rotate(-90.000000) translate(-12.823879, -12.367500) "
                points="8.45637859 22.1006769 18.1913786 12.3786214 8.45637859 2.6343231 7.45637859 3.81401964 15.8564872 12.3786214 7.45637859 21.0400081"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
