import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { InputError } from '../../InputError/InputError';
import { getInputTypeFromField } from '../../../utils/input-helpers';
import './NumberRange.scss';

const NumberRange = (props) => {
  const { form, field: fieldData, formData } = props;

  return (
    <Grid
      item
      container
      xs={fieldData.span}
      spacing={2}
      className={'range-fields'}
      key={`${fieldData.label}-form-field`}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          const { ref, ...rest } = form.register(
            `${fieldData.fieldName}.value.from`,
            {
              required: fieldData.required,
              minLength: {
                value: fieldData.minLength,
                message: `Minimum ${fieldData.minLength} characters required`,
              },
              maxLength: fieldData.maxLength,
              pattern: {
                value: fieldData.pattern,
                message: `Please enter a valid ${fieldData.label.toLowerCase()}`,
              },
            },
          );
          return (
            <Grid item xs={6}>
              <TextField
                inputProps={{
                  ...rest,
                  name: `${fieldData.fieldName}.value.from`,
                  defaultValue:
                    formData && formData[`${fieldData.fieldName}.value.from`],
                  maxLength: fieldData.maxLength,
                  minLength: fieldData.minLength,
                  ...(fieldData.subType &&
                    getInputTypeFromField(fieldData.subType)),
                }}
                inputRef={ref}
                disabled={fieldData.disabledOnEdit || false}
                multiline={!!fieldData.multiline}
                label={
                  fieldData.required
                    ? `${fieldData.label} *`
                    : fieldData.label + '-from'
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  form.setValue(
                    `${fieldData.fieldName}.value.from`,
                    event.target.value,
                  );
                  onChange((value = event.target.value));
                }}
                margin={'dense'}
                fullWidth
                variant="standard"
                helperText={
                  form.formState.errors[
                    `${fieldData.fieldName}.value.from`
                  ] && (
                    <InputError
                      message={
                        form.formState.errors[
                          `${fieldData.fieldName}.value.from`
                        ].message || fieldData.error
                      }
                    />
                  )
                }
              />
            </Grid>
          );
        }}
        name={`${fieldData.fieldName}.value.from`}
        control={form.control}
        rules={{
          required: fieldData.required,
        }}
        defaultValue={formData && formData[`${fieldData.fieldName}.value.from`]}
      />
      <Controller
        render={({ field: { onChange, value } }) => {
          const { ref, ...rest } = form.register(
            `${fieldData.fieldName}.value.to`,
            {
              required: fieldData.required,
              minLength: {
                value: fieldData.minLength,
                message: `Minimum ${fieldData.minLength} characters required`,
              },
              maxLength: fieldData.maxLength,
              pattern: {
                value: fieldData.pattern,
                message: `Please enter a valid ${fieldData.label.toLowerCase()}`,
              },
            },
          );
          return (
            <Grid item xs={6}>
              <TextField
                inputProps={{
                  ...rest,
                  name: `${fieldData.fieldName}.value.to`,
                  defaultValue:
                    formData && formData[`${fieldData.fieldName}.to`],
                  maxLength: fieldData.maxLength,
                  minLength: fieldData.minLength,
                  ...(fieldData.subType &&
                    getInputTypeFromField(fieldData.subType)),
                }}
                inputRef={ref}
                disabled={fieldData.disabledOnEdit || false}
                multiline={!!fieldData.multiline}
                label={
                  fieldData.required
                    ? `${fieldData.label} *`
                    : fieldData.label + '-to'
                }
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  form.setValue(
                    `${fieldData.fieldName}.value.to`,
                    event.target.value,
                  );
                  onChange((value = event.target.value));
                }}
                margin={'dense'}
                fullWidth
                variant="standard"
                helperText={
                  form.formState.errors[`${fieldData.fieldName}.value.to`] && (
                    <InputError
                      message={
                        form.formState.errors[`${fieldData.fieldName}.value.to`]
                          .message || fieldData.error
                      }
                    />
                  )
                }
              />
            </Grid>
          );
        }}
        name={`${fieldData.fieldName}.value.to`}
        control={form.control}
        rules={{
          required: fieldData.required,
        }}
        defaultValue={formData && formData[`${fieldData.fieldName}.value.to`]}
      />
    </Grid>
  );
};

export default NumberRange;
