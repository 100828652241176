export const saveAccount = (account) => {
  window.localStorage.setItem('account', JSON.stringify(account));
};

export const getLocalAccount = () => {
  const account: any = window.localStorage.getItem('account');
  return JSON.parse(account);
};

export const isLabAdmin = () => {
  const localAccount = getLocalAccount();
  return ['LABADMIN', 'ADMIN'].includes(localAccount.role?.name);
};

export const isSuperAdmin = () => {
  const localAccount = getLocalAccount();
  return ['SUPERADMIN'].includes(localAccount.role?.name)
}
