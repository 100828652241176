import {
  RECENT_NOTIFICATIONS_START,
  RECENT_NOTIFICATIONS_SUCCESS,
  RECENT_NOTIFICATIONS_ERROR,
  UNREAD_NOTIFICATIONS_START,
  UNREAD_NOTIFICATIONS_SUCCESS,
  UNREAD_NOTIFICATIONS_ERROR,
  MARK_ALL_READ_NOTIFICATIONS_START,
  MARK_ALL_READ_NOTIFICATIONS_SUCCESS,
  MARK_ALL_READ_NOTIFICATIONS_ERROR,
} from './RecentNotificationsTypes';
import {
  getRecentNotifications,
  getUnreadNotifications,
  readAllRecentNotifications,
  readRecentNotification,
} from '../../repository/recentNotifications';
import { ERROR } from '../../containers/_Default/Notification/NotificationTypes';

export const fetchRecentNotifications = ({ _limit }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RECENT_NOTIFICATIONS_START,
      });
      const response: any = await getRecentNotifications(_limit);

      if (response.data) {
        dispatch({
          type: RECENT_NOTIFICATIONS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: RECENT_NOTIFICATIONS_ERROR,
          payload: {
            message: 'Notifications was not found',
          },
        });
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'Oops! Something bad happened!';
      if (error?.response?.status === 400) {
        dispatch({
          type: RECENT_NOTIFICATIONS_ERROR,
          payload: {
            isOpen: true,
            message: 'Notifications was not found',
            redirectToOverview: true,
          },
        });
      } else {
        dispatch({
          type: ERROR,
          payload: {
            message,
          },
        });
      }
    }
  };
};

export const fetchUnreadNotifications = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UNREAD_NOTIFICATIONS_START,
      });
      const response: any = await getUnreadNotifications();

      if (response.data) {
        dispatch({
          type: UNREAD_NOTIFICATIONS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UNREAD_NOTIFICATIONS_ERROR,
          payload: {
            message: 'Notifications was not found',
          },
        });
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'Oops! Something bad happened!';
      if (error?.response?.status === 400) {
        dispatch({
          type: UNREAD_NOTIFICATIONS_ERROR,
          payload: {
            isOpen: true,
            message: 'Unread notifications was not found',
            redirectToOverview: true,
          },
        });
      } else {
        dispatch({
          type: ERROR,
          payload: {
            message,
          },
        });
      }
    }
  };
};

export const updateRecentNotification = (data: any) => {
  return async (dispatch) => {
    try {
      await readRecentNotification(data);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.reponse?.data?.details ||
        error?.response?.data?.title ||
        'Oops! Something bad has happened';
      dispatch({
        type: ERROR,
        payload: {
          message,
        },
      });
    }
  };
};

export const markAllNotificationsAsRead = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MARK_ALL_READ_NOTIFICATIONS_START,
      });
      const response: any = await readAllRecentNotifications();
      dispatch({
        type: MARK_ALL_READ_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'Oops! Something bad happened!';
      dispatch({
        type: ERROR,
        payload: {
          message,
        },
      });
    }
  };
};
