import {
  GET_COMPANY,
  GET_COMPANY_ERROR,
  COMPANY_CLEAR,
  COMPANY_SINGLE_SUCCESS,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_CREATE_SUCCESS,
  COMPANY_EDIT,
} from './CompanyFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  locales: [],
  audit: [],
  company: {
    name: '',
    customerNumber: '',
    streetName: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
    phoneNumber1: '',
    phoneNumber2: '',
    email: '',
    vatId: '',
    sandbox: false,
    contact: {
      title: '',
      position: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return { ...state, loading: true };

    case COMPANY_SINGLE_SUCCESS:
      return { ...state, loading: false, company: action.payload };

    case COMPANY_UPDATE_SUCCESS:
      return { ...state, loading: false };

    case COMPANY_CREATE_SUCCESS:
      return { ...state, loading: false };

    case GET_COMPANY_ERROR:
      return { ...state, loading: false, company: null, error: action.payload };

    case COMPANY_CLEAR:
      return { ...INITIAL_STATE };

    case COMPANY_EDIT:
      return { ...state, company: action.payload };

    default:
      return state;
  }
};
