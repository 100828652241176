export const populateSelect = (state, label, formData) => {
  const list: any[] = [];
  state &&
    state.forEach((dataField) => {
      list.push({
        label: dataField[label],
        value: dataField._id || dataField.value,
      });
    });
  if (list.length > 0) {
    formData.options = [...list];
  }
};

export const populateSelectTestSubtypes = (state, formData) => {
  const list: any[] = [];
  state &&
    state.forEach((dataField) => {
      list.push({
        label: dataField.label,
        value: dataField.value,
        testType: dataField.testType,
      });
    });
  if (list.length > 0) {
    formData.options = [...list];
  }
};

export const populateSelectDeviceCatalogue = (
  data,
  field,
  label,
  tab,
  formData,
) => {
  const list: any[] = [];
  data &&
    data.forEach((dataField) => {
      list.push({
        label: dataField[label],
        value: dataField._id,
      });
    });
  if (list.length > 0) {
    formData[tab].fields[field].options = [...list];
  }
};

export const getInputTypeFromField = (subType) => {
  switch (subType) {
    case 'Int':
      return {
        type: 'number',
        step: '1',
      };
    case 'Float':
      return {
        type: 'number',
        step: '0.0000000001',
      };
    case 'String':
    default:
      return {
        type: 'text',
      };
  }
};

export const populateAutoIncrementField = (rows, config) => {
  const rowsLength = Object.values(rows)
    .map((item) => item)
    .filter((item: any) => !item.deleted).length;

  const autoIncrementFields = config.filter(
    (field: any) => !!field.autoIncrement,
  );
  return autoIncrementFields.reduce((accumulator, currentObject) => {
    accumulator[currentObject.header] = rowsLength + 1;
    return accumulator;
  }, {});
};
