import API from 'services/api';

export const getDataSearchFields = () => {
  return API.get('/result/search-fields');
};

export const getSearchResults = (query, pagination) => {
  return API.post('/result/search/complex', {
    query,
    pagination,
  });
};

export const exportSearchResults = (
  query,
  pagination,
  selectedResults,
  columns
) => {
  return API.post('/result/search/complex/export', {
    query,
    pagination,
    selectedResults,
    columns,
  });
};
