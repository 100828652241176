import React from 'react';
import { Snackbar, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { CLEAR } from './NotificationTypes';

import './Notification.scss';
import { useTranslation } from 'react-i18next';

export const Notification = () => {
  const { t } = useTranslation('common');
  const notification = useSelector((state: any) => state.notification);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: CLEAR,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={notification.isOpen}
      className={`snack-notification ${[
        notification.isLoading ? 'snack-loading' : '',
        notification.isSuccess ? 'snack-success' : '',
        notification.isError ? 'snack-error' : '',
      ].join(' ')}`}
      autoHideDuration={6000}
      onClose={handleClose}
      message={t(notification.message)}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          {(notification.isLoading && <CircularProgress size={20} />) || (
            <CloseIcon fontSize="small" />
          )}
        </IconButton>
      }
    />
  );
};
