import { Language } from 'utils/locale';
import {
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_CLEAR,
} from './ProfileTypes';

const INITIAL_STATE = {
  loading: true,
  error: false,
  message: '',
  data: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    notificationPreferences: {
      frequency: 'daily',
    },
    image: '',
    imagePath: '',
    language: Language.ENGLISH,
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROFILE_START:
      return { ...state, loading: true, error: null };

    case PROFILE_SUCCESS:
      if (action.payload === 'OK') {
        return {
          ...state,
          message: action.payload,
          loading: false,
        };
      }
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PROFILE_CLEAR:
      return {
        ...state,
        data: { ...INITIAL_STATE.data },
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
