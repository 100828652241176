import React from 'react';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import './InputError.scss';

type InputErrorProps = {
  message?: string;
};

export const InputError: React.FC<InputErrorProps> = ({ message }) => (
  <span className="input-error">
    <ErrorOutlineOutlinedIcon />
    <span>{message}</span>
  </span>
);
